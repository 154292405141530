import { InvoiceStatusType } from "@/enum/invoiceStatusType";
import { JobAccessLevelType } from "@/enum/jobAccessLevelType";
import { ResourceType } from "@/enum/resourceType";
import { SubscriptionStatusType } from "@/enum/subscriptionStatusType";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import { devLogger, getConvertedAreaUnit } from "@/utils/helpers";
import moment from "moment";
import { getDateTimeFormat, getDateTimeFormattedLocal } from '@/utils/helpers';
import { getCancellationDate, getSubscriptionStatusEnum, getSusbcriptionAmountIfExists, getTrialDaysLeft } from "@/utils/helpers/subscription-helper";

const exportDataSeparator = ", ";
const exportDataDateTimeFormat = "DD MMM YYYY hh:mm A";
const exportDataWithEmptySpaces = true;
const newLine = "\n";

const initialState = () => {
  return {
    exportDataOffset: 0,
    exportDataTotal: 0,
    exportDataLoaded: 0,
    exportData: [],
    exportDataLength: 0,
    exportDataTextFilters: "",
  };
};

function getAddressFormatted(address) {
  if (
    address.addressLine1 ||
    address.addressLine2 ||
    address.city ||
    address.state ||
    address.country ||
    address.postalCode
  ) {
    return (
      (address.addressLine1 ? address.addressLine1 + exportDataSeparator : "") +
      (address.addressLine2 ? address.addressLine2 + exportDataSeparator : "") +
      (address.city ? address.city + exportDataSeparator : "") +
      (address.state ? address.state + exportDataSeparator : "") +
      (address.country ? address.country + " - " : "") +
      (address.postalCode ? address.postalCode : "")
    );
  } else {
    if (address.coordinate) {
      return `Dropped Pin: (${address.coordinate.latitude}, ${address.coordinate.longitude})`;
    }
  }
}

function getLineItemFormatted(lineItem) {
  return `${lineItem.title}`;
}

function getDateTime(dateTime) {
  return dateTime
    ? moment.utc(dateTime).local().format(exportDataDateTimeFormat)
    : "";
}

function getCurrencyFormatted(currency) {
  return `${currency.id}`;
}

function showIfNotRepeated(key, value, invoiceId, items = state.exportData) {
  if (exportDataWithEmptySpaces) {
    if (
      items.find((item) => item[key] == value) &&
      items.find((item) => item.id === invoiceId)
    ) {
      return "";
    }
  }
  return value;
}

function getLineItemTaxRate(taxAmounts) {
  return taxAmounts?.[0]?.salesTaxRateInPercentage || 0;
}

function getLineItemTaxAmount(taxAmounts) {
  return taxAmounts?.[0]?.amount || 0;
}

export const state = initialState();

export const getters = {
  exportDataOffset: (state) => state.exportDataOffset,
  exportDataTotal: (state) => state.exportDataTotal,
  exportDataLoaded: (state) => state.exportDataLoaded,
  exportData: (state) => state.exportData,
  exportDataLength: (state) => state.exportDataLength,
  exportDataTextFilters: (state) => state.exportDataTextFilters,
};

export const mutations = {
  setInvoicesDataListForExport(state, invoicesData) {
    invoicesData.forEach((inv) => {
      state.exportDataLength++;

      inv.references.forEach((jobReference) => {
        if (inv?.lineItems.length) {
          const lineItems = inv.lineItems.filter((lineItem) =>
            jobReference.includes(lineItem.reference)
          );

          if (lineItems?.length) {
            lineItems.forEach((lineItem) => {
              const obj = {
                id: inv.id,
                invoice_number: showIfNotRepeated(
                  "invoice_number",
                  inv.number,
                  inv.id
                ),
                status: showIfNotRepeated(
                  "status",
                  InvoiceStatusType[inv.status],
                  inv.id
                ),
                customer_name: showIfNotRepeated(
                  "customer_name",
                  inv.customer.name,
                  inv.id
                ),
                customer_address: showIfNotRepeated(
                  "customer_address",
                  getAddressFormatted(inv.customer.address),
                  inv.id
                ),
                email_address: showIfNotRepeated(
                  "email_address",
                  inv.customer.emailAddress,
                  inv.id
                ),
                phone_number: showIfNotRepeated(
                  "phone_number",
                  inv.customer.phoneNumber,
                  inv.id
                ),
                reference_number: showIfNotRepeated(
                  "reference_number",
                  lineItem.reference,
                  inv.id
                ),
                line_item_date: getDateTime(lineItem.date),
                line_item: getLineItemFormatted(lineItem),
                line_item_rate: lineItem.rate,
                line_item_quantity: lineItem.quantity,
                line_item_unit: lineItem.unit,
                line_item_amount: lineItem.amount,
                line_item_tax_rate: getLineItemTaxRate(lineItem.taxAmounts),
                line_item_tax_amount: getLineItemTaxAmount(lineItem.taxAmounts),
                invoice_due_date: showIfNotRepeated(
                  "invoice_due_date",
                  getDateTime(inv.dueDate),
                  inv.id
                ),
                invoice_subtotal: showIfNotRepeated("invoice_subtotal", inv.subTotal, inv.id),
                invoice_tax: showIfNotRepeated("invoice_tax", inv.tax, inv.id),
                invoice_total: showIfNotRepeated("invoice_total", inv.total, inv.id),
                invoice_currency: showIfNotRepeated(
                  "invoice_currency",
                  getCurrencyFormatted(inv.currency),
                  inv.id
                ),
              };
              state.exportData.push(obj);
            });
          }
        }
      });
    });

    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },

  removeIdsFromExportableData(state) {
    const tempExportData = [];

    state.exportData.forEach((ed) => {
      const edObj = ed;
      delete edObj.id;
      tempExportData.push(edObj);
    });

    state.exportData = tempExportData;
  },

  setUsersDataListForExport(state, usersData) {
    usersData.forEach((jb) => {
      state.exportDataLength++;

      const obj = {
        name: jb.name,
        business_name: jb.businessName,
        email_address: jb.emailAddress,
        phone_number: jb.primaryPhoneNumber,
        onboarded_on: getDateTime(jb.createdOn),
        role: jb.roles.map(role => (role + '')).join(','),
        status: jb.isArchived ? 'Inactive' : 'Active',
        last_active: getDateTime(jb.lastActiveOn)
      }

      state.exportData.push(obj);
    })

    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },

  setBusinessesDataListForExport(state, businessesData) {

    function getSubscriptionText(business) {
      let subscriptionText = ''
      const unavailableText = 'NA'

      if(business.subscriptions.status !== null) {
        if(!business.subscriptions.cancellationScheduledOn) {

            if(business.subscriptions.status == SubscriptionStatusType.Trialing) {
              subscriptionText += getTrialDaysLeft(business.subscriptions.trialEnd)
            } else {
              if(business.subscriptions.status == SubscriptionStatusType.Active) {
                subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status) + ' '
                subscriptionText += business.subscriptions.currentPeriodStart ? getDateTimeFormat(business.subscriptions.currentPeriodStart) : unavailableText
                subscriptionText += ' - '
                subscriptionText += business.subscriptions.currentPeriodEnd ? getDateTimeFormat(business.subscriptions.currentPeriodEnd) : unavailableText
              } else if (business.subscriptions.status == SubscriptionStatusType.Cancelled) {
                subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status)
                subscriptionText += ' '
                subscriptionText += getDateTimeFormattedLocal(business.subscriptions.cancelledAt)
              } else {
                subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status)
              }
            }

            if(business.subscriptions.status == SubscriptionStatusType.Trialing) {
              subscriptionText += ' '
              subscriptionText += business.subscriptions.trialStart ? getDateTimeFormat(business.subscriptions.trialStart) : unavailableText
              subscriptionText += ' - '
              subscriptionText += business.subscriptions.trialEnd ? getDateTimeFormat(business.subscriptions.trialEnd) : unavailableText
            }
        }
        subscriptionText += getCancellationDate(business.subscriptions)
      } else {
        if(business.subscriptions.trialStart || business.subscriptions.trialEnd) {
          subscriptionText += getTrialDaysLeft(business.subscriptions.trialEnd) 
          subscriptionText += ' '
          subscriptionText += business.subscriptions.trialStart ? getDateTimeFormat(business.subscriptions.trialStart) : unavailableText
          subscriptionText += ' - '
          subscriptionText += business.subscriptions.trialEnd ? getDateTimeFormat(business.subscriptions.trialEnd) : unavailableText
        }
      }

      return subscriptionText
    }

    businessesData.forEach((business) => {
      state.exportDataLength++;

      const obj = {
        business_name: business.name,
        country: business.country,
        owner_name: business.ownerName,
        owner_contact: business.ownerContact,
        owner_type: business.businessType,
        onboarded_on: getDateTime(business.createdOn),
        jobs: business.totalJobs,
        active_members: `(${business.teamMembers.active}/${business.teamMembers.total})`,
        subscriptions: getSubscriptionText(business),
        amount: getSusbcriptionAmountIfExists(business, false)
      }

      state.exportData.push(obj);
    })

    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },

  resetExportData(state) {
    Object.assign(state, initialState());
  },
};
