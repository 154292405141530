import { Coordinate } from './Coordinate';
import { User } from './User';

export class TeamJobField {
    id?: string;
    createdOn?: Date;
    title: string;
    areaInSquareMeter: number;
    colour: string;
    polygon: string;
    metaData: [];
    owner: User;
    ownerId: string;

    constructor(field?: TeamJobField) {
        this.id = field ? field.id : null;
        this.createdOn = field ? field.createdOn : null;
        this.title = field ? field.title : null;
        this.areaInSquareMeter = field ? field.areaInSquareMeter : 0;
        this.colour = field ? field.colour : null;
        this.polygon = field ? field.polygon : null;
        this.metaData = field ? field.metaData : [];
        this.owner = field ? field.owner : null;
        this.ownerId = field ? field.owner.id : null;

    }

    public getTeamJobField(field: TeamJobField) {
        this.id = field.id;
        this.createdOn = field.createdOn ? field.createdOn : null;
        this.title = field.title;
        this.areaInSquareMeter = field.areaInSquareMeter;
        this.colour = field.colour;
        this.polygon = field.polygon ? field.polygon : null;
        this.metaData = field.metaData ? field.metaData : [];
        this.owner = field.owner ? field.owner : null;
        this.ownerId = field.owner ? field.owner.id : null;
        return this;
    }
}