




































































































































































import $ from "jquery";
import { openOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import productsComputedMixin from "@/components/products/products-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { listAction } from '@/enum/listAction';
import { DeleteItemDialog as ItemDialog, ActionListingDialog } from "@/components/shared/dialogs/index";
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { TabFilterType } from "@/enum/tabFilterType";

export default {
    name: "ProductsListView",
    mixins: [
        permissionsMixin,
        scrollerMixin,
        filterParamsMixin,
        productsComputedMixin,
        Timers
    ],
    components: {
        Snackbar,
        ActionListingDialog,
        Button,
        ItemDialog,
        List
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems.length > 0) {
                this.snackbarShow = true;
            } else {
                this.snackbarShow = false;
                this.isChecked = false;
            }
        },
        productStatus() {
            this.$store.commit('clearSnackbarDetails');
        },
    },
    data() {
        return {
            dialogProduct: false,
            deleteProductId: null,
            isChecked: false,
            actionType: listAction.Delete,
            listAction,
            ViewPreferenceType,
            searchTimer: null,
        };
    },
    created() {
        this.TabFilterType = TabFilterType
    },
    beforeMount() {
        this.clearAndResetProductsPagingStates();
    },
    beforeDestroy() {
        this.clearAndResetProductsPagingStates();
        this.$store.commit('clearSnackbarDetails');
    },
    mounted() {
        if(!this.$store.state.isPageRelated) {
            localStorage.removeItem('productSearchQuery');
            localStorage.removeItem('productFilterType');
        }
        this.getUrlParamsProductLocal();
        this.setFilterOptionsAndGetProducts();
        this.$store.dispatch("getProductUnits");
    },
    computed: {
        someProductsSelected() {
            return this.selectedItems.length > 0 && !this.allProductsSelected;
        },
        allProductsSelected() {
           if(this.productSearchText){
                return false;
            }
            else {
            return (this.selectedItems.length == this.productsList.length);
            }
        },
    },
    methods: {
        ...mapMutations({
            clearProductDetailsState: "resetProductDetails",
            clearProductFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetSnackbarDetails: "clearSnackbarDetails",
            resetImportForm: "resetImportDataDetails"
        }),
        openAddProductForm: function () {
            this.clearProductDetailsState();
            openOverlay("editProductFormOverlay");
        },
        openProductEditForm: function (id) {
            this.clearProductDetailsState();
            const $this = this.$store;
            this.resetSnackbarDetails();
            $this.dispatch("getProductDetails", id);
            openOverlay("editProductFormOverlay");
        },
        fnGetHeaderString(actionType: listAction) {
            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('productDeletePromptTitle');
                case listAction.Archive:
                    return this.$stringConstants('productArchivePromptTitle');
                case listAction.Restore:
                    return this.$stringConstants('productRestorePromptTitle');
            }

        },
        fnGetBodyString(actionType: listAction) {
            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('productDeletePromptSubtitle');
                case listAction.Archive:
                    return this.$stringConstants('productArchivePromptSubtitle');
                case listAction.Restore:
                    return this.$stringConstants('productRestorePromptSubtitle');
            }
        },

        confirmActionOnProduct: function (id, listAction: listAction) {
            this.deleteProductId = id;
            this.dialogProduct = true;
            this.actionType = listAction;
        },
        cancelActionOnMachine: function (actionType: listAction) {
            this.dialogProduct = false;
            this.deleteProductId = "";
        },
        proceedActionOnMachine: function (actionType: listAction) {
            this.dialogProduct = false;
            this.resetSnackbarDetails();
            this.$store.dispatch("actionOnProduct", [actionType, this.deleteProductId]);
        },
        setUrlParamsProductLocal() {
            this.setLocalParamsMx(this.productSearchText, "productSearchQuery");
            this.setLocalParamsMx(this.productStatus, "productFilterType");
        },
        getUrlParamsProductLocal() {
            this.productSearchText = this.getLocalParamsMx("productSearchQuery", "");
            this.productStatus = parseInt(
                this.getLocalParamsMx("productFilterType", 0)
            );
        },

        removeLocalProductsParams() {
            this.productSearchText = this.removeLocalParamsMx(
                "productSearchQuery",
                null
            );
        },
        applySearchProductFilter() {
            this.clearProductFilterOption();
            this.resetSnackbarDetails();
            this.resetPaging();
            this.productsList = [];
            this.filtersApplied = true;
            this.setUrlParamsProductLocal();
            this.setFilterOptionsAndGetProducts();
        },
        getProductListByStatus() {
            this.applySearchProductFilter();
        },

        clearAndResetProductsPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.productsModule.productsList = [];
            this.clearProductFilterOption();
            this.resetPaging();
            this.resetSnackbarDetails();
            this.filtersApplied = false;
        },

        seedProducts() {
            for (let i = 1; i < 400; i++) {
                let productObj = {
                    title: "PRODUCT_" + i,
                    cost: i,
                    unit: i % 2 == 0 ? "Metre" : "Kg",
                };
                this.$store.dispatch("addNewProduct", productObj);
            }
        },

        selectAllProducts() {
            this.selectedItems = [];
            this.allItemsDeleted = false;
            if (this.isChecked) {
                this.productsList.map((pro) => {
                    this.selectedItems.push(pro.id);
                });
            }
        },
        setFilterOptionsAndGetProducts(filter = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            if (!this.productSearchText) {
                this.clearProductFilterOption();
                this.filtersApplied = false;
            }
            this.$store.dispatch('setProductsListFilter', filter).then(res => {
                this.$store.dispatch('getProductsListFiltered');
            })
        },

        openImportForm() {
            this.resetImportForm();
            openOverlay("importProductsFormOverlay");
        },
    },
};
