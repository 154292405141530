import ConstantValues from "@/plugins/constantValues";
import { generateRandomHexColorString } from "@/utils/helpers/generator-helpers";
import moment from "moment";

export class Config {
    id?: string;
    workingStartTime: string;
    workingEndTime: string;
    workingStartTimeConverted?: string;
    workingEndTimeConverted?: string;
    allowedBreakTimeADayInMinutes: number;
    defaultColor: string;
    defaultLatitude: number;
    defaultLongitude: number;
    defaultAreaMeasurementUnit: number;
    timezone: any;
    currency: any;
    invoiceInstructions: string;
    invoiceTermsInDays: number;
    salesTaxName: string;
    salesTaxRegistrationNumber: string;
    salesTaxRates: Array<any>;
    exportOptionsConfigs: any;
    jobTags: Array<string>;
    
        constructor() {
                const crDate = moment().format('YYYY-MM-DD');
                const stTime = moment.utc("0001-01-01T06:00:00.000Z").format("HH:mm:ss");
                const etTime = moment.utc("0001-01-01T14:00:00.000Z").format("HH:mm:ss");
                this.id = null;
                this.workingStartTime = null;
                this.workingEndTime = null;
                this.workingStartTimeConverted = moment.utc(crDate + " " + stTime).local().format("HH:mm");
                this.workingEndTimeConverted = moment.utc(crDate + " " + etTime).local().format("HH:mm");
                this.allowedBreakTimeADayInMinutes = 0;
                this.defaultColor = generateRandomHexColorString();
                this.defaultLatitude = ConstantValues.defaultBusinessLatitude;
                this.defaultLongitude = ConstantValues.defaultBusinessLongitude;
                this.defaultAreaMeasurementUnit = 0;
                this.timezone = null;
                this.currency = null;
                this.invoiceInstructions = null;
                this.invoiceTermsInDays = ConstantValues.defaultInvoicingTermsInDays;
                this.salesTaxName = null;
                this.salesTaxRegistrationNumber = null;
                this.salesTaxRates = [];
                this.exportOptionsConfigs = [];
                this.jobTags = [];
        }

        public getConfigDetails(config: Config) {
                const crDate = moment().format('YYYY-MM-DD');
                const stTime = config?.workingStartTime ? moment.utc(config.workingStartTime).format("HH:mm:ss") : moment.utc("0001-01-01T06:00:00.000Z").format("HH:mm:ss");
                const etTime = config?.workingEndTime ? moment.utc(config.workingEndTime).format("HH:mm:ss") : moment.utc("0001-01-01T14:00:00.000Z").format("HH:mm:ss");
                this.id = config?.id || null;
                this.workingStartTime = config?.workingStartTime || null;
                this.workingEndTime = config?.workingEndTime || null;
                this.workingStartTimeConverted = config?.workingStartTime ? moment.utc(crDate + " " + stTime).local().format("HH:mm") : moment.utc(crDate + " " + stTime).local().format("HH:mm");
                this.workingEndTimeConverted = config?.workingEndTime ? moment.utc(crDate + " " + etTime).local().format("HH:mm") : moment.utc(crDate + " " + etTime).local().format("HH:mm");
                this.allowedBreakTimeADayInMinutes = config?.allowedBreakTimeADayInMinutes || 0;
                this.defaultColor = config?.defaultColor || generateRandomHexColorString();
                this.defaultLatitude = config?.defaultLatitude || ConstantValues.defaultBusinessLatitude;
                this.defaultLongitude = config?.defaultLongitude || ConstantValues.defaultBusinessLongitude;
                this.defaultAreaMeasurementUnit = config?.defaultAreaMeasurementUnit || 0;
                this.timezone = config?.timezone || null;
                this.currency = config?.currency || null;
                this.invoiceInstructions = config?.invoiceInstructions || null;
                this.invoiceTermsInDays = config?.invoiceTermsInDays;
                this.salesTaxName = config?.salesTaxName || null;
                this.salesTaxRegistrationNumber = config?.salesTaxRegistrationNumber || null;
                this.salesTaxRates = config?.salesTaxRates || [];
                this.exportOptionsConfigs = config?.exportOptionsConfigs || [];
                this.jobTags = config?.jobTags || [];
            return this;
        }
}