




























































































































import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import loginComputedMixin from "@/components/login/login-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { ExternalIntegrationType } from "@/enum/externalIntegrationType";
import scriptsLoader from '@/mixins/scripts-loader';
import ConstantValues from '@/plugins/constantValues';
import StringConstants from "@/plugins/stringConstants";
import router from "@/router/index";
import { appVersion } from "@/utils/constants";
import { routeForgetPassword, routeSignUp } from "@/utils/endpoints";
import { notify } from "@/utils/helpers";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, loginComputedMixin, scriptsLoader],
  validations: {
    userName: { required, email },
    passWord: { required },
  },
  components: {
    Button,
    LoginRegistrationWrapper,
  },
  data: function () {
    return {
      userName: "",
      passWord: "",
      routeSignUp,
      routeForgetPassword,
      regularSubmit: true,
      verificationSubmit: false,
      value: String,
      appVersion,
    };
  },
  watch: {
    userName(newVal) {
      this.userName = this.userName.toLowerCase();
    },
  },
  computed: {
    loginDataLoading() {
      return this.loginLoader || this.usersLoader || this.subscriptionPlanLoader;
    },
    userNameErrors() {
      let errors: any = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("Email is required.");
      !this.$v.userName.email &&
        errors.push("Please enter the correct email address");
      return errors;
    },
    passWordErrors() {
      let errors: any = [];
      if (!this.$v.passWord.$dirty) return errors;
      !this.$v.passWord.required && errors.push("Password is required.");
      return errors;
    },
  },
  mounted() {
    this.loadScripts();
    const urlParams = new URLSearchParams(window.location.search);
    const invitationCode = urlParams.get("code");
    const invitationType = urlParams.get("type");
    const invitationEmail = urlParams.get("email");
    if (invitationCode != null && invitationType != null) {
      switch (invitationType) {
        case this.invitationType["invitation"]:
          this.regularSubmit = false;
          this.verificationSubmit = true;
          this.userInviteeAcceptanceCode = invitationCode;
          break;
        case this.invitationType["verification"]:
          this.regularSubmit = true;
          this.verificationSubmit = false;
          if (invitationEmail != null) {
            this.userName = decodeURIComponent(invitationEmail);
          }
          this.$store.dispatch("verifyEmailInvite", [
            invitationCode,
            invitationEmail,
          ]);
          break;
      }
    }

    const email = urlParams.get("email");

    if (email) {
      this.userName = email;
      notify(StringConstants.userWelcomeBackText, 'success');
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedLogin();
    },
    submitVerification() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedLoginVerify();
    },
    proceedLogin() {
      let formData = new FormData();
      formData.append("username", this.userName);
      formData.append("password", this.passWord);
      formData.append("clientType", ConstantValues.clientType);
      this.$store.dispatch("login", formData);
    },
    signUpXero() {
      this.$store.dispatch("initializeLoginWithExternalInvoicingService", ExternalIntegrationType.Xero);
    },
    proceedLoginVerify() {
      let formData = new FormData();
      formData.append("username", this.userName);
      formData.append("password", this.passWord);
      formData.append("clientType", ConstantValues.clientType);
      this.$store.dispatch("loginAndAcceptInvite", formData).then(() => {
        router.push("/users/invitee/profile/" + this.userInviteeAcceptanceCode);
      });
    },
  },
};
