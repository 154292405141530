


























































































































































































import { listAction } from '@/enum/listAction';
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import { UserRoleType } from '@/enum/userRoleType';

    export default {
        name: 'SnackBarList',
        props: [
            'items',
            'deleteApiType',
            'allItemsSelected',
            'someItemsSelected',
            'status',
            'showOnlyDelete',
            'showArchiveOrDelete',
            'showRoleChange',
            'change-role-callback',
            'showUpdateButton',
            'update-items-callback',
            'integrationType',
            'loaderState'
        ],
        data() {
            return {
                timeout: -1,
                listAction,
                ViewPreferenceType,
                UserRoleType,
            }
        },
        watch: {
            snackbarShow(snackBarToggleVisibility) {
                if(snackBarToggleVisibility === false) {
                    this.clearAllSelected();
                }
            },
            selectedItems(val) {
                if (this.someItemsSelected && this.isAllItemsSelected && this.items.length > 0) {
                    this.unselectedItems = this.items.filter(item => !this.selectedItems.includes(item.id)).map(item => item.id);
                } else {
                    this.unselectedItems = [];
                }
            }
        },
        computed: {
            snackbarShow: {
                get() {
                    return this.$store.getters.snackbarShow;
                },
                set(newVal) {
                    this.$store.state.snackbarShow = newVal;
                }
            },
            allItemsDeleted: {
                get() {
                    return this.$store.getters.allItemsDeleted;
                },
                set(newVal) {
                    this.$store.state.allItemsDeleted = newVal;
                }
            },
            allItemsRestored: {
                get() {
                    return this.$store.getters.allItemsRestored;
                },
                set(newVal) {
                    this.$store.state.allItemsRestored = newVal;
                }
            },
            allItemsArchived: {
                get() {
                    return this.$store.getters.allItemsArchived;
                },
                set(newVal) {
                    this.$store.state.allItemsArchived = newVal;
                }
            },
            allItemsApproved: {
                get() {
                    return this.$store.getters.allItemsApproved;
                },
                set(newVal) {
                    this.$store.state.allItemsApproved = newVal;
                }
            },
            allItemsUnapproved: {
                get() {
                    return this.$store.getters.allItemsUnapproved;
                },
                set(newVal) {
                    this.$store.state.allItemsUnapproved = newVal;
                }
            },
            allItemsExported: {
                get() {
                    return this.$store.getters.allItemsExported;
                },
                set(newVal) {
                    this.$store.state.allItemsExported = newVal;
                }
            },
            allItemsMarkedForInvoice: {
                get() {
                    return this.$store.getters.allItemsMarkedForInvoice;
                },
                set(newVal) {
                    this.$store.state.allItemsMarkedForInvoice = newVal;
                }
            },
            allItemsCancelled: {
                get() {
                    return this.$store.getters.allItemsCancelled;
                },
                set(newVal) {
                    this.$store.state.allItemsCancelled = newVal;
                }
            },
            selectedItems: {
                get() {
                    return this.$store.getters.selectedItems;
                },
                set(newVal) {
                    this.$store.state.selectedItems = newVal;
                }
            },
            unselectedItems: {
                get() {
                    return this.$store.getters.unselectedItems;
                },
                set(newVal) {
                    this.$store.state.unselectedItems = newVal;
                }
            },
            totalRowCount: {
                get() {
                    return this.$store.getters.totalRowCount;
                },
                set(newVal) {
                    this.$store.state.totalRowCount = newVal;
                }
            }, 
            listActionType: {
                get() {
                    return this.$store.getters.listActionType;
                },
                set(newVal) {
                    this.$store.state.listActionType = newVal;
                }
            },
            dialogSelectedRows: {
                get() {
                    return this.$store.getters.dialogSelectedRows;
                },
                set(val) {
                    this.$store.state.dialogSelectedRows = val;
                },
            },
            archiveDialogSelectedRows: {
                get() {
                    return this.$store.getters.archiveDialogSelectedRows;
                },
                set(val) {
                    this.$store.state.archiveDialogSelectedRows = val;
                },
            },
            restoreDialogSelectedRows: {
                get() {
                    return this.$store.getters.restoreDialogSelectedRows;
                },
                set(value) {
                    this.$store.state.restoreDialogSelectedRows = value;
                }
            },
            bulkActionLoader: {
                get() {
                    return this.$store.getters.bulkActionLoader;
                },
                set(value) {
                    this.$store.state.bulkActionLoader = value;
                }
            },
            ownerRole: {
                get() {
                    return localStorage.getItem('ownerRole')
                }
            },
            loginUserRole: {
                get() {
                    return this.$store.getters.loginUserRole;
                }               
            },
            isAllItemsSelected: {
                get() {
                    return this.$store.getters.isAllItemsSelected;
                }
            },
            isContractorOnlyView: {
                get() {
                    return ((this.loginUserRole == UserRoleType.Contractor) 
                                || (((this.loginUserRole == UserRoleType.Manager) 
                                    && (this.ownerRole == UserRoleType[UserRoleType.Contractor])))
                            )
                }
            },
            selectedItemsCount: {
                get() {
                    if (this.isAllItemsSelected) {
                        return this.totalRowCount - this.unselectedItems.length;
                    } else {
                        return this.totalRowCount;
                    }
                }
            }
        },
        methods: {
            selectAllItemsData() {
                this.allItemsDeleted = true;
                this.allItemsArchived = true;
                this.allItemsRestored = true;
                this.allItemsApproved = true;
                this.allItemsUnapproved = true;
                this.allItemsExported = true;
                this.allItemsMarkedForInvoice = true;
                this.allItemsCancelled = true;
            },
            deleteSectedRows() {
                this.listActionType = listAction.Delete;
                this.dialogSelectedRows = true;
            },
            unSelectAllItems() {
                this.allItemsDeleted = false;
                this.allItemsArchived = false;
                this.allItemsRestored = false;
                this.allItemsApproved = false;
                this.allItemsUnapproved = false;
                this.allItemsExported = false;
                this.allItemsMarkedForInvoice = false;
                this.allItemsCancelled = false;
            },
            clearAllSelected() {
                this.selectedItems = []
                this.unSelectAllItems()
            },
            archiveSelectedRows() {
                this.listActionType = listAction.Archive;
                this.dialogSelectedRows = true;
            },
            restoreSelectedRows() {
                this.listActionType = listAction.Restore;
                this.dialogSelectedRows = true;
            },
            approveSelectedRows() {
                this.listActionType = listAction.Approve;
                this.dialogSelectedRows = true;
            },
            unapproveSelectedRows() {
                this.listActionType = listAction.Unapprove;
                this.dialogSelectedRows = true;
            },
            cancelSelectedRows() {
                this.listActionType = listAction.Cancel;
                this.dialogSelectedRows = true;
            },
            exportSelectedRows() {
                this.listActionType = listAction.Export;
                this.dialogSelectedRows = true;
            },
            markAsInvoicedSelectedRows() {
                this.listActionType = listAction.MarkAsInvoiced;
                this.dialogSelectedRows = true;
            },
            changeRole() {
                this.$emit('change-role-callback')
            },
            updateItems() {
                this.$emit('update-items-callback')
            }
        }
    }
