



















































































































































































































































































import $ from 'jquery';
import moment from 'moment';
import { notify, openOverlay } from '@/utils/helpers';
import { defaultScrollViewHeight } from '@/utils/uiconstants';
import { mapMutations } from 'vuex';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from '@/mixins/filter-params-mixin';
import skeletonLoaderMixin from '@/mixins/skeleton-loader-mixin';
import Avatar from '@/components/shared/avatars/avatar.vue';
import teamMembersComputedMixin from '@/components/team-members/team-members-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { DeleteItemDialog as ActionItemDialog } from '@/components/shared/dialogs/index';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { DeleteItemDialog as ItemDialog, ActionListingDialog } from "@/components/shared/dialogs/index";
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import { listAction } from '@/enum/listAction';
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import { DeleteItemDialog as ChangeRoleDialog } from '@/components/shared/dialogs/index';
import { UserRoleType } from '@/enum/userRoleType';


export default {
    name: "TeamMembersListView",
    mixins: [
        permissionsMixin, 
        scrollerMixin, 
        filterParamsMixin, 
        teamMembersComputedMixin, 
        skeletonLoaderMixin,
        Timers
    ],
    components: {
        Avatar,
        Button,
        ActionItemDialog,
        ItemDialog,
        ActionListingDialog,
        List,
        Snackbar,
        ChangeRoleDialog
    },
    data() {
        return {
            selectedTeamMemberId: null,
            dialogTeamMember: false,
            defaultScrollViewHeight,
            archiveDialog: false,
            restoreDialog: false,
            searchTimer: null,
            actionType: listAction.Restore,
            isChecked: false,
            ViewPreferenceType,
            listAction,
            showChangeRole: false,
            selectedTeamMemberRole: UserRoleType.Manager
        }
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems.length > 0) {
                this.snackbarShow = true;
            } else {
                this.snackbarShow = false;
                this.isChecked = false;
            }
        },
        memberFilterType() {
            this.$store.commit('clearSnackbarDetails');
        }
    },
    computed:{ 
        someTeamMembersSelected() {
            return this.selectedItems.length > 0 && !this.allTeamMembersSelected;
        },
        allTeamMembersSelected() {
           if (this.teamMemberSearchText){
                return false
            }
            else {
                return (this.selectedItems.length == this.teamMembersList.length)
            }
        },
    },
    beforeMount() {
        this.clearAndResetTeamMembersPagingStates()
    },
    beforeDestroy() {
        this.clearAndResetTeamMembersPagingStates()
        this.$store.commit('clearSnackbarDetails')
    },
    mounted() {
        this.getTeamMembersList()
    },  
    methods: {
        ...mapMutations({
            clearTeamMemberDetailsState: "resetTeamMemberDetails",
            clearTeamMemberFilterOptions: "setClearFilterOptions",
            setClearInviteeProfile: "setClearInviteeProfile",
            resetPaging: "resetPaging",
            resetSnackbarDetails: "clearSnackbarDetails"
        }),

        getTeamMembersList() {
            if (!this.$store.state.isPageRelated) {
                localStorage.removeItem('memberSearchQuery')
                localStorage.removeItem('memberType')
            }
            this.getUrlParamsTeamMembersLocal()
            this.setFilterOptionsAndGetTeamMembers()
        },

        setUrlParamsTeamMembersLocal() {
            this.setLocalParamsMx(this.teamMemberSearchText, 'memberSearchQuery')
            this.setLocalParamsMx(this.memberFilterType, 'memberType')
        },

        getUrlParamsTeamMembersLocal() {
            this.teamMemberSearchText = this.getLocalParamsMx('memberSearchQuery', '')
            this.memberFilterType = parseInt(this.getLocalParamsMx('memberType', 0))
        },

        openAddTeamMemberForm() {
            this.clearTeamMemberDetailsState();
            this.setClearInviteeProfile();
            openOverlay("addTeamMemberFormOverlay");
        },

        openEditTeamMemberForm(teamMemberId: string) {
            this.clearTeamMemberDetailsState();
            this.$store.dispatch('getTeamMember', teamMemberId);
            openOverlay("editTeamMemberFormOverlay");
        },
        applySearchTeamMembersFilter() {
            this.clearTeamMemberFilterOptions();
            this.resetPaging();
            this.teamMembersList = [];
            this.pendingTeamMemberList = [];
            this.filtersApplied = true;
            this.setUrlParamsTeamMembersLocal()
            this.setFilterOptionsAndGetTeamMembers();
        },
        
        promtArchiveTeamMember(teamMemberId: string) {
            this.archiveDialog = true;
            this.selectedTeamMemberId = teamMemberId;
        },

        proceedTeamMemberArchiving: function (id) {
            this.archiveDialog = false;
            this.$store.dispatch('archiveTeamMember', this.selectedTeamMemberId).then((res) => {
                if (res) {
                    this.selectedTeamMemberId = '';
                }
            });
        },

        proceedTeamMemberRestoration: function (id) {
            this.restoreDialog = false
            this.$store.dispatch('restoreTeamMember', this.selectedTeamMemberId).then((res) => {
                this.selectedTeamMemberId = ''
            })
        },

        cancelTeamMemberArchiving: function () {
            this.archiveDialog = false;
            this.restoreDialog = false;
            this.selectedTeamMemberId = '';
        },

        getDateTimeFormat: function (date) {
            return moment.utc(date).local().format('DD MMM YYYY')
        },

        getMembersListByStatus() {
            this.applySearchTeamMembersFilter();
            this.setColumnWidths([]);
            this.$nextTick(() => {
                this.calculateColumnWidths(this.$refs.listHeader);
            })
        },

        promtRestoreTeamMember: function (id) {
            this.restoreDialog = true;
            this.selectedTeamMemberId = id;
        },

        clearAndResetTeamMembersPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.teamMembersModule.teamMembersList = [];
            this.$store.state.teamMembersModule.pendingTeamMemberList = [];
            this.clearTeamMemberDetailsState();
            this.clearTeamMemberFilterOptions();
            this.resetPaging();
            this.resetSnackbarDetails();
            this.memberFilterType = 0;
            this.filtersApplied = false;
        },
        getTimeInitials(dateTime) {
            const crDate = moment().format('YYYY-MM-DD');
            const time = moment.utc(dateTime).format("HH:mm:ss");
            const shortTime = moment.utc(crDate + " " + time).local().format("LT");
            return shortTime
        },

        deleteInviteConnection(code) {
            this.$store.dispatch('deleteSentConReq', code).then((res) => {
                if (res) {
                    notify(this.$stringConstants('invitationRequestDeleted'));
                    this.pendingTeamMemberList = [];
                    this.$store.dispatch('fnSetTeamMembersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                        this.$store.dispatch('getPendingTeamMembers');
                    });
                }
            });
        },

        setFilterOptionsAndGetTeamMembers(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            if (!this.teamMemberSearchText) {
                this.clearTeamMemberFilterOptions();
                this.filtersApplied = false;
            }
            this.$store.dispatch('fnSetTeamMembersFilter', filters).then(res => {
                if (this.memberFilterType === 0 || this.memberFilterType === 1) {
                    this.$store.dispatch('getTeamMembers')
                } else if (this.memberFilterType === 2) {
                    this.$store.dispatch('getPendingTeamMembers')
                }
            })
        },

        fnGetHeaderString(actionType: listAction) {
            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('teamMemberDeletePromtTitle');
                case listAction.Archive:
                    return this.$stringConstants('teamMemberArchivePromtTitle');
                case listAction.Restore:
                    return this.$stringConstants('teamMemberRestorePromtTitle');
            }

        },
        fnGetBodyString(actionType: listAction) {
            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('teamMemberDeletePromtSubtitle');
                case listAction.Archive:
                    return this.$stringConstants('teamMemberArchivePromtSubtitle');
                case listAction.Restore:
                    return this.$stringConstants('teamMemberRestorePromtSubtitle');
            }
        },

        selectAllTeamMembers() {
            this.selectedItems = [];
            this.allItemsDeleted = false;
            if (this.isChecked) {
                this.teamMembersList.map((pro) => {
                    this.selectedItems.push(pro.id);
                });
            }
        },

        cancelActionOnTeamMember: function (actionType) {
            this.dialogTeamMember = false;
            this.selectedTeamMemberId = "";
        },

        proceedActionOnTeamMember(actionType) {
            this.dialogTeamMember = false;
            this.resetSnackbarDetails();
            this.$store.dispatch("actionOnTeamMember", [actionType, this.deleteFarmerId]);
        },

        changeRole() {
            this.showChangeRole = true
        },

        cancelChangeRoleDialog() {
            this.showChangeRole = false
        },

        changeRoleAsync() {
            this.clearTeamMemberFilterOptions()
            this.showChangeRole = false
            const $globalStore = this.$store

            const resetSnackbarAndListStates = () => {
                this.resetSnackbarDetails()
                $globalStore.state.teamMembersModule.teamMembersList = []
                this.clearAndResetPagingStates()
                this.getTeamMembersList()
                this.selectedTeamMemberRole = UserRoleType.Manager
            }

            if(!this.allItemsDeleted) {
                this.selectedItems.map((item) => {
                    $globalStore.commit("setFilterOptions", [ConstantValues.queryIdentifiers, item])
                })
            } 

            $globalStore.dispatch("updateTeamMembersRole", { newRole: this.selectedTeamMemberRole, allSelected: this.allItemsDeleted }).then(() => {
                resetSnackbarAndListStates()
            })

        },

        clearAndResetPagingStates() {
            this.clearTeamMemberFilterOptions()
            this.resetPaging()
            this.resetSnackbarDetails()
        },

        
    }
}
