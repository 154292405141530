import axios from 'axios';
import { apiUsers, apiRefreshToken } from "./endpoints";
import { devLogger, getCookie } from "./helpers";
import store from "../store";
import buildUrl from 'build-url';
import { notify, notifyDev } from '@/utils/helpers';
import { ResponseList } from '@/data/models/ResponseList';
import ConstantValues from '@/plugins/constantValues';

const SUCCESS = 200;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const REFRESH_TOKEN_RETRIES_LIMIT = 2;
//const controller = new AbortController();

const fnGetHeaders = (cancellable?, includeVersioning?) => {
    if (getCookie("tkn").length > 0) {
        if (cancellable && store.state?.abortController) {
            const obj = {
                headers: {
                    "Authorization": `Bearer ${getCookie("tkn")}`,
                },
                signal: store.state.abortController.signal
            }

            if (getCookie("accessCode") && getCookie("accessCode").length > 0)
                obj['headers']['accessCode'] = getCookie("accessCode")

            if (includeVersioning)
                obj['headers']['x-api-version'] = ConstantValues.fieldsApiVersion
            return obj
        }
        const headersObj = {
            "Authorization": `Bearer ${getCookie("tkn")}`,
        }

        if(getCookie("accessCode") && getCookie("accessCode").length > 0) {
            headersObj['accessCode'] = getCookie("accessCode")
        }

        if (includeVersioning) {
            headersObj['x-api-version'] = ConstantValues.fieldsApiVersion
        }

        return {
            headers: headersObj
        }
    }
    else
        return null;
}

const initializeCancelRequests = async (next) => {
    await cancelRequests()
    resetAbortController()
    next()
}

const cancelPendingRequests = async () => {
    await cancelRequests()
}

const initializeAbortControllerForJob = async () => {
    store.state.abortController = new AbortController();
}

const initializeAbortController = (next) => {
    store.state.abortController = new AbortController();
    next()
}

const resetAbortController = () => {
    store.state.abortController = null;
}

const getResponseErrorHandler = (error) => {
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.message && error.response.data.detail) {
                const errorMessage = error.response.data.detail;
                notifyDev(errorMessage, 'fail');
            }
            if (error.response.data.errors) {
                const errors = error.response.data.errors;
                let errorMessage = '';

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errorMessage += errors[key][0] + '. ';
                    }
                }
                notifyDev(errorMessage, 'fail');
            }
        }
    } else if (error.request) {
        if (error.request.response) {
            notifyDev(error.request.response, 'fail');
        } else if (error.request.responseText) {
            notifyDev(error.request.response, 'fail');
        } else {
            return;
        }
    }
}

const globalErrorHandler = (error) => {
    if (error.response) {
        if (process.env.NODE_ENV == 'production') {
            if (error.response.data) {
                if (error.response.data.message && error.response.data.detail) {
                    const errorMessage = error.response.data.detail;
                    notify(errorMessage, 'fail');
                }
                if (error.response.data.errors) {
                    const errors = error.response.data.errors;
                    let errorMessage = '';

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            errorMessage += errors[key][0] + '. ';
                        }
                    }
                    notify(errorMessage, 'fail');
                }
            }
        } else {
            if (error.response.data) {
                const statusCode = error.response.status;
                if (error.response.data.message && error.response.data.detail) {
                    let errorMessage = (statusCode ? ('STATUS CODE: ' + statusCode) : '') + '<br><br>ERROR: ' + error.response.data.detail;
                    if (error.response.data.stackTrace) {
                        errorMessage += ('<br><br>STACK TRACE:<br><br>' + error.response.data.stackTrace);
                    }
                    notifyDev(errorMessage, 'fail');
                }
                if (error.response.data.errors) {
                    const errors = error.response.data.errors;
                    let errorMessage = '';

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            errorMessage += errors[key][0] + '. ';
                        }
                    }
                    notifyDev(errorMessage, 'fail');
                }
            }
        }

    } else if (error.request) {
        if (error.request.response) {
            notify(error.request.response, 'fail');
        } else if (error.request.responseText) {
            notify(error.request.response, 'fail');
        } else {
            return;
        }
    }
}

function getEmpltyResponseList<T>() {
    return new ResponseList<T>(0, 0, 0, new Array<T>());
}

const getList = async <T>(url: string, {rootState, cancellable}) => {
    let resultSet = getEmpltyResponseList<T>();
    await axios.get<ResponseList<T>>(url, fnGetHeaders(cancellable))
        .then(function (response) {
            if (response != null && response.data != null) {
                rootState.listCount = response.data.value.length;
                rootState.totalRowCount = response.data.size;
                rootState.offset = response.data.offset;
                rootState.isListRequestCancelled = false;
                resultSet = response.data;
                return resultSet;
            }
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.message && error.response.data.detail) {
                        devLogger().logNewline(error.response.data.message);
                        devLogger().logNewline(error.response.data.detail);
                    }
                }
            } else if (error.request) {
                devLogger().logError(error.request)
            } else {
                devLogger().logError(error.message);
            }
            devLogger().logError(error.config);
            if (error.message == "canceled") {
                rootState.isListRequestCancelled = true;
            }
            return resultSet;
        });
    return resultSet;
}

const get = async <T>(url: string, cancellable?, includeVersioning?: boolean) => {
    let resultSet: T = null;
    await axios.get<T>(url, fnGetHeaders(cancellable, includeVersioning))
        .then(function (response) {
            if (response != null && response.data != null) {
                resultSet = response.data;
                return resultSet;
            }
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.message && error.response.data.detail) {
                        devLogger().logError(error.response.data.message);
                        devLogger().logError(error.response.data.detail);
                    }
                }
            } else if (error.request) {
                devLogger().logError(error.request);
            } else {
                devLogger().logError(error.message);
            }
            devLogger().logError(error.config);
            return resultSet;
        });
    return resultSet;
}

const post = async <T>(url: string, data) => {
    let resData: T = null;
    await axios.post<T>(url, data, fnGetHeaders())
        .then(function (response) {
            if (response != null) {
                resData = response.data;
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                notify(error.message, 'fail');
            }
            devLogger().logError(error.config);
        });

    return resData;
}

const put = async <T>(url: string, data: T) => {
    let resData: T = null;
    await axios.put<T>(url, data, fnGetHeaders())
        .then(function (response) {
            if (response != null) {
                resData = response.data;
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {

                notify(error.message, 'fail');
            }
            devLogger().logError(error.config);
        });
    return resData;
}

const deleteObject = async (url: string) => {
    let resData = false;
    await axios.delete(url, fnGetHeaders())
        .then(function (response) {
            if (response != null && response.status == 200) {
                resData = true;
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                notify(error.message, 'fail');
            }
        });
    return resData;
}

const cancelRequests = async () => {
    await store.state.abortController?.abort()
}

const getData = async (url: string, cancellable?: boolean, includeVersioning?: boolean) => {
    const resData = [];
    const headers = fnGetHeaders(cancellable, includeVersioning);
    await axios.get(url, headers)
        .then(function (response) {
            if (response != null) {
                resData.push(response);
            }
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.message && error.response.data.detail) {
                        devLogger().logError(error.response.data.message);
                        devLogger().logError(error.response.data.detail);
                    }
                }
            } else if (error.request) {
                devLogger().logError(error.request);
            } else {
                resData.push(error);
                devLogger().logError(error.message);
            }
            devLogger().logError(error.config);
            resData.push(error);
        });
    return resData[0];
}

const getDataRes = async (url: string, cancellable?: boolean) => {
    const resData = [];
    await axios.get(url, fnGetHeaders(cancellable))
        .then(function (response) {
            if (response != null) {
                resData.push(response);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                getResponseErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
            devLogger().logError(error.config);
        });
    return resData[0];
}

const getDataWithNoContent = async (url: string, cancellable?) => {
    const resData = [];
    await axios.get(url, fnGetHeaders(cancellable))
        .then(function (response) {
            resData.push(response);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.message && error.response.data.detail) {
                        devLogger().logError(error.response.data.message);
                        devLogger().logError(error.response.data.detail);
                    }
                }
            } else if (error.request) {
                devLogger().logError(error.request);
            } else {
                resData.push(error);
                devLogger().logError(error.message);
            }
            devLogger().logError(error.config);
            resData.push(error);
        });
    return resData[0];
}

const postData = async (url: string, data, includeVersioning = false) => {
    const resData = [];
    await axios.post(url, data, fnGetHeaders(true, includeVersioning))
        .then(function (response) {
            if (response != null) {
                resData.push([response.data]);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
            devLogger().logError(error.config);
        });

    return resData[0];
}

const postBulkFields = async (url: string, data) => {
    const resData = [];
    await axios.post(url, data, fnGetHeaders())
        .then(function (response) {
            if (response != null) {
                resData.push([response]);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
            devLogger().logError(error.config);
        });
    return resData[0];
}

const postDataWithoutHeader = async (url: string, data) => {
    const resData = [];
    await axios.post(url, data)
        .then(function (response) {
            if (response != null) {
                resData.push([response.data]);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
        });
    return resData[0];
}

const postWithoutDataWithoutAuth = async (url: string) => {
    const resData = [];
    await axios.post(url, null)
        .then(function (response) {
            if (response != null) {
                resData.push([response.data]);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
        });
    return resData[0];
}

const getDataWithoutAuth = async (url: string) => {
    const resData = [];
    await axios.get(url, {
        headers: {
            "Content-Type": "text/plain"
        }
    })
        .then(function (response) {
            if (response != null) {
                resData.push(response);
            }
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.message && error.response.data.detail) {
                        devLogger().logError(error.response.data.message);
                        devLogger().logError(error.response.data.detail);
                    }
                }
            } else if (error.request) {
                devLogger().logError(error.request);
            } else {
                resData.push(error);
                devLogger().logError(error.message);
            }
            devLogger().logError(error.config);
            resData.push(error);
        });
    return resData[0];
}

const putData = async (url: string, data: any, includeVersioning?: boolean) => {
    const resData = [];
    const headers = fnGetHeaders(true, includeVersioning);
    
    await axios.put(url, data, headers)
        .then(function (response) {
            if (response != null) {
                resData.push([response.data]);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }

            resData.push([null, error]);
        });
    return resData[0];
}

const putDataWithoutHeader = async (url: string, data) => {
    const resData = [];
    await axios.put(url, data)
        .then(function (response) {
            if (response != null) {
                resData.push([response.data]);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
        });
    return resData[0];
}

const putDataUrl = async (url: string) => {
    const resData = [];
    await axios.put(url, {}, fnGetHeaders())
        .then(function (response) {
            if (response != null) {
                resData.push(response);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
        });
    return resData[0];
}

const verifyWithoutAuth = async (url: string) => {
    const resData = [];
    await axios.put(url, {}, {
        headers: {
            "Content-Type": "text/plain"
        }
    })
        .then(function (response) {
            if (response != null) {
                resData.push(response.status);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);
        });
    return resData[0];
}

const deleteData = async (url: string, cancellable?) => {
    const resData = [];
    await axios.delete(url, fnGetHeaders(cancellable))
        .then(function (response) {
            if (response != null) {
                resData.push(response);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);

        });
    return resData[0];
}

const archiveData = async (url: string) => {
    const resData = [];
    await axios.post(url, store.state.selectedItems, fnGetHeaders())
        .then(function (response) {
            if (response != null) {
                resData.push(response);
            }
        })
        .catch(function (error) {
            if (error.response || error.request) {
                globalErrorHandler(error);
            } else {
                resData.push(error);
                notify(error.message, 'fail');
            }
            resData.push([null, error]);

        });
    return resData[0];
}

async function checkUrl(store) {
    if (localStorage.getItem("userId")) {
        let response = null;

        const url = buildUrl(store.state.baseUrl, {
            path: apiUsers + "/" + localStorage.getItem("userId")
        });

        response = await getData(url, true);

        if (response?.response?.status == 502) return [false, null];

        store.state.loginUserName = response.data.firstName + (response.data.lastName ? (" " + response.data.lastName) : '');
        if (response.data.businessProfile != null) {
            store.state.loginUserBusinessName = response.data.businessProfile.title
        }
        if (response.status == SUCCESS) {
            if (response.data.businessProfile != null &&
                response.data.businessProfile.title != null &&
                response.data.businessProfile.shortTitle != null &&
                (response.data.businessProfile.shortTitle.length >= ConstantValues.defaultBusinessProfilePrefixMinLength && response.data.businessProfile.shortTitle.length <= ConstantValues.defaultBusinessProfilePrefixLength) &&
                response.data.businessProfile.address.addressLine1 != null &&
                response.data.businessProfile.address.postalCode != null
            ) {
                store.state.hasBusinessProfile = true;
            } else {
                store.state.hasBusinessProfile = false;
            }
            return [true, response.data];
        }
    } else {
        store.dispatch("logout");
    }
}

async function updateToken(store) {
    if (store.state.refreshTokenRetries == REFRESH_TOKEN_RETRIES_LIMIT) {
        store.dispatch('clearLocalData');
    }
    const formData = new FormData();
    formData.append("accessToken", getCookie('tkn'));
    formData.append("refreshToken", getCookie('rft'));
    formData.append("clientType", ConstantValues.clientType);

    const url = buildUrl(store.state.baseUrl, {
        path: apiRefreshToken
    });
    const result = await postDataWithoutHeader(url, formData);

    if (result && result.length != 0) {

        await store.dispatch("saveToken", result[0]);
        store.state.refreshTokenRetries = 0;
        return result[0];
    }
    else {
        store.dispatch('clearLocalData');
        return null;
    }
}

async function handleSuperAdminForbiddenRequest(result) {
    if(result?.request?.status === FORBIDDEN) {
        store.dispatch('logout')
    }
}

axios.interceptors.response.use(null, (error: any) => {
    if (error.config && error.response && error.response.status === UNAUTHORIZED && getCookie("tkn")) {
        store.state.refreshTokenRetries = store.state.refreshTokenRetries + 1;
        const config = error.config;
        return updateToken(store).then((result) => {

            if (result != null) {
                config.headers.Authorization = "Bearer " + getCookie("tkn");
                return axios.request(config);
            }
        });
    }
    return Promise.reject(error);
});

export default {
    get,
    getList,
    post,
    put,
    deleteObject,
    getData,
    getDataRes,
    getDataWithoutAuth,
    postData,
    putData,
    putDataUrl,
    deleteData,
    checkUrl,
    updateToken,
    postDataWithoutHeader,
    postWithoutDataWithoutAuth,
    verifyWithoutAuth,
    getDataWithNoContent,
    putDataWithoutHeader,
    postBulkFields,
    cancelRequests,
    cancelPendingRequests,
    initializeCancelRequests,
    initializeAbortController,
    initializeAbortControllerForJob,
    resetAbortController,
    handleSuperAdminForbiddenRequest
}