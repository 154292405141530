












































































































































































































import $ from 'jquery';
import { mapMutations } from 'vuex';
import { defaultScrollViewHeight } from '@/utils/uiconstants';
import { openOverlay } from '@/utils/helpers';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import Avatar from '@/components/shared/avatars/avatar.vue';
import moment from 'moment';
import contractorsComputedMixin from '@/components/contractors/contractors-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import skeletonLoaderMixin from '@/mixins/skeleton-loader-mixin';
import List from "@/components/shared/list.vue";
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import { ActionListingDialog } from "@/components/shared/dialogs/index";
import { listAction } from '@/enum/listAction';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';

export default {
    name: "ContractorListView",
    mixins: [
        permissionsMixin, 
        scrollerMixin, 
        contractorsComputedMixin, 
        skeletonLoaderMixin,
        Timers
    ],
    components: {
        Avatar,
        Button,
        List,
        ActionListingDialog,
        Snackbar,
        DeleteItemDialog
    },
    data() {
        return {
                defaultScrollViewHeight,
                searchTimer: null,
                isChecked: false,
                deleteContractorId: null,
                dialogContractor: false,
                actionType: listAction.Archive,
                listAction
           }
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems.length > 0) {
                this.snackbarShow = true;
            } else {
                this.snackbarShow = false;
                this.isChecked = false;
            }
        },
        contractorFilterType() {
            this.$store.commit('clearSnackbarDetails');
        }
    },
    computed: {
        someContractorsSelected() {
            return this.selectedItems.length > 0 && !this.allContractorsSelected
        },
        allContractorsSelected() {
           if(this.contractorSearchText){
                return false
            }
            else {
                return (this.selectedItems.length == this.contractorFilterList.length)
            }
        },
    },
    beforeMount() {
        this.clearAndResetContractorPagingStates();
    },
    beforeDestroy() {
        this.clearAndResetContractorPagingStates();
        this.$store.commit('clearSnackbarDetails');
    },
    mounted() {
        if (!this.$store.state.isPageRelated) {
            localStorage.removeItem('contractorSearchQuery');
            localStorage.removeItem('contractorFilterType');
        }
        this.setFilterOptionsAndGetContractors([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
    },
    methods: {
        ...mapMutations({
            clearContractorFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            setClearInviteeProfile: "setClearInviteeProfile",
            resetSnackbarDetails: "clearSnackbarDetails",
        }),
        applySearchContractorFilter() {
            this.clearContractorFilterOption();
            this.resetPaging();
            this.contractorFilterList = [];
            this.pendingContractorList = [];
            this.filtersApplied = true;
            this.$nextTick(() => {
                this.setFilterOptionsAndGetContractors([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
            })
        },
        clearAndResetContractorPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.contractorsModule.contractorFilterList = [];
            this.clearContractorFilterOption();
            this.resetSnackbarDetails();
            this.resetPaging();
            this.contractorFilterType = 0;
            this.filtersApplied = false;
        },
        openAddContractorForm() {
            this.setClearInviteeProfile();
            openOverlay("addContractorFormOverlay");
        },
        
        openManageContractorForm() {
            this.getConnectionRequests();
            openOverlay("manageContractorFormOverlay");
        },
        getConnectionRequests() {
            this.$store.dispatch('getConnectionRequestsReceived');
            this.$store.dispatch('getConnectionRequestsSent');
        },
        getTimeDateFormate(date: any) {
            return moment.utc(date).local().format('DD MMM YYYY')
        },
        getContactorListByStatus() {
            this.applySearchContractorFilter();
            this.noAcceptedContractors = false;
            this.noPendingContractors = false;
        },
        getBusinessAddress(data: any) {
            if (data != null && data.address != null) {
                const addr = {
                    add2: data.address.addressLine2 != null ? data.address.addressLine2 : '',
                    city: data.address.city != null ? data.address.city : '',
                    country: data.address.country != null ? data.address.country : ''
                }
                return data.title + ", " + data.address.addressLine1 + " " + addr.add2 + " " + addr.city + " " + addr.country;
            } else {
                if (data != null && data.address == null) {
                    return data.title;
                }
            }
            return 'N/A';
        },
        setFilterOptionsAndGetContractors(filter) {
            if (!this.contractorSearchText) {
                this.clearContractorFilterOption();
                this.filtersApplied = false;
            }
            this.$store.dispatch("setContractorFilter", filter).then(res => {
                if (this.contractorFilterType == 0) {
                    this.$store.dispatch("getContractorFiltered");
                } else if (this.contractorFilterType == 1) {
                    this.$store.dispatch("getPendingContractorFiltered");
                }
            });
        },

        selectAllContractors() {
            this.selectedItems = [];
            this.allItemsDeleted = false;
            if (this.isChecked) {
                this.contractorFilterList.map((pro) => {
                    this.selectedItems.push(pro.id);
                });
            }
        },

        confirmDeleteContractor(id) {
            this.actionType = listAction.Delete
            this.deleteContractorId = id
            this.dialogContractor = true
        },

        proceedContractorDeletion: function () {
            this.dialogContractor = false
            this.contractorFilterList = []

            switch(this.actionType) {
                case listAction.Delete:
                case listAction.Archive:
                        this.$store.dispatch('deleteContractor', this.deleteContractorId).then(() => {
                            this.setFilterOptionsAndGetContractors([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
                        })
                        break
                default:
                    return
            }
        },
    }
}
