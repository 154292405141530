import { apiTeamMembers, apiUsers, apiInvitation, apiAccount } from '@/utils/endpoints';
import { notify, devLogger, deleteObjectFromList } from '@/utils/helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import { UserRoleType } from '@/enum/userRoleType';
import { ResponseList, User } from '@/data/models';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { listAction } from '@/enum/listAction';

export const actions = {
    async getTeamMembers({ rootState, commit, dispatch }) {

        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);

        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + '/' + apiTeamMembers + rootState.tempUrl
        });
        
        const result: ResponseList<User> = await requests.getList<User>(url, {
            rootState, 
            cancellable: true
        });
        if (result != null) {
            if (result.value.length > 0) {
                commit('setTeamMembersList', result.value);
                dispatch("appendLoadedItemsToSelections", result.value);
            }
            if (rootState.offset == 0 && !rootState.isListRequestCancelled) {
                commit('setNoDataStatus', result.value);
            }
            rootState.listLoading = false;
        } else {
            devLogger().logError(result);
        }
    },

    async getTeamMember({ state, commit }, teamMemberId) {
        if (state.teamMembersList.length != 0) {
            const teamMember = state.teamMembersList.filter(teamMember => teamMember.id == teamMemberId)[0];
            commit('setTeamMemberDetails', teamMember);
        }
    },

    async fnSetTeamMembersFilter({ commit, state }, data) {
        if (state.teamMemberSearchText?.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamMemberSearchText]);
        }
        commit('setFilterOptions', ['Preference', state.memberFilterType])
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async updateTeamMemberRole({ state, rootState }, data) {
        state.teamMembersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiAccount + '/' + data[0] + '/roles'
        });
        const result = await requests.putData(url, data[1]);
        if (result[0] != null) {
            state.teamMembersLoader = false;
            notify(StringConstants.teamMemberUpdateSuccessMessage, 'success');
            return result[0];
        } else {
            state.teamMembersLoader = false;
        }
    },

    async updateTeamMembersRole({ state, rootState, dispatch }, { newRole, allSelected }) {
        state.teamMembersLoader = true
        dispatch("jsonToQueryString", rootState.filterOptions)

        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + '/' + apiTeamMembers + '/' + newRole + (allSelected ? '' : rootState.tempUrl)
        })

        const result = await requests.putData(url, {})
        
        state.teamMembersLoader = false

        if (result[0] != null) {
            notify(StringConstants.teamMembersRoleChangeSuccessMessage, 'success')
            return result[0]
        }
    },

    async archiveTeamMember({ state, rootState, commit}, id) {
        rootState.listLoading = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + '/' + apiTeamMembers + '/' + id + '/archive'
        });
        const result = await requests.postData(url, {});
        if (result[0] != null) {
            notify(StringConstants.teamMemberArchiveSuccessMessage, 'success');
            deleteObjectFromList(state.teamMembersList, id, 'id');
            rootState.totalRowCount--;
            commit('setNoDataStatus', state.teamMembersList)
            rootState.listLoading = false;
            return true;
        }
        rootState.listLoading = false;
    },

    async restoreTeamMember({ state, rootState, commit }, id) {
        rootState.listLoading = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + '/' + apiTeamMembers + '/' + id + '/restore'
        });
        const result = await requests.postData(url, {});
        if (result[0] != null) {
            notify(StringConstants.teamMemberRestoreSuccessMessage, 'success');
            deleteObjectFromList(state.teamMembersList, id, 'id');
            rootState.totalRowCount--;
            commit('setNoDataStatus', state.teamMembersList);
            rootState.listLoading = false;
            return true;
        }
        rootState.listLoading = false;
    },

    async getPendingTeamMembers({ rootState, state, commit, dispatch }) {
        rootState.listLoading = true
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + rootState.tempUrl + '&showSent=true&roles=' + UserRoleType.Manager + ',' + UserRoleType.TeamLead + ',' + UserRoleType.Operator
        });
        const result: ResponseList<User> = await requests.getList<User>(url, {
            rootState, 
            cancellable: true
        });
        if (result != null) {
            if (result.value.length > 0) {
                commit('setPendingTeamMembersList', result.value);
            }
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.value);
            }
            rootState.listLoading = false;
        } else {
            devLogger().logError(result);
        }
    },

    async actionOnTeamMember({ state, rootState }, data) {
        const actionType = data[0];
        const id = data[1];
        const path = localStorage.getItem(ConstantValues.ownerId) + apiTeamMembers + "/" + id;
        const preferenceParms = "?Preference=" + state.memberFilterType;

        switch (actionType) {
            case listAction.Delete: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + preferenceParms
                });
                const result = await requests.deleteObject(url);
                if (result) {
                    notify('Team member deleted successfully!', 'success');
                    deleteObjectFromList(state.teamMembersList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.teamMembersList.length === 0 ? true : false;
                } else {
                    notify(StringConstants.notArchivedText, 'fail');
                }

                break;
            }
            case listAction.Archive: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + '/archive' + preferenceParms
                });
                const result = await requests.postData(url, {});
                if (result != null) {
                    notify('Team member archived succesfully!', 'success');
                    deleteObjectFromList(state.teamMembersList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.teamMembersList.length === 0 ? true : false;
                }
                else {
                    notify(StringConstants.notArchivedText, 'fail');
                }
                break;
            }
            case listAction.Restore: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + "/restore" + preferenceParms
                });
                const result = await requests.postData(url, {});
                if (result != null) {
                    notify('Team member restored successfully!', 'success');
                    deleteObjectFromList(state.teamMembersList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.teamMembersList.length === 0 ? true : false;
                }
                else {
                    notify(StringConstants.notArchivedText, 'fail');
                }
                break;
            }
        }
    },
}