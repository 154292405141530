











import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsComponents from '@/components/team-jobs/common/team-jobs-all.vue';
import TeamJobsAddSaveButtons from '@/components/team-jobs/team-jobs-add/team-jobs-add-save.vue';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import { UserRoleType } from '@/enum/userRoleType';
import { TeamJobComponentConfiguration } from '@/data/models/TeamJobComponentConfiguration';
import { TeamJobViewPreference } from '@/data/models/TeamJobViewPreference';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import ConstantValues from '@/plugins/constantValues';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
import { closeNotify, listContainsItem, notify, textToHtml } from '@/utils/helpers';
import { MinimalUser } from '@/data/models/MinimalUser';
import { createJobOperatorsForOperatorsView, getResourcesForAssignedOperators } from '@/utils/helpers/jobs-helpers';
import { TeamJobOperatorChecklistResourceType } from '@/enum/teamJobOperatorChecklistResourceType';
import moment from 'moment';
import { TeamJobMetaData } from '@/data/models/TeamJobMetaData';
import { TeamJobTimeDetails } from '@/data/models/TeamJobTimeDetails';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import { JobAttachmentPurposeType } from '@/enum/jobAttachmentPurposeType';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';

    export default {
        name: "TeamJobsAdd",
        mixins: [TeamJobsComputed, TeamJobsCommon, requestsCancellationMixin, breadcrumbsMixin],
        mounted() {
            this.mountTeamJobAdd();
            this.$store.dispatch("getGlobalCountriesList");
        },
        computed: {
          isDuplicatePage: {
            get() {
              return this.$route.name == 'Team jobs duplicate'
            }
          }
        },
        components: {
            TeamJobsComponents,
            TeamJobsAddSaveButtons
        },
        beforeMount() {
            this.$store.commit('resetTeamJobs');
        },
        beforeDestroy() {
            this.$store.commit('resetTeamJobs');
            this.$store.dispatch("destroyMapInstance");
        },
        methods: {
            async mountTeamJobAdd() {
                await this.$store.dispatch('initializeTeamJobsMap');
                await this.initTeamJobsAdd();
                await this.setFieldCustomerFilter();
                if (this.isDuplicatePage) {
                    this.$nextTick(async () => {
                        await this.initializeDataToDuplicate();
                    })
                }
            },
            async setFieldCustomerFilter() {
                if (this.teamJobUser) {
                    this.teamJobFieldCustomerId = this.teamJobUser.id;
                    if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                       await this.$store.dispatch('filterCustomerFieldsTeamJob');
                    }
                }
            },
            setAddTeamsJobViewPreference() {
                //set status preference
                const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
                this.teamJobViewPreference.status = new TeamJobViewPreference().setStatusPreference(newViewConfiguration);
                this.teamJobViewPreference.locationHistoryMap = new TeamJobViewPreference().setLocationHistoryMapPreference(newViewConfiguration);
                this.teamJobViewPreference.timeDetails = new TeamJobViewPreference().setTimeDetailsPreference(newViewConfiguration);

                //set operators preference
                const newViewEnabledConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
                this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.customers = new TeamJobViewPreference().setCustomerPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.scheduledDate = new TeamJobViewPreference().setScheduledDataPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.operation = new TeamJobViewPreference().setOperationPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.tags = new TeamJobViewPreference().setTagsPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.instructions = new TeamJobViewPreference().setInstructionsPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.instructionPhotos = new TeamJobViewPreference().setInstructionPhotosPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.fields = new TeamJobViewPreference().setFieldsPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.metaData = new TeamJobViewPreference().setMetaDataPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.products = new TeamJobViewPreference().setProductsPreference(newViewEnabledConfiguration);
                
                this.teamJobViewPreference.locations = new TeamJobViewPreference().setLocationsPreference(newViewEnabledConfiguration);
                this.teamJobViewPreference.billingInformation = new TeamJobViewPreference().setBillingInformationPreference(newViewEnabledConfiguration);

                //set job completion preference
                const newJobCompletionViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
                this.teamJobViewPreference.completionInformation = new TeamJobViewPreference().setCompletionInformationPreference(newJobCompletionViewConfiguration);
                
                this.hideBillingQuantities();
                this.editableTeamUser();
            },
            async initTeamJobsAdd() {
                const pageId = Math.random().toString(36).substr(2, 9);
                if (this.fieldRequesterId == null) {
                    this.fieldRequesterId = pageId;
                }
                this.setAddTeamsJobViewPreference();

                this.setBreadcrumbs('TeamJobsAdd');

                if (getLoggedInUserRole().isUserOwnerFarmerLoggedIn) {
                    this.teamJobOwnerType = UserRoleType.Contractor;
                }
                await this.$store.dispatch('getTeamJobsOwners');

                this.$store.dispatch('getTagsForTeamJobs');
                
                this.teamJobOperationOwnerId = getOwnerId();

                await this.$store.dispatch('getTeamJobOperations');
                this.$store.commit('setClearFilterOptions');

                await this.$store.dispatch('getTeamJobTeamMembers');
                this.$store.commit('setClearFilterOptions');

                await this.$store.dispatch('getTeamJobProducts');
                this.$store.commit('setClearFilterOptions');

                await this.$store.dispatch('getTeamJobAddresses');
                this.$store.commit('setClearFilterOptions');

                await this.$store.dispatch('getTeamJobVehiclesList');
                this.$store.commit('setClearFilterOptions');

                await this.$store.dispatch('getTeamJobImplementsList');
                this.$store.commit('setClearFilterOptions');

                if (!this.isDuplicatePage) {
                  this.$nextTick(async () => {
                    await this.$store.dispatch('getTeamJobFields', pageId);
                  })
                }
            },
            async initializeDataToDuplicate() {
                const jobId = this.$route.params.id;
                await this.$store.dispatch('duplicateTeamJobData', jobId);
                const jobResponse = this.$store.state.teamJobsModule.teamJobsDataToDuplicate
                if (!jobResponse) return;

                //contractor logged in
                if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                    this.teamJobOwnerType = UserRoleType.Farmer;
                    this.teamJobOperationOwnerId = getOwnerId();
                }

                //farmer logged in
                if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                    this.teamJobOwnerType = UserRoleType.Contractor;
                    this.teamJobOperationOwnerId = jobResponse.contractor.id;
                }

                //owners list
                let user = null;
                if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                    user = this.teamJobUsersList.find(customer => customer.id == jobResponse.customer.id);
                    if (user) {
                        this.teamJobUser = new MinimalUser().getMinimalUser(user)
                    }
                    else {
                        if (!listContainsItem(this.teamJobUsersList, ['id'], jobResponse.customer.id)) {
                            this.teamJobUsersList.push(new MinimalUser().getMinimalUser(jobResponse.customer));
                        }
                        this.teamJobUser = new MinimalUser().getMinimalUser(jobResponse.customer);
                    }
                }

                if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                    user = this.teamJobUsersList.find(contractor => contractor.id == jobResponse.contractor.id);
                    if (user) {
                        this.teamJobUser = new MinimalUser().getMinimalUser(user)
                    }
                    else {
                        if (!listContainsItem(this.teamJobUsersList, ['id'], jobResponse.contractor.id)) {
                            this.teamJobUsersList.push(new MinimalUser().getMinimalUser(jobResponse.contractor));
                        }
                        this.teamJobUser = new MinimalUser().getMinimalUser(jobResponse.contractor);
                    }
                }

                const jobResponseOperators = jobResponse.operators;

                //set operators
                this.teamJobOperators = createJobOperatorsForOperatorsView(
                    this.teamJobOperators,
                    jobResponseOperators
                )

                //set resources for assigned operators
                getResourcesForAssignedOperators(
                    jobResponseOperators,
                    this.teamJobVehiclesListForOperators,
                    TeamJobOperatorChecklistResourceType.Vehicle
                );

                getResourcesForAssignedOperators(
                    jobResponseOperators,
                    this.teamJobImplementsListForOperators,
                    TeamJobOperatorChecklistResourceType.Implement
                );

                // clear unneccessary data from operators
                if (this.teamJobOperators?.length > 0) {
                    this.teamJobOperators.map((operator) => {
                        if (operator?.operatorDetails?.checklists?.length > 0) {
                            operator.operatorDetails.checklists.map(chk => {
                                chk.items.map(item => {
                                    item.completedOn = null
                                })
                            })
                        }

                        operator.operatorDetails.timeline = []
                        operator.operatorDetails.travelHistory = []
                        operator.operatorDetails.travelStatus = null
                        operator.operatorDetails.workHistory = []
                        operator.operatorDetails.workStatus = null
                    })
                }

                //scheduled date
                if (jobResponse.status < TeamJobStatusType.Completed && jobResponse.scheduledAt) {
                    this.teamJobScheduledDate = moment.utc(jobResponse.scheduledAt).local().format()
                } else {
                    this.teamJobScheduledDate = null
                }

                //operations
                await this.handleTeamJobOperationBinding(jobResponse.operation.id, jobResponse.operation);

                //instructions
                if (jobResponse.instructions) {
                    this.teamJobInstructions = textToHtml(jobResponse.instructions);
                    this.tempTeamJobInstructions = textToHtml(jobResponse.instructions);
                    this.previousOperationDescription = jobResponse.instructions;
                }

                //attachements
                this.teamJobAttachments = []
                if (jobResponse?.attachments?.length > 0) {
                    const reuploadAttachmentUrls = []

                    jobResponse.attachments.map(a => {
                        if (a.purpose == JobAttachmentPurposeType.Creation)
                        reuploadAttachmentUrls.push(a.url)
                })
                
                if (reuploadAttachmentUrls?.length > 0) {
                  const uploadedAttachments = await this.$store.dispatch('reuploadAttachments', reuploadAttachmentUrls)
                    
                    uploadedAttachments?.map(async (attachment) => {
                      const fileType = await this.$store.dispatch('getFileType', attachment.path)

                      this.teamJobAttachments.push({
                          purpose: JobAttachmentPurposeType.Creation,
                          order: attachment.order,
                          type: fileType,
                          url: attachment.path
                      })
                    })
                  }
                }

                //job fields
                this.$nextTick(async () => {
                  let callbackFn

                  if (jobResponse.fields.length != 0) {
                    callbackFn = async () => {
                        await jobResponse.fields.map(async (f) => {
                            await this.$store.dispatch('storeSelectedFieldOnTeamJobsMap', f)
                        })
                        await this.$store.dispatch('drawFieldsGeoJsonOnLocationMap', jobResponse.fields)
                        const ids = jobResponse.fields.map(f => f.id)
                        this.$store.dispatch('highlightFieldsAndSetMapBound', ids)
                    }
                  }

                  await this.$store.dispatch('getTeamJobFields', callbackFn);
                })
                
                //meta data
                if (jobResponse.metaData) {
                    jobResponse.metaData.map(x => (this.teamJobMetaDataList.push(new TeamJobMetaData().getTeamJobMetaData(x))));
                }

                // clear values from meta data
                if (this.teamJobMetaDataList?.length > 0) {
                    this.teamJobMetaDataList.map((metaData) => {
                        metaData.value = null
                    })
                }

                //time details
                this.teamJobTimeDetailsList = []
                this.teamJobTempTimeDetailsList = []

                //products
                this.$store.commit('setClearFilterOptions');
                this.teamJobProducts = jobResponse.products.map((product) => new TeamJobProduct().getTeamJobProduct(product))

                //clear product usages
                if (this.teamJobProducts?.length > 0) {
                    this.teamJobProducts.map((product) => {
                        product.usages.map((u) => {
                            u.usage = 0
                        })
                    })
                }

                //addresses
                this.teamJobAddresses = [];
                const responseAddresses = jobResponse.addresses;    

                if (responseAddresses && responseAddresses) {
                    responseAddresses.forEach((addr) => {
                        this.teamJobAddresses.push(new TeamJobAddress().getTeamJobAddress(addr))
                    })
                }

                //team job notes
                this.teamJobNotes = '';
                this.tempTeamJobNotes = '';

                closeNotify();
            }
        }
    }
