import { apiUsers, apiFarmers, apiInvitation, apiCustomersImport, apiCustomersUpdateFromIntegration } from '@/utils/endpoints';
import request from '@/utils/requests';
import { deleteObjectFromList, notify } from '@/utils/helpers';
import buildUrl from 'build-url';
import { UserRoleType } from '@/enum/userRoleType';
import StringConstants from '@/plugins/stringConstants';
import { ResponseList, User } from '@/data/models';
import ConstantValues from '@/plugins/constantValues';
import { listAction } from '@/enum/listAction';

export const actions = {
    async getFarmersFiltered({ state, commit, rootState, dispatch }) {
        if (rootState.loginUserRole != 1) {
            state.farmersLoader = true;
            rootState.listLoading = true;
            dispatch("jsonToQueryString", rootState.filterOptions);
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + rootState.tempUrl + ConstantValues.preferenceQuery + state.farmerStatus
            });
            const result: ResponseList<User> = await request.getList(url, {
                rootState,
                cancellable: true
            });
            if (result != null) {
                if (result.value.length > 0) {
                    commit('setFarmersFilteredList', result.value);
                    dispatch("appendLoadedItemsToSelections", result.value);
                }
                if (rootState.offset == 0 && !rootState.isListRequestCancelled) {
                    commit('setNoDataStatus', result.value);
                }
                state.farmersLoader = false;
                rootState.listLoading = false;
            }
        }
    },

    async setFarmersFilter({ commit, state }, data) {
        if (state.farmerSearchText && state.farmerSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.farmerSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        if (state.farmerType != null) {
            commit('setFilterOptions', ['IsInternal', state.farmerType]);
        }
    },

    async deleteFarmer({ rootState, state, commit }, farmerId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + farmerId+ '/archive'
        });
        const result = await request.postData(url, farmerId);

        if (result != null && result.length && result?.[0] !== null) {
            notify(StringConstants.farmerDeleted, 'success');
            deleteObjectFromList(state.farmersFilterList, farmerId, 'id');
            rootState.totalRowCount--;
            commit('setNoDataStatus', state.farmersFilterList);
            return result.data;
        }
    },

    async archiveFarmer({ rootState, state, commit }, farmerId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + farmerId + '/archive'
        });
        const result = await request.postData(url, farmerId);

        if (result != null && result.length && result?.[0] !== null) {
            notify('Customer archived successfully!', 'success');
            deleteObjectFromList(state.farmersFilterList, farmerId, 'id');
            rootState.totalRowCount--;
            commit('setNoDataStatus', state.farmersFilterList);
            return result.data;
        }
    },

    async restoreFarmer({ rootState, state, commit }, farmerId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + farmerId + '/restore'
        });
        const result = await request.postData(url, farmerId);

        if (result != null && result.length && result?.[0] !== null) {
            notify('Customer restored successfully!', 'success');
            deleteObjectFromList(state.farmersFilterList, farmerId, 'id');
            rootState.totalRowCount--;
            commit('setNoDataStatus', state.farmersFilterList);
            return result.data;
        }
    },

    async getPendingFarmersFiltered({ state, rootState, dispatch, commit }) {
        state.farmersLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + rootState.tempUrl + ConstantValues.preferenceQuery + rootState.enumPreference['NotArchived'] + '&showSent=true&roles=' + UserRoleType.Farmer
        });
        const result = await request.getData(url);
        if (result != null && 'data' in result) {
            if (result.data != null) {
                rootState.listCount = result.data.value.length;
                rootState.totalRowCount = result.data.size;
                if (result.data.value.length > 0) {
                    commit('setPendingFarmersFilteredList', result.data.value);
                }
                if (rootState.offset == 0) {
                    commit('setNoDataStatus', result.data.value);
                }
            }
            state.farmersLoader = false;
        }
    },

    async importCustomersExcel({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + "/" + localStorage.getItem(ConstantValues.ownerId) + apiCustomersImport
        })
        const result = await request.postData(url, data)
        if (result[0] != null) {
            return result[0]
        }
    },

    async getFarmersConReq({ rootState, state, commit }) {
        state.farmerConReqList = [];
        rootState.conReqLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + "?roles=1&showSent=false"
        });
        const result = await request.getData(url, true);
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setFarmerConReqList', result.data.value);
                }
            }
        }
        rootState.conReqLoader = false;
    },

    async getFarmersSendReq({ rootState, state, commit }) {
        state.farmerSendReqList = [];
        rootState.conSendLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + "?roles=1&showSent=true"
        });
        const result = await request.getData(url);
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setFarmerSendReqList', result.data.value);
                }
            }
        }
        rootState.conSendLoader = false;
    },

    async updateIntegratedCustomerDetails({ state, rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + "/" + data[1] + "/contacts" + "/" + data[0].id
        });
        const result = await request.putData(url, data[0]);
        if (result[0] != null) {
            notify(StringConstants.farmerUpdated, 'success');
            return result[0];
        }
    },

    async fnSetIntegrationContactsFilter({ state, commit }, data) {
        if (state.integrationContactSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.integrationContactSearchText]);
        }
    },

    async getIntegrationContacts({ state, commit, rootState, dispatch }, type) {
        const loginOwnerId = localStorage.getItem(ConstantValues.ownerId);
        if (rootState.listCount == 0) {
            state.customerLoaderImport = true;
        }
        state.importIntegratedCustomerLoader = true;
        state.fetchingintergrationDataProgressCustomer = true;

        commit('setClearFilterOptions');
        commit('setFilterOptions', ['isDisabled', true]);
        const defaultOffset = state.tempIntegratedCustomers.length;
        
        await dispatch('fnSetIntegrationContactsFilter', [defaultOffset, ConstantValues.defaultPageLimitSizeCustomer])


        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: loginOwnerId + "/" + type + "/contacts" + rootState.tempUrl
        });
        const result = await request.getDataRes(url, true);

        if (result != null && 'data' in result) {
            state.fetchingintergrationDataProgressCustomer = false;

            if (result.data != null && result.data.value && result.data.value.length != 0) {
                result.data.value.forEach(data => { 
                    state.tempIntegratedCustomers.push(data)
                })
                const filteredCustomers = result.data.value.filter(contact => contact.customer == null);
                if (filteredCustomers.length < ConstantValues.defaultPageLimitSizeCustomer && state.tempIntegratedCustomers.length < result.data.size) {
                    
                    commit("setIntegrationContacts", filteredCustomers);
                    commit('setClearFilterOptions');
                    const defaultOffset = result.data.offset + ConstantValues.defaultPageLimitSizeCustomer;
                    
                    await dispatch('fnSetIntegrationContactsFilter', [defaultOffset, ConstantValues.defaultPageLimitSizeCustomer])
                    await dispatch('getIntegrationContacts', type);
                } else {
                    commit("setIntegrationContacts", filteredCustomers);
                }
            }
            if (result.data != null && result.data.value && !(result.data.value.length < ConstantValues.defaultPageLimitSizeCustomer && rootState.listCount < ConstantValues.defaultPageLimitSizeCustomer && rootState.listCount != 0)) {
                rootState.listCount = result.data.value.length;
                state.loadedContactsCustomer += rootState.listCount;

                if (result.data.value.length == 0) {
                    state.intergrationHasDataCustomer = false;
                } else {
                    state.intergrationHasDataCustomer = true;
                }
            } else {
                if (result.data != null) {
                    if (result.data.value == null) {
                        state.allIntegrationCustomersLoaded = false;
                        state.fetchingintergrationDataProgressCustomer = false;
                    }
                    state.allIntegrationCustomersLoaded = true;
                    state.fetchingintergrationDataProgressCustomer = false;
                }
            }
        }
        state.importIntegratedCustomerLoader = false;
        state.customerLoaderImport = false;
    },

    async actionOnFarmer({ state, rootState }, data) {
        const actionType = data[0];
        const id = data[1];
        const path = localStorage.getItem(ConstantValues.ownerId) + apiFarmers + "/" + id;
        const preferenceParms = "?Preference=" + state.farmerStatus;

        switch (actionType) {
            case listAction.Delete: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + preferenceParms
                });
                const result = await request.deleteObject(url);
                if (result) {
                    notify('Customer deleted successfully!', 'success');
                    deleteObjectFromList(state.farmersFilterList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.farmersFilterList.length === 0 ? true : false;
                } else {
                    notify(StringConstants.notArchivedText, 'fail');
                }

                break;
            }
            case listAction.Archive: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + '/archive' + preferenceParms
                });
                const result = await request.postData(url, {});
                if (result != null) {
                    notify('Customer archived succesfully!', 'success');
                    deleteObjectFromList(state.farmersFilterList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.farmersFilterList.length === 0 ? true : false;
                }
                else {
                    notify(StringConstants.notArchivedText, 'fail');
                }
                break;
            }
            case listAction.Restore: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + "/restore" + preferenceParms
                });
                const result = await request.postData(url, {});
                if (result != null) {
                    notify('Customer restored successfully!', 'success');
                    deleteObjectFromList(state.farmersFilterList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.farmersFilterList.length === 0 ? true : false;
                }
                else {
                    notify(StringConstants.notArchivedText, 'fail');
                }
                break;
            }
        }
    },

    async updateCustomersFromInvoicingService({ state, rootState, dispatch }, { allSelected }) {
        state.farmersLoader = true
        rootState.bulkActionResponse = null

        dispatch("jsonToQueryString", rootState.filterOptions)

        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiCustomersUpdateFromIntegration + (allSelected ? '' : rootState.tempUrl)
        })
        
        const result = await request.postData(url, {})
        state.farmersLoader = false

        if (result[0] != null) {
            if(result[0].failed) {
                rootState.dialogBulkActionResponseTitle = StringConstants.bulkActionResponseTitleCustomersUpdate
                rootState.dialogBulkActionResponse = true
                rootState.bulkActionResponse = result[0]
            } else {
                notify(StringConstants.farmersUpdatedFromInvoicingService, 'success')
            }
            return result[0]
        }
    },

    async seedFarmers({ state, rootState }, data) {
        state.addFarmerLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers
        });
        const result = await request.postData(url, data);
        state.addFarmerLoader = false;

        return result;
    },
}