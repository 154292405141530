



































































































































































































import { openOverlay, getCapitalised } from "@/utils/helpers";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import vehiclesComputedMixin from "@/components/machines/machines-computed";
import snackbarDelete from "@/components/shared/snackbar/snackbar-listing.vue";
import { ActionListingDialog, DeleteItemDialog as ItemDialog } from "@/components/shared/dialogs/index";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { listAction } from '@/enum/listAction';
import { MachineType as MachineTypeInfo } from '@/enum/machineType';
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { TabFilterType } from "@/enum/tabFilterType";
import { mapMutations } from 'vuex';

export default {
    name: "VehiclesListView",
    created() {
        this.TabFilterType = TabFilterType
    },
    mixins: [
        permissionsMixin,
        scrollerMixin,
        filterParamsMixin,
        vehiclesComputedMixin,
        Timers
    ],
    components: {
        ItemDialog,
        snackbarDelete,
        ActionListingDialog,
        Button,
        List
    },
    computed: {
        someMachinesSelected() {
            return this.selectedItems.length > 0 && !this.allMachinesSelected;
        },
        allMachinesSelected() {
            if(this.machineSearchText){
                return false;
            }
            else {
            return this.selectedItems.length == this.machineListFiltered.length;
            }
        },
        moduleTitle() {
            if (this.machineType == MachineTypeInfo.Vehicle) {
                return this.$stringConstants('vehiclesTitle')
            } else if (this.machineType == MachineTypeInfo.Implement) {
                return this.$stringConstants('implementsTitle')
            }

            return ''
        }
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems.length > 0) {
                this.snackbarShow = true;
            } else {
                this.snackbarShow = false;
                this.isChecked = false;
            }
        },
        machinesFilterType() {
            this.$store.commit('clearSnackbarDetails');
        }
    },
    data() {
        return {
            deleteDialog: false,
            actionType: listAction.Delete,
            listAction,
            actionOnMachineId: "",
            isChecked: false,
            MachineTypeInfo,
            ViewPreferenceType,
            searchTimer: null,
        };
    },
    beforeMount() {
        this.clearAndResetMachinesPagingStates();
    },
    beforeDestroy() {
        this.clearAndResetMachinesPagingStates();
        this.$store.commit('clearSnackbarDetails');
    },
    mounted() {
        this.$store.state.dashboardTitle = this.moduleTitle;
        this.getUrlParamsMachineLocal();
        this.setFilterOptionsAndGetMachines([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
    },
    methods: {
        ...mapMutations({
            resetImportForm: "resetImportDataDetails"
        }),
        fnGetHeaderString(actionType: listAction) {

            switch (actionType) {
                case listAction.Delete:
                    {
                        if (this.machineType == MachineTypeInfo.Vehicle)
                            return this.$stringConstants('vehicleDeletePromtTitle');
                        else
                            return this.$stringConstants('implementDeletePromtTitle');
                    }
                case listAction.Archive:
                    {
                        if (this.machineType == MachineTypeInfo.Vehicle)
                            return this.$stringConstants('vehicleArchivePromptTitle');
                        else
                            return this.$stringConstants('implementArchivePromptTitle');
                    }
                case listAction.Restore:
                    {
                        if (this.machineType == MachineTypeInfo.Vehicle)
                            return this.$stringConstants('vehicleRestorePromptTitle');
                        else
                            return this.$stringConstants('implementRestorePromptTitle');
                    }
            }

        },
        fnGetBodyString(actionType: listAction) {

            switch (actionType) {
                case listAction.Delete:
                    {
                        if (this.machineType == MachineTypeInfo.Vehicle)
                            return this.$stringConstants('vehicleDeletePromtSubtitle');
                        else
                            return this.$stringConstants('implementDeletePromtSubtitle');
                    }
                case listAction.Archive:
                    {
                        if (this.machineType == MachineTypeInfo.Vehicle)
                            return this.$stringConstants('vehicleArchivePromptSubtitle');
                        else
                            return this.$stringConstants('implementArchivePromptSubtitle');
                    }
                case listAction.Restore:
                    {
                        if (this.machineType == MachineTypeInfo.Vehicle)
                            return this.$stringConstants('vehicleRestorePromptSubtitle');
                        else
                            return this.$stringConstants('implementRestorePromptSubtitle');
                    }

            }

        },

        setUrlParamsMachineLocal() {
            this.setLocalParamsMx(this.machineSearchText, "machineSearchQuery");
            this.setLocalParamsMx(this.machinesFilterType, "machineFilterType");
        },
        getUrlParamsMachineLocal() {
            this.machineSearchText = this.getLocalParamsMx("machineSearchQuery", "");
            this.machinesFilterType = parseInt(
                this.getLocalParamsMx("machineFilterType", 0)
            );
        },
        getMachineOwnership(ownershipVal: number) {
            return this.enumOwnership[ownershipVal]["title"];
        },
        openAddMachineForm() {
            this.clearMachineDetailsState();
            openOverlay("editMachineFormOverlay");
            this.scrollToToForm();
        },
        openEditMachineForm(vehicleId: string) {
            this.clearMachineDetailsState();
            this.resetSnackbarDetails();
            this.$store.dispatch("getmachine", vehicleId);
            openOverlay("editMachineFormOverlay");
            this.scrollToToForm();
        },
        applySearchMachineFilter() {
            this.clearMachineFilterOptions();
            this.resetPaging();
            this.machineListFiltered = [];
            this.setUrlParamsMachineLocal();
            if (this.machineSearchText != "" && this.machineSearchText != null) {
                this.filtersApplied = true;
            }
            this.setFilterOptionsAndGetMachines([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
        },
        promtActionOnMachine(machineId: string, listAction: listAction) {
            this.deleteDialog = true;
            this.actionType = listAction;
            this.actionOnMachineId = machineId;
        },
        proceedActionOnMachine(actionType: listAction) {
            this.deleteDialog = false;
            this.resetSnackbarDetails();
            this.$store.dispatch("actionOnMachineList", [actionType, this.actionOnMachineId]);
        },
        cancelActionOnMachine() {
            this.deleteDialog = false;
            this.actionOnMachineId = "";
        },
        getMachineListByStatus() {
            this.resetSnackbarDetails();
            this.applySearchMachineFilter();
        },
        selectAllMachines() {
            this.selectedItems = [];
            this.allItemsDeleted = false;
            if (this.isChecked) {
                this.machineListFiltered.map((pro) => {
                    this.selectedItems.push(pro.id);
                });
            }
        },
        getCapitalised(unformattedString) {
            return getCapitalised(unformattedString);
        },
        seedVehicles() {
            for (let i = 0; i < 400; i++) {
                let vehicleObj = {
                    title: "VEHICLE_" + i,
                    healthSafetyList: [],
                    isHealthSafetyListMandatory: false,
                    length: i + 100,
                    width: i,
                    make: "MK" + i,
                    model: "MD" + i,
                    ownership: i % 2 == 0 ? 0 : 1,
                    registration: "REG" + i + "999",
                    weight: i * 10,
                };
                this.$store.dispatch("addVehicle", vehicleObj);
            }
        },
        setFilterOptionsAndGetMachines(filter) {
            if (!this.machineSearchText) {
                this.clearMachineFilterOptions();
                this.filtersApplied = false;
            }
            this.$store.dispatch("fnSetMachineFilter", filter).then(res => {
                this.$store.dispatch("getMachineListFiltered");
            })
        },
        openImportForm() {
            this.resetImportForm();
            openOverlay("importMachinesFormOverlay");
        }
    },
};
