import operatorIcon from '@/components/shared/icon/icon.vue';
import FieldLabelComponent from '@/components/shared/labels/fields-label-stroke.vue';
import { TeamJobField } from "@/data/models/TeamJobField";
import { TeamJobMapFieldDto } from "@/data/models/TeamJobMapFieldDto";
import { ExternalAccountType } from '@/enum/externalAccountType';
import { JobAttachmentType } from '@/enum/jobAttachmentType';
import ConstantValues from "@/plugins/constantValues";
import StringConstants from "@/plugins/stringConstants";
import fieldsCacheService from "@/services/fields-cache-service";
import signalR from '@/services/signalr';
import { supportedImageExtensions } from '@/utils/constants';
import {
    apiAddresses,
    apiBusinessProfile,
    apiFields,
    apiImplements,
    apiJobAccept,
    apiJobCancel,
    apiJobComplete,
    apiJobDecline,
    apiJobForceFinish,
    apiJobLocations,
    apiJobPartComplete,
    apiJobs,
    apiOperations,
    apiProducts,
    apiQuickbooksInvoice,
    apiSageInvoice,
    apiTeamMembers,
    apiUsers,
    apiVehicles,
    apiXeroInvoice
} from "@/utils/endpoints";
import { closeNotify, devLogger, getInitials, getListLengthAbsolute, getResponseData, htmlToText, notify, responseHasListData } from "@/utils/helpers";
import {
    changeInfoWindowTheme,
    createPolyline,
    extendBoundsByLocations,
    setLabelsInvisible,
    setLabelsOnMap,
    setLabelsVisible
} from '@/utils/helpers/gmaps-helpers';
import { FeatureLayerHelper } from "@/utils/helpers/index";
import { getLoggedInUserRole, getOwnerId } from "@/utils/helpers/user-role-helpers";
import requests from "@/utils/requests";
import { defaultScrollAnimationBuffer } from "@/utils/uiconstants";
import buildUrl from "build-url";
import $ from 'jquery';
import loadGoogleMapsApi from 'load-google-maps-api';
import Vue from 'vue';

const FieldsCacheService = new fieldsCacheService();
const fieldLabelConstructor = Vue.extend(FieldLabelComponent);
const operatorIconConstructor = Vue.extend(operatorIcon);
const defaultBusinessLatitude = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
const defaultBusinessLongitude = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;

export const actions = {

    async getTeamJobsOwners({ state, rootState, commit, dispatch }) {
        rootState.hasMoreData = true;
        state.teamJobUsersListLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + getOwnerId() + '/' + state.teamJobOwnerTypeText[state.teamJobOwnerType].toLowerCase() + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobUsersListLoader = false;
        if (responseHasListData(result)) {
            commit('setTeamJobsOwners', getResponseData(result));
            if(result?.data?.size && !state.teamJobUserSearchText) {
                state.teamJobUsersListSize = result.data.size;
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
            return result;
        }
    },

    async setTeamJobCustomerFilters({ commit, state }, data) {
        if(data) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
            commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        }
        if (state.teamJobUserSearchText != null) {
            const search = state.teamJobUserSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async setTeamJobFieldCustomerSearchText({ commit, state }, data) {
        if(data) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
            commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        }
        if (state.teamJobFieldsCustomerSearchText != null) {
            const search = state.teamJobFieldsCustomerSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async getTeamJobOperations({ state, rootState, commit, dispatch }) {
        state.teamJobOperationsListLoader = true;
        rootState.hasMoreData = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: state.teamJobOperationOwnerId + '/' + apiOperations + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobOperationsListLoader = false;
        if (responseHasListData(result)) {
            commit('setTeamJobsOperations', getResponseData(result));
            if(!state.teamJobOperationsSearchText) {
                state.teamJobOperationsListSize = result.data.size;
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
        }
    },

    async getTeamJobOperationResource({ state, rootState, dispatch }, id) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: state.teamJobOperationOwnerId + '/' + apiOperations + '/' + id
        });
        const result = await requests.getData(url);
        return result;
    },

    async setTeamJobOperationsFilters({ commit, state }) {
        if (state.teamJobOperationsSearchText != null) {
            const search = state.teamJobOperationsSearchText.trim();
            if (search && search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
            }
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobOperationsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        return
    },

    async getTeamJobTeamMembers({state, rootState, commit, dispatch }) {
        state.teamJobOperatorToAddLoader = true;
        rootState.hasMoreData = true;
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthWithoutOwner(state.teamJobsTeamMembersListForOperators)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + getOwnerId() + '/' + apiTeamMembers + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobOperatorToAddLoader = false;
        if (result && result.data) {
            commit('setTeamMembersListForTeamsJob', [result.data.value, rootState.loginUserName]);
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.data.value);
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
        }
    },

    async getTeamJobVehiclesList({ state, commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + rootState.tempUrl
        });

        state.teamJobVehiclesListForOperatorsLoader = true;
        const result = await requests.getData(url);
        state.teamJobVehiclesListForOperatorsLoader = false;
         
        if (result?.data) {
            if (result.data.value.length > 0) {
                commit('setTeamJobVehiclesList', result.data.value);
            }
            if(!state.teamJobVehiclesSearchTextForOperators) {
                state.teamJobVehiclesListForOperatorsSize = result.data.size;
            }
         }
    },

    async getTeamJobImplementsList({ state, commit, rootState, dispatch }) {
        rootState.hasMoreData = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + rootState.tempUrl
        });

        state.teamJobImplementsListForOperatorsLoader = true;
        const result = await requests.getData(url);
        state.teamJobImplementsListForOperatorsLoader = false;

        if (result?.data) {
                if (result.data.value.length > 0) {
                    commit('setTeamJobImplementsList', result.data.value);
                }
                if(!state.teamJobImplementsSearchTextForOperators) {
                    state.teamJobImplementsListForOperatorsSize = result.data.size;
                }
        }
    },

    async setTeamJobMachinesPaging({commit}, data) {
        const { searchText, list } = data;
        if(searchText) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, ConstantValues.defaultPageOffsetSize]);
            commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        } else {
            commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(list)]);
            commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        }
    },

    async setTeamJobVehiclesSearchFilters({ commit, state, dispatch }) {
        if (state.teamJobVehiclesSearchTextForOperators != null) {
            const search = state.teamJobVehiclesSearchTextForOperators.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
            }
        }
        
        await dispatch('setTeamJobMachinesPaging', {
            searchText: state.teamJobVehiclesSearchTextForOperators,
            list: state.teamJobVehiclesListForOperators
        })

        return true;
    },

    async setTeamJobImplementsSearchFilters({ commit, state, dispatch }) {
        if (state.teamJobImplementsSearchTextForOperators != null) {
            const search = state.teamJobImplementsSearchTextForOperators.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
            }
        }
        await dispatch('setTeamJobMachinesPaging', {
            searchText: state.teamJobImplementsSearchTextForOperators,
            list: state.teamJobImplementsListForOperators
        })
        return true
    },

    async getTeamJobAddresses({ commit, rootState, state }) {
        let farmAddressesOwner = getOwnerId()
        if ((getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) && state.teamJobUser) {
            farmAddressesOwner = state.teamJobUser.id
        }
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + farmAddressesOwner + apiAddresses
        });
        const result = await requests.getData(url);
        if (responseHasListData(result)) {
            commit('setTeamJobsAddressList', getResponseData(result));
        }
    },

    async getTeamJobProducts({state, commit, rootState, dispatch }) {
        rootState.hasMoreData = true;
        state.teamJobProductsLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: getOwnerId() + apiProducts + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobProductsLoader = false;
        if (result && result.data) {
            if (result.data.value.length > 0) {
                commit('setProductsListForTeamsJob', result.data.value);
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
        }
    },

    async setTeamJobProductsSearchText({ commit, state }) {
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobProductsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        if (state.teamJobProductsSearchText != null) {
            const search = state.teamJobProductsSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async setTeamMembersForTeamJobSearchText({ commit, state }) {
        if (state.teamJobTeamMembersForOperatorsSeachText != null) {
            const search = state.teamJobTeamMembersForOperatorsSeachText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async addTeamJobAttachment({ commit, rootState }, data) {
        const { obj, type, purpose } = data;
        const url = buildUrl(rootState.baseUrl, {
            path: "attachments"
        });
        const result = await requests.postData(url, obj);

        if (result != null && result[0]) {
            commit('setTeamJobAttachment', {
                result: result[0],
                type: type,
                purpose: purpose
            });
            return result;
        }
    },

    async deleteTeamJobAttachment({ rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: "attachments" + "?url=" + data
        });
        const result = await requests.deleteData(url,);
        if (result != null) {
            commit('deleteAttachmentFromList', data);
        }
    },

    async getFileType({ rootState }, filepath) {
        const extension = filepath.toLowerCase().split('.').pop();
    
        if (extension === 'pdf') {
            return JobAttachmentType.Pdf;
        }
        
        if (supportedImageExtensions.includes(extension)) {
            return JobAttachmentType.Image;
        }
        
        throw new Error('Unsupported file type');
    },

    async reuploadAttachments({ commit, rootState }, urls) {
        const formData = new FormData();
        urls.forEach((url, index) => {
            formData.append(`urls`, url);
        })

        const url = buildUrl(rootState.baseUrl, {
            path: "attachments/re-upload"
        });
        const result = await requests.post(url, formData);
        return result
    },

    async drawAllFieldsOnTeamJobsMap({ state, dispatch }, callback) {
        await dispatch('drawPolygonOnTeamJobsFieldMap', { data: state.teamJobFieldsList, callback: callback ?? null });
        await dispatch('setMapBoundTeamJobsMap');
        await dispatch('createInfoWindowsForFields');
    },

    async generateTeamJobFieldsUrlPaths({ state, rootState }, recordSize) {
        const callStackSize = ConstantValues.defaultPageLimitForJobsFields;
        state.teamJobFieldsTotal = recordSize;

        const urlPaths = [];
        const totalCallValue = Math.ceil(recordSize / callStackSize);

        for (let i = 0; i < totalCallValue; i++) {
            const tempOffset = callStackSize * i;
            const path = buildUrl(rootState.baseUrl, {
                path: apiFields + '?&' + ConstantValues.limitQuery + '=' + callStackSize + '&offset=' + tempOffset
            });
            urlPaths.push(path);
        }

        return urlPaths;
    },

    async getTeamJobFields({ state, commit, rootState, dispatch }, callback) {
        let allFields = [];

        const hasCachedFields = await FieldsCacheService.hasCachedFields();
        if (hasCachedFields) {
            const result = await dispatch('getFieldsWithChangesSinceLastSync')
            if (result?.data?.value?.length > 0)
                await dispatch('handleUnsyncedFields', result.data.value);

            allFields = await FieldsCacheService.getAllCachedFields();
            state.teamJobFieldsTotal = allFields.length;

            commit('setFieldsForTeamJobs', allFields);
            await dispatch('drawAllFieldsOnTeamJobsMap', callback);
            closeNotify();
            return;
        }

        const url = buildUrl(rootState.baseUrl, {
            path: apiFields + '?&' + ConstantValues.limitQuery + '=10' + '&offset=0'
        });
        const result = await requests.getData(url, true, true);
        if (result?.data?.size) {
            const recordSize = result.data.size;
            const urlPaths = await dispatch('generateTeamJobFieldsUrlPaths', recordSize);

            await Promise.all(urlPaths.map(async (url) => {
                const result = await requests.getData(url, true, true);
                if (result?.data?.value) {
                    allFields.push(...result.data.value);
                    commit('setFieldsForTeamJobs', result.data.value);
                }
            }));

            if (recordSize > 0) {
                dispatch('drawAllFieldsOnTeamJobsMap');
                closeNotify();
            } else {
                commit('setNoDataStatus', recordSize);
            }

            await FieldsCacheService.cacheAllFields(allFields);
        }
    },

    async destroyMapInstance({ state }){
        google.maps.event.clearInstanceListeners(window);
        google.maps.event.clearInstanceListeners(document);
    },

    async initializeTeamJobsMap({ state }) {
        state.teamJobsFieldMap = null;
        $("#teamJobsFieldMap").html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude }),
                mapTypeControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.teamJobsFieldMap = new google.maps.Map(document.getElementById('teamJobsFieldMap'), mapOptions);
        });
    },

    async handleFieldFeatureSelection({ state, dispatch }, id) {
        if (state.teamJobFieldsList?.length > 0 || state.teamJobSelectedFields?.length > 0) {
            try {
                const mapField = state.teamJobFieldsList.filter(field => field.id == id)?.[0]
                const listField = state.teamJobSelectedFieldsForList.filter(field => field.id == id)?.[0]

                const field = mapField || listField

                if (state.teamJobSelectedFields.some(x => x.id == field.id)) {
                    state.teamJobSelectedFields = state.teamJobSelectedFields.filter(x => x.id != field.id)
                    state.teamJobSelectedFieldsForList = state.teamJobSelectedFieldsForList.filter(x => x.id != field.id)
                    FeatureLayerHelper.revertFeatureStylesByIds(state.teamJobsFieldMap.data, [id])
                } else {
                    state.teamJobSelectedFields.push(field)
                    state.teamJobSelectedFieldsForList.push(field)
                    FeatureLayerHelper.highlightFeaturesByIds(state.teamJobsFieldMap.data, [id])
                }
    
            } catch (e) {
                console.error(e)
            }
        }
    },

    async highlightFieldsOnTeamJobsMap({ state, dispatch }, ids) {
        FeatureLayerHelper.highlightFeaturesByIds(state.teamJobsFieldMap.data, ids)
    },

    async setMapBoundByHighlightedFields({ state }, ids) {
        const bounds = FeatureLayerHelper.getBoundsByFeatureIds(state.teamJobsFieldMap.data, ids)
        state.teamJobsFieldMap.fitBounds(bounds)
    },

    async highlightFieldsAndSetMapBound({ state, dispatch }, ids) {
        await dispatch('highlightFieldsOnTeamJobsMap', ids)
        await dispatch('setMapBoundByHighlightedFields', ids)
    },

    async attachClickListenersToTeamJobFields({ state, dispatch }) {
        FeatureLayerHelper.attachClickListnerToDataLayerFeatures(state.teamJobsFieldMap.data, (id: string, mapEventListerner: google.maps.MapsEventListener) => { 
            dispatch('handleFieldFeatureSelection', id)
        })
    },

    async drawPolygonOnTeamJobsFieldMap({ state, dispatch, rootState }, dataAndCallback) {
        const { data, callback } = dataAndCallback

        if (state.fieldsListMap?.data) {
            FeatureLayerHelper.removeAllClickListenersFromDataLayerFeatures()
            FeatureLayerHelper.removeAllFeaturesFromDataLayer(state.teamJobsFieldMap.data)
        }
    
        if (!data?.length) return
    
        const BATCH_SIZE = 200
        const PAUSE_DURATION = 100
        state.teamJobFieldsLoading = true

        const pause = (ms) => new Promise(resolve => setTimeout(resolve, ms))
    
        for (let i = 0; i < data.length; i += BATCH_SIZE) {
            const batch = data.slice(i, i + BATCH_SIZE)
    
            batch.forEach(async (field: TeamJobField) => {
                const fieldInfo = await dispatch('createFieldInfos', field)
                FeatureLayerHelper.addGeoJsonFeatureOnMap(field.polygon, state.teamJobsFieldMap.data, fieldInfo)
            })
    
            if (i + BATCH_SIZE < data.length) {
                await pause(PAUSE_DURATION)
            }
        }

        if (typeof callback == 'function') callback()
        state.teamJobFieldsLoading = false
        await dispatch('attachClickListenersToTeamJobFields')
    },

    async storeSelectedFieldOnTeamJobsMap({ state, dispatch }, data) {
        if (state.teamJobSelectedFieldsForList.length == 0)
            state.teamJobSelectedFieldsForList.push(data)

        if (!state.teamJobSelectedFieldsForList?.some(f => f.id == data.id))
            state.teamJobSelectedFieldsForList.push(data)

        if (state.teamJobSelectedFields.length == 0)
            state.teamJobSelectedFields.push(data)

        if (!state.teamJobSelectedFields?.some(f => f.id == data.id))
            state.teamJobSelectedFields.push(data)
    },

    async removeHighlightAddedFieldsOnTeamJobsMap({ state }, data) {
        const polyPath = state.teamJobSelectedPolygon.filter(x => x.fieldId == data.id)[0].polyPath;
        state.teamJobSelectedPolygon = state.teamJobSelectedPolygon.filter(x => x.fieldId != data.id);
        polyPath.setMap(null);
    },

    async clearExistingFieldsTeamJobsMap({ state }) {
        FeatureLayerHelper.removeAllFeaturesFromDataLayer(state.teamJobsFieldMap.data)
        FeatureLayerHelper.removeAllClickListenersFromDataLayerFeatures()
    },

    async setMapBoundTeamJobsMap({ state }) {
        FeatureLayerHelper.boundMapByAllFeatures(state.teamJobsFieldMap.data, state.teamJobsFieldMap)
    },

    async navigateToSearchField({ state }, fieldId) {
        const bounds = FeatureLayerHelper.getBoundsByFeatureIds(state.teamJobsFieldMap.data, [fieldId])

        if (state.teamJobsFieldMap) {
            state.teamJobsFieldMap.fitBounds(bounds)
            state.teamJobsFieldMap.setZoom(ConstantValues.defaultMapZoom)
        }
    },

    async filterCustomerFieldsTeamJob({ state, dispatch }) {
        dispatch('clearExistingFieldsTeamJobsMap', state.teamJobFieldsList);
        if (state.teamJobFieldCustomerId) {
            state.teamJobFieldsList = state.teamJobFieldsTempList.filter(x => x.ownerId === state.teamJobFieldCustomerId);
        } else {
            state.teamJobFieldsList = state.teamJobFieldsTempList;
        }
        if (state.teamJobFieldsList.length != 0) {
            await dispatch('drawPolygonOnTeamJobsFieldMap', { data: state.teamJobFieldsList, callback: null });
            await dispatch('setMapBoundTeamJobsMap');
            await dispatch('createInfoWindowsForFields');
        }
    },

    async createInfoWindowsForFields({ state, dispatch }) {
        const infoWindow = new google.maps.InfoWindow()
        FeatureLayerHelper.attachInfoWindowToDataLayerFeatures(
            state.teamJobsFieldMap.data, 
            state.teamJobsFieldMap, 
            infoWindow
        )
    },

    async getTeamJobDetails({ rootState, commit }, resourceId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + resourceId
        });
        const result = await requests.getData(url);

        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setTeamJobDetails', result.data);
                return result.data;
            }
        }
        return false;
    },

    async saveTeamJob({ state, rootState, dispatch }, data) {
        data = await dispatch('parseHtmlJobData', data);

        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs
        });
        state.teamJobsAddLoader = true;
        const result = await requests.postData(url, data);
        state.teamJobsAddLoader = false;
        if (result[0] != null) {
            return result[0];
        }
    },

    async teamJobChangeInfoWindowTheme() {
        changeInfoWindowTheme('#job-map-drop-pin', 'dark')
    },

    async getGoogleMapToSelectDefaultLocation({ rootState, state, dispatch }) {
        state.jobDropPinMap = null
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const mapZoom = ConstantValues.defaultMapZoom
            const { google } = window
            const mapOptions = {
                zoom: 16,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                rotateControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.jobDropPinMap = new google.maps.Map(document.getElementById('job-map-drop-pin'), mapOptions)

            state.teamJobMapLocationInfoWindow = new google.maps.InfoWindow()

            state.teamJobDropPinLatLng = JSON.stringify({
                lat: defaultBusinessLatitude,
                lng: defaultBusinessLongitude
            }, null, 2)

            state.teamJobAddressesDropPinLatitude = defaultBusinessLatitude
            state.teamJobAddressesDropPinLongitude = defaultBusinessLongitude
            
            if (state.teamJobMapLocationInfoWindow) {
                state.teamJobMapLocationInfoWindow.setPosition(new google.maps.LatLng(defaultBusinessLatitude, defaultBusinessLongitude))
                state.teamJobMapLocationInfoWindow.setContent(defaultBusinessLatitude.toFixed(4).toString() + ', ' + defaultBusinessLongitude.toFixed(4).toString())
                state.teamJobMapLocationInfoWindow.open(state.jobDropPinMap)
            }

            setTimeout(() => {
                dispatch('teamJobChangeInfoWindowTheme')
            }, 200)

            state.jobDropPinMap.addListener("click", (mapsMouseEvent) => {
                state.teamJobAddressesDropPinLatitude = mapsMouseEvent.latLng.toJSON().lat
                state.teamJobAddressesDropPinLongitude = mapsMouseEvent.latLng.toJSON().lng

                state.teamJobMapLocationInfoWindow.close()

                state.teamJobMapLocationInfoWindow.setPosition(mapsMouseEvent.latLng)
                const latLngString = state.teamJobAddressesDropPinLatitude.toFixed(4).toString().trim() + ', ' + state.teamJobAddressesDropPinLongitude.toFixed(4).toString().trim()
                state.teamJobMapLocationInfoWindow.setContent(latLngString)

                state.teamJobMapLocationInfoWindow.open(state.jobDropPinMap)
                dispatch('teamJobChangeInfoWindowTheme')
            })
        })
    },

    async updateTeamJob({ rootState, state, commit, dispatch }, data) {
        state.teamJobsLoader = true;
        data = await dispatch('parseHtmlJobData', data);

        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data.id
        });
        const result = await requests.putData(url, data);
        try {
            if (result[0] != null) {
                commit('updateJobInList', result[0]);
                return result[0];
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            state.teamJobsLoader = false;
        }
    },

    async deleteTeamJob({ rootState, state, commit }, id) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + id
        });
        state.teamJobsLoader = true;
        const result = await requests.deleteData(url);
        state.teamJobsLoader = false;
        if (result) {
            const jobIndex = rootState.teamJobsListModule.teamJobsList.findIndex(teamJob => teamJob.id === id);
            const isLastIndex = jobIndex === rootState.teamJobsListModule.teamJobsList.length - 1;
            const scrollToIndex = isLastIndex ? jobIndex - 1 : jobIndex;
            if (jobIndex > -1) {
                commit('removeJobFromList', jobIndex);
                commit('updatePagingAfterListItemRemoval');
                if (jobIndex !== 0) {
                    commit('updateScrollToItem', rootState.teamJobsListModule.teamJobsList[scrollToIndex].id);
                }
            }
            return true
        } else {
            return false
        }
    },

    async acceptTeamJobByContractor({ rootState, state, commit }, resourceId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + resourceId + apiJobAccept
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, '');
        if (result != null) {
            commit('updateJobInList', result);
            return result;
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async declineTeamJobByContractor({ rootState, state, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + data.resourceId + apiJobDecline
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data.reason);
        state.teamJobsLoader = false;
        if (result[0] != null) {
            notify(StringConstants.teamJobDeclinedSuccessfully, 'success');
            commit('updateJobInList', result[0]);
        }
    },

    async cancelTeamJobByContractor({ rootState, state, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + data.resourceId + apiJobCancel
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data.reason);
        state.teamJobsLoader = false;
        if (result[0] != null) {
            notify(StringConstants.teamJobCancelledSuccessfully, 'success');
            const jobIndex = rootState.teamJobsListModule.teamJobsList.findIndex(job => job.id === result[0].id);
            const isLastIndex = jobIndex === rootState.teamJobsListModule.teamJobsList.length - 1;
            const scrollToIndex = isLastIndex ? jobIndex - 1 : jobIndex;
            if (jobIndex > -1) {
                commit('removeJobFromList', jobIndex);
                if (jobIndex !== 0) {
                    commit('updateScrollToItem', rootState.teamJobsListModule.teamJobsList[scrollToIndex].id);
                }
            }
            return true;
        } else {
            return false;
        }
    },

    async forceFinishTeamJob({ rootState, state, commit }, resourceId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + resourceId + apiJobForceFinish
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, '');
        if (result != null) {
            commit('updateJobInList', result[0]);
            return result;
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async initializeTeamJobsLocationMap({ state }) {
        state.teamJobsLocationMap = null;
        $("#teamJobsLocationMap").html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapStyle = [{
                  elementType: 'labels',
                  stylers: [{visibility: 'off'}]
                }];
            const mapOptions = {
                zoom: mapZoom,
                center: new google.maps.LatLng({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude }),
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                 }
             };
            state.teamJobsLocationMap = new google.maps.Map(document.getElementById('teamJobsLocationMap'), mapOptions);
        });
    },

    async drawFieldsOnLocationMap({ state }, data) {
        if (state.teamJobsLocationMap?.data)
            FeatureLayerHelper.removeAllFeaturesFromDataLayer(state.teamJobsLocationMap.data)
        
        if (data?.length > 0) {
            data.forEach((field: any) => {
                FeatureLayerHelper.addGeoJsonFeatureOnMap(field.polygon, state.teamJobsLocationMap.data)
            })
        }
    },

    async getOperatorLocationHistory({ rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data[0] + apiJobLocations + '?operatorId=' + data[1]
        });
        const result = await requests.getData(url);
        if (result.data) {
            commit('setTeamJobsOperatorLocationHistory', result.data);
            return result.data;
        }
    },

    async drawFieldsGeoJsonOnLocationMap({ state }, data) {
        if (state.teamJobsLocationMap?.data)
            FeatureLayerHelper.removeAllFeaturesFromDataLayer(state.teamJobsLocationMap.data)
        
        if (data?.length > 0) {
            data.forEach((field: any) => {
                FeatureLayerHelper.addGeoJsonFeatureOnMap(field.geometry, state.teamJobsLocationMap.data)
            })
        }
    },

    async clearOperatorPolylines({ state }) {
        FeatureLayerHelper.removeAllLineStringFeaturesFromFeatureLayer(state.teamJobsLocationMap.data)
        state.selectedOperatorLocationsGeoJSON = []
        if (state.operatorLocationShapes) {
            state.operatorLocationShapes.map((x) => {
                x.setMap(null)
            })
        }
    },

    async setLocationBounds({ state }, locations) {
        if (locations) {
            extendBoundsByLocations(locations, state.teamJobDetails.fields, state.teamJobsLocationMap);
        }
    },

    async drawSelectedOperatorPolylines({ state }) {
        if (state.selectedOperatorLocationsGeoJSON?.length > 0) {
            state.selectedOperatorLocationsGeoJSON.forEach(loc => {
                FeatureLayerHelper.drawLineStringFeatureOnFeatureLayer(state.teamJobsLocationMap.data, loc)
            })

            FeatureLayerHelper.setMapBoundsByAllLineStringFeatures(state.teamJobsLocationMap.data, state.teamJobsLocationMap)
        }
    },

    async partCompleteTeamJob({ state, rootState, commit, dispatch }, data) {
        data = await dispatch('parseHtmlJobData', data);

        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data.id + apiJobPartComplete
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            commit('updateJobInList', result[0]);
            return result[0];
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async completeTeamJob({ state, rootState, commit, dispatch }, data) {
        data = await dispatch('parseHtmlJobData', data);

        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data.id + apiJobComplete
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            commit('updateJobInList', result[0]);
            return result[0];
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async subscribeJobOperators({ state }, data) {
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                await signalR.fnSubscribeGroup(data[i].id);
            }
        }
    },

    async clearOperatorMarkerOnMap({ state }) {
        if (state.operatorsMarkers.length != 0) {
            state.operatorsMarkers.map(x => {
                x.marker.setMap(null);
            })
        }
    },

    async getPolyLineMarkersOnMap({ state, dispatch }, data) {
        const location = data;
        if (location.userId == state.teamJobLocationSelectedOperatorId) {
            dispatch('createRealTimePolyLineOnMap', location);
        }
        const userId = location.userId;
        const infoLat = location.latitude;
        const infoLng = location.longitude;
        const initials = getInitials(location.name);
        
        const updateMarker = state.operatorsMarkers.filter(x => x.userId === location.userId);
        if (updateMarker.length > 0) {
            const position = new google.maps.LatLng(infoLat, infoLng);
            updateMarker[0].marker.setPosition(position);
            return
        }
        function createMarkerIcon(fillColor) {
            const iconComponent = new operatorIconConstructor({ propsData: { fillColor, initials } })
            iconComponent.$mount();
            const iconDom = iconComponent.$el;
            const iconString = new XMLSerializer().serializeToString(iconComponent.$el);
            const operatorUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString);

            const icon = {
                url: operatorUrl,
                fillColor: fillColor,
                strokeColor: '#ffffff',
                fillOpacity: 1,
                strokeWeight: 1,
                scale: new google.maps.Size(68, 36),
                anchor: new google.maps.Point(34, 6),

            };
            return icon;
        }
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(infoLat, infoLng),
            map: state.teamJobsLocationMap,
            visible: true,
            zIndex: 999,
            icon: createMarkerIcon("#ffffff")
        });

        const oprMarker = {
            userId: location.userId,
            marker: marker
        }
        state.operatorsMarkers.push(oprMarker);
    },

    async createRealTimePolyLineOnMap({ state }, data) {
        const latLng = {
            latitude: data.latitude,
            longitude: data.longitude,
            timestamp: data.timestamp
        }
        if (state.selectedOperatorLocations.length) {
            const lastLocationTimestamp = state.selectedOperatorLocations[state.selectedOperatorLocations.length - 1].timestamp;
            const timeGapLessThanThreshold = (new Date(data.timestamp).getTime() - new Date(lastLocationTimestamp).getTime()) < 30000;
            if (timeGapLessThanThreshold) {
                const polyline = state.operatorLocationShapes[state.operatorLocationShapes.length - 1];
                const path = polyline.getPath();
                path.push(new google.maps.LatLng(data.latitude, data.longitude));
            }
        }
        if (data.timestamp && state.operatorRealTimeLocation.length) {
            const lastRealTimeTimestamp = new Date(state.operatorRealTimeLocation[state.operatorRealTimeLocation.length - 1].timestamp).getTime();
            const currentTimestamp = new Date(data.timestamp).getTime();
            const selectedOperator = state.teamJobOperators
                                        .find(operator => operator.operatorDetails.id === state.teamJobLocationSelectedOperatorId);
            const travelHistory = selectedOperator.operatorDetails.travelHistory;
            const workHistory = selectedOperator.operatorDetails.workHistory;
            const pauseStatusHistory = [...workHistory, ...travelHistory]
                                        .filter(history => history.status === 1)
                                        .sort((a,b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
            const hasOperatorReachedLocation = travelHistory[travelHistory.length - 1]?.status === 2;
            const hasOperatorStartedJob = workHistory;
            const lastPauseTimestamp = new Date(pauseStatusHistory[0]?.createdOn).getTime();

            if ((lastPauseTimestamp <= currentTimestamp
                && lastPauseTimestamp >= lastRealTimeTimestamp)
                || (hasOperatorReachedLocation && !hasOperatorStartedJob)) {
                state.operatorLocationShapes = [];
                state.operatorRealTimeLocation = [];
            }
        }
        const coordsToDraw = [];
        state.operatorRealTimeLocation.push(latLng);

        if (state.operatorRealTimeLocation.length > 2) {
            const polyline = state.operatorLocationShapes[state.operatorLocationShapes.length - 1];
            const polylinePath = polyline.getPath();
            polylinePath.push(new google.maps.LatLng(latLng.latitude, latLng.longitude));
            return;
        } else if (state.operatorRealTimeLocation.length > 1) {
            state.operatorRealTimeLocation.forEach((location: {latitude: number, longitude: number}) => {
                coordsToDraw.push({
                    latitude: location.latitude,
                    longitude: location.longitude
                });
            });
            const polyline = createPolyline(coordsToDraw);
            state.operatorLocationShapes.push(polyline);
            polyline.setMap(state.teamJobsLocationMap);
        }
    },

    async getInvoiceUrl({rootState, state, commit, dispatch}, data) {
        const {id, service} = data;
        let targetServiceUrl;
        state.teamJobsLoader = true;
        if (service === ExternalAccountType.QuickBooks) {
            targetServiceUrl = apiQuickbooksInvoice;
        } else if (service === ExternalAccountType.Sage) {
            targetServiceUrl = apiSageInvoice;
        } else if (service === ExternalAccountType.Xero) {
            targetServiceUrl = apiXeroInvoice;
        }

        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + targetServiceUrl + '/' + id
        });
        const result = await requests.getDataRes(url);
        state.teamJobsLoader = false;
        return result.data;
    },

    setWorkHoursAsBillableQuantity({ state, dispatch }, { workTimeInSeconds, shouldHighlightBillingInformation = true }) {
        const { billingUnit, billingQuantities } = state.teamJobOperation;

        const conversionFactors = {
            hour: { factor: 60 * 60, precision: 2, round: false },
            minute: { factor: 60, precision: 0, round: true },
        };
        
        const { factor: conversionFactor, precision, round } = conversionFactors[billingUnit] || {};
        
        const convertedDuration = conversionFactor
            ? Number(
                ((round ? Math.floor(workTimeInSeconds / conversionFactor) : workTimeInSeconds / conversionFactor))
                .toFixed(precision)
            )
            : 0;

        if (billingQuantities?.length > 0) {
            billingQuantities[0].billingQuantity = convertedDuration;
            shouldHighlightBillingInformation && dispatch('highlightBillingInformation');
        }
    },

    highlightBillingInformation() {
        const billingInformationContainer = document.querySelector('#job-billing-information');
        const billingInformationField = document.querySelector('#job-billing-information-fields');
    
        if (!billingInformationContainer || !billingInformationField) return;
    
        billingInformationContainer.scrollIntoView({ behavior: 'smooth' });
    
        setTimeout(() => {
            billingInformationField.classList.add('job-billing-information-fields--highlighted');
            
            setTimeout(() => {
                billingInformationField.classList.remove('job-billing-information-fields--highlighted');
            }, defaultScrollAnimationBuffer + 400);
        }, defaultScrollAnimationBuffer);
    },

    parseHtmlJobData({ state }, data) {
        data.instructions = htmlToText(data.instructions);
        data.notes = htmlToText(data.notes);

        return data;
    },

    async duplicateTeamJobData({ state, rootState }, jobId) {
        const url = buildUrl(rootState.baseUrl, { path: apiJobs + "/" + jobId });
        const result = await requests.getData(url);

        if (result?.data != null) {
            state.teamJobsDataToDuplicate = result.data;
            return result.data;
        }

        return false;
    },

    async getTagsForTeamJobs({ state, rootState }) {
        const url = buildUrl(rootState.baseUrl, { 
            path: apiBusinessProfile 
        });

        state.teamJobTagsLoader = true;
        const result = await requests.getData(url);
        state.teamJobTagsLoader = false;

        if (result?.data != null) {
            state.teamJobTagsAvailable = result.data.config?.jobTags || [];
            return result.data;
        }

        return false;
    },
}
