import { apiTimeSheets, apiTeamMembers, apiUsers } from '@/utils/endpoints';
import request from '@/utils/requests';
import { notify, closeOverlay } from '@/utils/helpers';
import buildUrl from 'build-url';
import { Timesheet } from '@/data/models/Timesheet';
import { ResponseList } from '@/data/models/ResponseList';
import { TimesheetStatistics } from '@/data/models/TimesheetStatistics';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';

export const actions = {
    async getTimesheetsList({ state, commit, rootState, dispatch }) {
        state.timesheetsLoader = true;
        rootState.listLoading = true;
        if (rootState.filterOptions.length != 0) {
            dispatch("jsonToQueryString", rootState.filterOptions);
        }
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets +
                (rootState.tempUrl ? (rootState.tempUrl + ConstantValues.preferenceQuery + rootState.enumPreference['NotArchived']) : ('?' + ConstantValues.preferenceQuery + rootState.enumPreference['NotArchived'])) +
                (state.timesheetsSelectedMemberId != null ? ('&userId=' + state.timesheetsSelectedMemberId) : '')
        });
        const result: ResponseList<Timesheet> = await request.getList<Timesheet>(url, {
            rootState, 
            cancellable: true
        });
        if (result != null && result.value.length > 0) {
            commit('setTimesheetsList', result.value);
            dispatch("appendLoadedItemsToSelections", result.value);
        }
        else {
            commit('setNoDataStatus', result.value);
        }

        state.timesheetsLoader = false;
        rootState.listLoading = false;
    },

    async getTimesheetsStatistics({ commit, rootState, state }) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets + '/statistic' +
                rootState.tempUrl +
                (state.timesheetsSelectedMemberId != null ? ('&userId=' + state.timesheetsSelectedMemberId) : '')
        });
        const result: TimesheetStatistics = await request.get<TimesheetStatistics>(url, true);
        if (result != null) {
            commit('setTimesheetsStatistics', result);
        }
    },

    async setTimesheetsFilter({ commit, state }, data) {
        if (state.timesheetsSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.timesheetsSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        if (state.timesheetsFilterStartDate != null) {
            commit('setFilterOptions', ['Start', state.timesheetsFilterStartDate]);
        }
        if (state.timesheetsFilterEndDate != null) {
            commit('setFilterOptions', ['End', state.timesheetsFilterEndDate]);
        }
        if (state.timesheetListFilterType != null) {
            commit('setFilterOptions', ['type', state.timesheetListFilterType]);
        }
    },

    async addNewTimesheet({ state, rootState, dispatch }, data: Timesheet) {
        state.addTimesheetLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets
        });
        const result: Timesheet = await request.post<Timesheet>(url, data);
        if (result != null) {
            notify(StringConstants.timeSheetCreated, 'success');
            state.timesheetsList = [];
            closeOverlay("addTimeSheetFormOverlay");
            state.addTimesheetLoader = false;
            return result;
        }
        state.addTimesheetLoader = false;
    },

    async setSearchTextForTimesheetMembers({ commit, state }) {
        if (state.timesheetMembersSearchText != null) {
            const search = state.timesheetMembersSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async getTimesheetMembersList({ commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + '/' + apiTeamMembers + rootState.tempUrl
        });
        const result = await request.getData(url, true);
        if (result?.data?.value.length) {
            commit('setTimesheetsMembersList', result.data.value);
        }
        else {
            rootState.searchNotify = StringConstants.noSearchResultFoundText;
        }
    },

    async getTimesheetDetails({ commit, rootState }, id) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets + "/" + id
        });
        const result: Timesheet = await request.get<Timesheet>(url, true);
        if (result != null) {
            commit('setTimesheetDetails', result);
        }
    },

    async getTimesheetMembersWorkTime({ commit, rootState }, id) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + id
        });
        const result: any = await request.get<any>(url, true);
        if (result != null) {
            commit('setUserWorkTimeForTimeSheet', result);
        }
    },

    async updateTimesheet({ state, rootState, dispatch }, [timesheetDto, timsheetId]) {
        state.editTimesheetLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets + "/" + timsheetId
        });
        const result: Timesheet = await request.put<Timesheet>(url, timesheetDto);
        if (result != null) {
            notify(StringConstants.timeSheetUpdated, 'success');
            dispatch('getTimesheetsStatistics');
            closeOverlay("editTimeSheetFormOverlay");
            state.timesheetsList = [];
            state.editTimesheetLoader = false;
            return result;
        }
        state.editTimesheetLoader = false;
    },

    async deleteTimesheet({ rootState, dispatch }, id) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets + "/" + id
        });
        const result = await request.deleteObject(url);
        if (result) {
            notify(StringConstants.timeSheetDeleted, 'success');
            dispatch('getTimesheetsStatistics');
            return result;
        } else {
            notify(StringConstants.timeSheetNotDeleted, 'fail');
        }
    },

    async setFilterForTimesheetDataExport({ state, commit }) {
        if (state.timesheetsSearchText && state.timesheetsSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.timesheetsSearchText]);
        }
        if (state.timesheetsFilterStartDate != null) {
            commit('setFilterOptions', ['Start', state.timesheetsFilterStartDate]);
        }
        if (state.timesheetsFilterEndDate != null) {
            commit('setFilterOptions', ['End', state.timesheetsFilterEndDate]);
        }
        if (state.timesheetListFilterType != null) {
            commit('setFilterOptions', ['type', state.timesheetListFilterType]);
        }
    },

    async getTimesheetDataForExport({ commit, state, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const pagingUrl = (rootState.tempUrl ? rootState.tempUrl : '') + (rootState.tempUrl ? '&' : '?') + ConstantValues.limitQuery + '=' + ConstantValues.defaultPageLimitForTimesheetsExport + '&offset=' + state.timesheetDataListOffset
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiTimeSheets + pagingUrl +
                (state.timesheetsSelectedMemberId != null ? ('&userId=' + state.timesheetsSelectedMemberId) : '')
        });
        const result = await request.getList<Timesheet>(url, {
            rootState, 
            cancellable: true
        });
        if (result != null) {
            state.timesheetDataListOffset = result.value.length;
            state.timesheetDataListTotalCount = result.size;
            if (result.value.length > 0) {
                commit('setTimesheetDataListForExport', result.value);
            }
        }
    }
}