export const appVersion = process.env.VUE_APP_VERSION;
export const appEnvironment = process.env.NODE_ENV;
export const clientType = "ehbihva7A7hTrPQbUnw";
export const gAnalyticsKey = "G-SFZG56D9YP";
export const gMapsApiKey = process.env.NODE_ENV == 'production' ? 'AIzaSyBzGpM14YtMKtLYHsEw6ZMihGoEQkV5dYE' : 'AIzaSyBg07txODm-Ybgm-a3ySPlmJfTDghm_9n0';
export const facebookPixelId = '3137813803119422';
export const webLocalHostUrl = 'localhost';
export const webStagingUrl = 'https://web.staging.ag-drive.com/';
export const webBetaUrl = 'https://web.beta.ag-drive.com/';
export const qbInvoiceTarget = 'https://app.qbo.intuit.com/app/invoices';
export const xeroInvoiceTarget = 'https://go.xero.com/AccountsReceivable/Search.aspx';
export const sageInvoiceTarget = 'https://accounts-extra.sageone.com/invoicing/sales_invoices/';
export const youtubeTutorialsLink = 'https://www.youtube.com/watch?v=iR_mYfDoV8g&list=PLQ3TpRx5ipsdmc39hihBpMaCyyu5Q0NzL';
export const defaultTimezoneRegion = 'Europe/London';
export const defaultLatitude = '53.9500';
export const defaultLongitude = '-1.0800';
export const defaultBusinessLatitude = 53.9500;
export const defaultBusinessLongitude = -1.0800;
export const defaultMapZoom = 12;
export const defaultMarkerZoom = 16;
export const defaultPolygonStrokeColor = "#D5B427";
export const defaultIconFillColor = "#D5B427";
export const defaultIconStrokeColor = "#ffffff";
export const defaultDateFormat = 'DD/MM/YYYY, HH:mm';
export const defaultPageLimitSizeProduction = 200;
export const defaultPageLimitSizeDebug = 10;
export const defaultPageOffsetSize = 0;
export const defaultPageLimitSize = process.env.NODE_ENV == 'production' ? defaultPageLimitSizeProduction : defaultPageLimitSizeDebug;
export const defaultPageLimitSizeImportCustomers = 100;
export const defaultPageLimitSizeCustomer = defaultPageLimitSizeProduction;
export const defaultPageLimitForJobsFields = 700;
export const defaultPageLimitForJobsExport = defaultPageLimitSizeProduction;
export const defaultPageLimitForTimesheetsExport = defaultPageLimitSizeProduction;
export const defaultPageSizeCalendarJobs = 500;
export const defaultPageListCount = 0;
export const defaultPageTotalRowCount = 0;
export const defaultPageRowCount = 0;
export const defaultVehicleLengthUnit = 'metre';
export const defaultVehicleWeightUnit = 'kg';
export const defaultImplementLengthUnit = 'metre';
export const defaultImplementWeightUnit = 'kg';
export const defaultMinMapZoom = 4;
export const defaultMaxMapZoom = 18;
export const defaultAdminCostInPounds = 10;
export const defaultMemberCostInPounds = 5;
export const defaultAmountFixedDigitCount = 2;
export const defaultImageDataMountTimeout = 3000;
export const defaultInvoiceLoaderTimeout = 500;
export const defaultSnapshotMinTimeout = 2000;
export const defaultIntegrationBufferTimeout = 3000;
export const defaultCardAddSuccessMessageTimeout = 5000;
export const defaultSearchTimeout = 800;
export const defaultDropdownSearchTimeout = 500;
export const defaultBusinessProfilePrefixLength = 3;
export const defaultBusinessProfilePrefixMinLength = 2;
export const defaultMapPadding = 20;
export const defaultLabelVisibilityZoomLevel = 14;
export const defaultZoomChangedEvent = 'zoom_changed';
export const defaultResendCountdown = 60;
export const defaultObjectIdentifier = 'id';
export const defaultListExportFormat = 'xls';
export const defaultTabSelectionTimeout = 500;
export const defaultScrollToPositionTimeout = 2000;
export const defaultScrollToPositionClickTimeout = 2500;
export const defaultInfoWindowTimeout = 5000;
export const defaultUserLocationUpdateTimeout = 30; //in seconds
export const defaultInvoicingTermsInDays = 7;
export const defaulSignupLoadingTimeout = 5000;
export const fieldsApiVersion = '3';
export const defaultMapTimeoutBuffer = 1000;
export const defaultDrawingManagerPolygonOptions = {
    clickable: true,
    editable: true,
    strokeColor: defaultPolygonStrokeColor,
    strokeOpacity: 0.7,
    strokeWeight: 5,
    fillColor: defaultPolygonStrokeColor,
    fillOpacity: 0.1
}

export const setVisible = true;
export const setNotVisible = false;
export const setEditable = true;
export const setNotEditable = false;
export const setDetailed = true;
export const setNotDetailed = false;

export const quickbookAccessStruct = {
    noAction: "NoAction",
    noQuickbookAccess: "NoQuickbookAccess",
    quickbookAccess: "QuickbookAccess"
};
export const xeroAccessStruct = {
    noAction: "NoAction",
    noXeroAccess: "NoXeroAccess",
    xeroAccess: "XeroAccess"
};
export const sageAccessStruct = {
    noAction: "NoAction",
    noSageAccess: "NoSageAccess",
    sageAccess: "SageAccess"
};
export const defaultCurrencySymbols = {
    'GBP': '£'
};

const defaultChartDataBarColor = '#b87333';

export const initialMonthlySubscriptionsChartData = [
    ['Month', 'Amount', { role: 'style' }],
    ['Jan', 0, defaultChartDataBarColor],
    ['Feb', 0, defaultChartDataBarColor],
    ['Mar', 0, defaultChartDataBarColor],
    ['Apr', 0, defaultChartDataBarColor],
    ['May', 0, defaultChartDataBarColor],
    ['Jun', 0, defaultChartDataBarColor],
    ['Jul', 0, defaultChartDataBarColor],
    ['Aug', 0, defaultChartDataBarColor],
    ['Sep', 0, defaultChartDataBarColor],
    ['Oct', 0, defaultChartDataBarColor],
    ['Nov', 0, defaultChartDataBarColor],
    ['Dec', 0, defaultChartDataBarColor]
];

export const months = [
    {
        title: 'Jan',
        value: 1,
    },
    {
        title: 'Feb',
        value: 2,
    },
    {
        title: 'Mar',
        value: 3,
    },
    {
        title: 'Apr',
        value: 4,
    },
    {
        title: 'May',
        value: 5,
    },
    {
        title: 'Jun',
        value: 6,
    },
    {
        title: 'Jul',
        value: 7,
    },
    {
        title: 'Aug',
        value: 8,
    },
    {
        title: 'Sep',
        value: 9,
    },
    {
        title: 'Oct',
        value: 10,
    },
    {
        title: 'Nov',
        value: 11,
    },
    {
        title: 'Dec',
        value: 12,
    }
];

export const accessAllPermission = 'AccessAll';
export const allComponentsPermissions = {
    fieldRead: "ReadField",
    fieldCreate: "CreateField",
    fieldUpdate: "UpdateField",
    fieldDelete: "DeleteField",
    fieldArchive: "ArchiveField",
    fieldRestore: "RestoreField",
    sendContractorInvitation: "SendInvitation",
    sendFarmerInvitation: "SendInvitation",
    membersList: "ListMembers",
    memberRoleChange: "ChangeRoleMember",
    memberRead: "ReadMemberProfile",
    memberUpdate: "UpdateMemberProfile",
    memberRemove: "RemoveMember",
    memberSettingRead: "ReadMemberSetting",
    memberSettingUpdate: "UpdateMemberSetting",
    operationRead: "ReadOperation",
    operationCreate: "CreateOperation",
    operationUpdate: "UpdateOperation",
    operationDelete: "DeleteOperation",
    operationTypeRead: "ReadOperationType",
    operationTypeCreate: "OperationTypeCreate",
    operationTypeUpdate: "UpdateOperationType",
    operationTypeDelete: "DeleteOperationType",
    implementCreate: "CreateImplement",
    implementUpdate: "UpdateImplement",
    implementRead: "ReadImplement",
    implementDelete: "DeleteImplement",
    vehicleCreate: "CreateVehicle",
    vehicleUpdate: "UpdateVehicle",
    vehicleRead: "ReadVehicle",
    vehicleDelete: "DeleteVehicle",
    productCreate: "CreateProduct",
    productUpdate: "UpdateProduct",
    productRead: "ReadProduct",
    productDelete: "DeleteProduct",
    productArchive: "ArchiveProduct",
    productRestore: "RestoreProduct",
    createSelfJob: "CreateJob",
    createJob: "CreateJob",
    invitationSend: "SendInvitation",
    invitationListRead: "ReadInvitations",
    invitationAccept: "AcceptInvitation",
    invitationDelete: "DeleteInvitation",
    createContact: "CreateInternalFarmer",
    createInvoice: "CreateInvoice",
    readInvoice: "ReadInvoice",
    updateInvoice: "UpdateInvoice",
    markInvoiceAsOpen: "MarkInvoiceAsOpen",
    markInvoiceAsPaid: "MarkInvoiceAsPaid",
    uploadInvoiceToExternalService: "UploadInvoiceToExternalService",
    readPaymentServiceKey: "ReadPaymentServiceKey",
    addCard: "AddCard",
    deleteCard: "DeleteCard",
    readCard: "ReadCard",
    listCards: "ListCards",
    cardSetDefault: "CardSetDefault",
    createSubscription: "CreateSubscription",
    readSubscription: "ReadSubscription",
    updateSubscription: "UpdateSubscription",
    cancelSubscription: "CancelSubscription",
    createUserAsContact: "CreateUserAsContact"
};

export const minlengthFullName = 2;
export const minlengthFirstName = 2;
export const minlengthLastName = 2;
export const minlengthPhoneNumber = 6;
export const maxlengthPhoneNumber = 10;
export const minlengthCompanyName = 2;
export const minlengthPassword = 8;
export const maxlengthPassword = 32;
export const maxLengthShort1 = 50;
export const maxLengthShort2 = 100;
export const maxLengthLong1 = 200;

export const ownerId = 'ownerId';
export const ownerColor = 'colorCode';
export const searchTextQuery = 'SearchText';
export const offsetQuery = 'Offset';
export const limitQuery = 'Limit';
export const preferenceQuery = '&Preference=';
export const includeDefaultOperationsQuery = '&IncludeDefaults=true';
export const customerIdsQuery = 'CustomerIds';
export const devSuperAdminAccessCode = 'DEFAULT'; 
export const queryIdentifiers = 'Ids';
export const excludeQueryIdentifiers = 'ExcludeIds';
export const isScheduledQuery = 'IsScheduled';

export const acceptableBillingUnitsForBillableQuantityUpdate = ['hour', 'minute'];
export const supportedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp'];
export const jobExportDataFileName = 'job_export_data';
