import { apiVehicles, apiImplements } from '@/utils/endpoints';
import requests from '@/utils/requests';
import { closeOverlay, notify, deleteObjectFromMachineList } from '@/utils/helpers';
import buildUrl from 'build-url';
import { Machine } from '@/data/models/Machine';
import { ResponseList } from '@/data/models/ResponseList';
import { listAction } from '@/enum/listAction';
import { MachineType } from '@/enum/machineType';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';

export const actions = {

    async getMachines({ rootState, dispatch }, machineType: MachineType) {
        await dispatch("jsonToQueryString", rootState.filterOptions);
        let url = "";
        switch (machineType) {
            case MachineType.Vehicle:
                url = buildUrl(rootState.baseUrl, {
                    path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + rootState.tempUrl
                });
                break;
            case MachineType.Implement:
                url = buildUrl(rootState.baseUrl, {
                    path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + rootState.tempUrl
                });
                break;
        }
        const result: ResponseList<Machine> = await requests.getList<Machine>(url, {rootState, cancellable: true});
        return result;
    },

    async getMachineListFiltered({ rootState, state, commit, dispatch }) {
        rootState.listLoading = true;

        const result: ResponseList<Machine> = await dispatch("getMachines", rootState.machineType);
        commit('setMachineListFiltered', result.value);
        dispatch("appendLoadedItemsToSelections", result.value);
        if (rootState.offset == 0 && !rootState.isListRequestCancelled) {
            commit('setNoDataStatus', result.value);
        }
        rootState.listLoading = false;
    },

    async getmachine({ state, commit }, machineId) {

        if (state.machineListFiltered.length != 0) {
            const machine = state.machineListFiltered.filter(machine => machine.id == machineId)[0];
            commit('setMachineDetails', machine);
        }
    },

    async addMachine({ rootState, state, commit }, data) {
        state.addmachineLoader = true;
        rootState.listLoading = true;
        let url = "";
        let msg = "";
        switch (rootState.machineType) {
            case MachineType.Vehicle:
                {
                    url = buildUrl(rootState.baseUrl, {
                        path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles
                    });
                    msg = StringConstants.vehicleAddSuccessMessage;
                    break;
                }
            case MachineType.Implement:
                {
                    url = buildUrl(rootState.baseUrl, {
                        path: localStorage.getItem(ConstantValues.ownerId) + apiImplements
                    });
                    msg = StringConstants.implementAddSuccessMessage;
                    break;
                }
        }
        const result: Machine = await requests.post<Machine>(url, data);
        if (result != null) {
            if (state.machinesFilterType == 0) {
                commit("addMachine", result);
                rootState.totalRowCount = rootState.totalRowCount + 1;
                rootState.noData = state.machineListFiltered.length === 0 ? true : false;
            }
            closeOverlay("editMachineFormOverlay");
            notify(msg, 'success');
            commit("setPageOffset", 1);
            state.addmachineLoader = false;
            commit("clearMachine");
            state.addmachineLoader = false;
            rootState.listLoading = false;
            return result[0];
        }
        state.addmachineLoader = false;
        rootState.listLoading = false;
    },

    async updateMachine({ rootState, state, commit }, data) {
        state.editmachineLoader = true;
        rootState.listLoading = true;

        let url = "";
        let msg = "";
        switch (rootState.machineType) {
            case MachineType.Vehicle:
                {
                    url = buildUrl(rootState.baseUrl, {
                        path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles
                    });
                    msg = StringConstants.vehicleUpdateSuccessMessage;
                    break;
                }
            case MachineType.Implement:
                {
                    url = buildUrl(rootState.baseUrl, {
                        path: localStorage.getItem(ConstantValues.ownerId) + apiImplements
                    });
                    msg = StringConstants.implementUpdateSuccessMessage;
                }
                break;
        }
        url = url + '/' + data[1];
        const result = await requests.put<Machine>(url, data[0]);

        if (result != null) {
            closeOverlay("editMachineFormOverlay");
            notify(msg, 'success');
            state.editmachineLoader = false;
            commit("clearMachine");
            commit("updateMachine", result);
        }
        state.editmachineLoader = false;
        rootState.listLoading = false;
    },

    async setdataAfterListAction({ state, rootState }, data) {
        deleteObjectFromMachineList(state.machineListFiltered, data[0], 'id');
        rootState.totalRowCount = rootState.totalRowCount - 1;
        rootState.noData = state.machineListFiltered.length === 0 ? true : false;
        notify(data[1], data[2]);
    },

    async actionOnMachineList({ rootState, state, dispatch }, data) {
        const listaction: listAction = data[0];
        const machineId = data[1];
        let url = null;
        let result = null;
        const resultSet = [];
        const preferenceParms = "?Preference=" + state.machinesFilterType;
        resultSet.push(machineId);
        switch (rootState.machineType) {
            case MachineType.Vehicle:
                url = buildUrl(rootState.baseUrl, {
                    path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + "/" + machineId
                });
                break;
            case MachineType.Implement:
                url = buildUrl(rootState.baseUrl, {
                    path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + "/" + machineId
                });
                break;
        }


        switch (listaction) {
            case listAction.Delete:
                url = url + preferenceParms;
                result = await requests.deleteObject(url);
                if (result) {
                    if (rootState.machineType == MachineType.Vehicle)
                        resultSet.push(StringConstants.vehicleDeleteSuccessMessage);
                    else
                        resultSet.push(StringConstants.implementDeleteSuccessMessage);
                    resultSet.push('success');
                }
                else {
                    resultSet.push(`${MachineType[rootState.machineType]} can't be deleted.`);
                    resultSet.push('fail');
                }
                break;

            case listAction.Archive:
                url = url + '/archive' + preferenceParms;
                result = await requests.postData(url, {});
                if (result) {
                    if (rootState.machineType == MachineType.Vehicle)
                        resultSet.push(StringConstants.vehicleArchiveSuccessMessage);
                    else
                        resultSet.push(StringConstants.implementArchiveSuccessMessage);

                    resultSet.push('success');

                }
                else {
                    resultSet.push(`${MachineType[rootState.machineType]} can't be archived.`);
                    resultSet.push('fail');
                }
                break;

            case listAction.Restore:
                url = url + '/restore' + preferenceParms;
                result = await requests.postData(url, {});
                if (result) {
                    if (rootState.machineType == MachineType.Vehicle)
                        resultSet.push(StringConstants.vehicleRestoreSuccessMessage);
                    else
                        resultSet.push(StringConstants.implementRestoreSuccessMessage);

                    resultSet.push('success');
                }
                else {
                    resultSet.push(`${MachineType[rootState.machineType]} can't be restored`);
                    resultSet.push('fail');
                }
                break;
        }
        if (result)
            await dispatch("setdataAfterListAction", resultSet)
    },

    async fnSetMachineFilter({ commit, state }, data) {
        if (state.machineSearchText != null && state.machineSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.machineSearchText]);
        }
        commit('setFilterOptions', ['Preference', state.machinesFilterType]);
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async seedImplements({ rootState, state }, data) {
        state.addmachineLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiImplements
        });
        const result = await requests.postData(url, data);
        state.addmachineLoader = false;
        return result;
    },
    
    async seedVehicles({ rootState, state }, data) {
        state.addmachineLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles
        });
        const result = await requests.postData(url, data);
        state.addmachineLoader = false;
        return result;
    },

    async importVehiclesExcel({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + '/import'
        })
        const result = await requests.postData(url, data)
        if (result[0] != null) {
            return result[0]
        }
    },

    async importImplementsExcel({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + '/import'
        })
        const result = await requests.postData(url, data)
        if (result[0] != null) {
            return result[0]
        }
    },

}