







































































































































































































































import $ from "jquery";
import { clearDateTimePicker, openOverlay } from "@/utils/helpers";
import { defaultScrollViewHeight } from "@/utils/uiconstants";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import { routeInvoicesAddExternal } from "@/utils/endpoints";
import router from "@/router/index";
import moment from "moment";
import invoicesExternalComputedMixin from "@/components/invoices-external/invoices-external-computed";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import List from "@/components/shared/list.vue";
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import { BaseButton as Button } from "@/components/shared/buttons/index";
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import { defaultDateFormat } from "@/utils/constants";

export default {    
  name: "InvoicesListViewExternal",
  mixins: [
    permissionsMixin, 
    scrollerMixin, 
    invoicesExternalComputedMixin, 
    requestsCancellationMixin
  ],
  components: {
    DatePicker,
    List,
    Button,
  },
  data() {
    return {
      defaultScrollViewHeight,
      dateInput: {
          placeholder: StringConstants.selectDatePlaceholder,
      },
      format: defaultDateFormat,
      sameDateFormat: {
          from: defaultDateFormat,
          to: defaultDateFormat,
      },
      showExternalInvoicingFilters: false
    };
  },
  beforeDestroy() {
    this.clearAndResetInvoicesPagingStates()
  },
  mounted() { 
    this.fetchingLineItems = false;
    this.totalExternalInvoicesCount = 0;
    this.invoicesListExternal = [];
    this.customersFilterListForInvoicesList = [];
    this.invoiceJobsPreviewLoader = false;
    this.clearInvoiceFilterOptions();
    this.$store.dispatch('getCustomersListForInvoicesFilter');
    this.setFilterOptionsAndGetInvoices();
    this.isAllApprovedJobsSelectedExternal = false;
    this.totalApprovedJobsCountExternal = 0;
  },
  methods: {
    ...mapMutations({
      clearInvoiceDetailsState: "setClearInvoiceDataExternal",
      clearInvoiceFilterOptions: "setClearFilterOptions",
      resetPaging: "resetPaging",
    }),
    openAddInvoice(integrationType: string) {
      switch (integrationType) {
        case "xero":
          this.integrationType = "xero";
          break;
        case "quickbooks":
          this.integrationType = "quickbooks";
          break;
        case "sage":
          this.integrationType = "sage";
          break;
      }
      this.fetchingLineItems = false;
      this.externalContactRadioGroup = 1;
      this.clearInvoiceDetailsState();
      this.invoiceDraftPreviewDetailsExternalLineItems = [];
      this.$store.state.jobsInvoicesModule.map = null;
      router.push(routeInvoicesAddExternal);
    },
    filterInvoicesByDateRange(start, end) {
      this.invoiceDateFilterApplied = true;
      this.clearAndResetInvoicesPagingStates();
      this.setFilterOptionsAndGetInvoices();

    },
    clearInvoicesDateFilter() {
      $(".vdpr-datepicker input[type=text]").first().val("");
      clearDateTimePicker(this.$refs.datePicker.$el);
      this.invoiceDateFilterApplied = false;

      this.clearAndResetInvoicesPagingStates();
      this.setFilterOptionsAndGetInvoices();
    },
    onInvoicesSearchClearClicked() {
      this.clearAndResetInvoicesPagingStates();
      this.$store.dispatch("getInvoicesListExternal", this.integrationType);
    },
    applySearchExternalInvoicesFilter() {
      this.clearAndResetInvoicesPagingStates();
      this.setFilterOptionsAndGetInvoices();
    },
    clearAndResetInvoicesPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.invoicesExternalModule.invoicesListExternal = [];

      this.clearInvoiceDetailsState();
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
    },
    getLocalFormat(date) {
      const formattedDate = moment.utc(date).local().format("DD MMM YYYY");
      return formattedDate;
    },
    openInvoiceDetails(invoice: Record<string, any>) {
      window.open(invoice?.url, "_blank");
    },
    
    goToQuickbooks() {
      window.open(ConstantValues.qbInvoiceTarget, "_blank").focus();
    },
    goToXero() {
      window.open(ConstantValues.xeroInvoiceTarget, "_blank").focus();
    },
    goToSage() {
      window.open(ConstantValues.sageInvoiceTarget, "_blank").focus();
    },
    openJobsPreview(jobIds: Array<any>) {
      openOverlay("invoiceJobsPreviewFlyout");
      this.$store.dispatch("getJobsListForPreview", jobIds.join());
    },
    resetPagingForModule() {
      const $state = this.$store.state.invoicesExternalModule;
      $state.offset = ConstantValues.defaultPageOffsetSize;
      $state.limit = ConstantValues.defaultPageLimitSize;
      $state.listCount = ConstantValues.defaultPageListCount;
      $state.noData = false;
      this.resetPaging();
    },
    searchCustomersListFilter() {
      this.$store.dispatch('getCustomersListForInvoicesFilter');
    },
    filterInvoicesListByCustomer() {
      alert('to do filter');
    },
    setFilterOptionsAndGetInvoices(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
      this.$store.dispatch("fnSetInvoicesFilterExternal", filters)
      .then(res => {
        this.$store.dispatch("getInvoicesListExternal", this.integrationType)
      })
    }
  },
};
