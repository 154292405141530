<template>
  <v-card 
    elevation="2" 
    class="seed-data-container pa-6"
  >
    <v-card-title class="d-flex align-center justify-space-between pb-4">
      <span class="text-h5 font-weight-bold">
        <v-icon left color="primary">mdi-database-plus</v-icon>
        Seed Data Generator
      </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon 
            v-bind="attrs" 
            v-on="on" 
            color="grey"
          >
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span>Configure and generate seed data for your application</span>
      </v-tooltip>
    </v-card-title>

    <v-divider class="mb-4"></v-divider>

    <v-form ref="seedForm" v-model="isFormValid">
      <v-row>
        <v-col cols="12" md="4" class="pl-0 pr-2">
          <v-text-field
            v-model.number="seedSize"
            type="number"
            label="Seed Size"
            placeholder="Enter seed size"
            prepend-inner-icon="mdi-numeric"
            :rules="[v => v > 0 || 'Seed size must be positive']"
            :disabled="isLoading"
            outlined
            dense
            required
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    v-bind="attrs" 
                    v-on="on" 
                    color="grey"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>Number of records to generate</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pr-2">
          <v-text-field
            v-model="prefixId"
            label="Prefix ID"
            placeholder="Enter prefix ID"
            prepend-inner-icon="mdi-tag-text"
            :rules="[v => !!v || 'Prefix ID is required']"
            :disabled="isLoading"
            outlined
            dense
            required
          />
        </v-col>
        <v-col cols="12" md="4" class="pr-2">
          <v-btn 
            @click="generatePrefixId"
            :disabled="isDisabled || isLoading"
            color="primary"
            outlined
          >
            Generate Prefix ID
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-expand-transition>
      <v-alert 
        v-if="isLoading" 
        type="info" 
        outlined 
        class="mt-4"
      >
        <v-row align="center">
          <v-col cols="12">
            <v-progress-linear 
              :value="progressPercentage"
              color="primary"
            ></v-progress-linear>
            <div class="mt-2">
              {{ loadingMessage }}
            </div>
          </v-col>
        </v-row>
      </v-alert>
    </v-expand-transition>

    <div class="seed-actions mt-4">
      <seed-action-button 
        v-for="(action, index) in seedActions"
        :key="index"
        :text-value="`${action.label}`"
        :is-disabled="!isFormValid || isLoading"
        @click="action.method"
      />
    </div>
  </v-card>
</template>

<script>
import ConstantValues from '@/plugins/constantValues';
import { apiOperationTypes } from '@/utils/endpoints';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import SeedActionButton from './seed-action-button.vue';

export default {
  components: {
    SeedActionButton
  },
  data() {
    return {
      seedSize: 50,
      seedActions: [
        { 
          label: 'Seed Vehicles', 
          method: this.seedVehicles 
        },
        { 
          label: 'Seed Implements', 
          method: this.seedImplements 
        },
        { 
          label: 'Seed Products', 
          method: this.seedProducts 
        },
        { 
          label: 'Seed Operations', 
          method: this.seedOperations 
        },
        { 
          label: 'Seed Customers', 
          method: this.seedCustomers 
        }
      ],
      prefixId: '',
      isLoading: false,
      currentAction: '',
      currentProgress: 0,
      totalProgress: 0
    };
  },
  mounted() {
    this.$store.state.dashboardTitle = "Seed Data";
  },
  computed: {
    progressPercentage() {
      return this.totalProgress > 0 
        ? (this.currentProgress / this.totalProgress) * 100 
        : 0;
    },
    loadingMessage() {
      return this.isLoading 
        ? `${this.currentAction} - ${this.currentProgress}/${this.totalProgress} records` 
        : 'Generating data, please wait...';
    }
  },
  methods: {
    generateRandomString(stringLength) {
      const alphaNumericCharacters = 
        "abcdefghijklmnopqrstuvwxyz0123456789";
      return Array.from(
        { length: stringLength }, 
        () => alphaNumericCharacters[
          Math.floor(Math.random() * alphaNumericCharacters.length)
        ]
      ).join('');
    },

    async seedWithProgress(actionName, seedMethod) {
      try {
        this.isLoading = true;
        this.currentAction = actionName;
        this.currentProgress = 0;
        this.totalProgress = this.seedSize;

        for (let i = 0; i < this.seedSize; i++) {
          await seedMethod(i);
          this.currentProgress = i + 1;
        }
      } catch (error) {
        console.error(`Error seeding ${actionName}:`, error);
      } finally {
        this.isLoading = false;
        this.currentAction = '';
        this.currentProgress = 0;
        this.totalProgress = 0;
      }
    },

    async seedVehicles() {
      await this.seedWithProgress('Seeding Vehicles', async (i) => {
        const vehicleObj = {
          title: `VEHICLE_${this.prefixId}_${i}`,
          healthSafetyList: [],
          isHealthSafetyListMandatory: false,
          length: i + 100,
          width: i,
          make: `MK${i}`,
          model: `MD${i}`,
          ownership: i % 2 === 0 ? 0 : 1,
          registration: `REG_${i}_${this.generateRandomString(8).toUpperCase()}`,
          weight: i * 10,
        };
        await this.$store.dispatch("seedVehicles", vehicleObj);
      });
    },

    async seedProducts() {
      await this.seedWithProgress('Seeding Products', async (i) => {
        const productObj = {
          title: `PRODUCT_${this.prefixId}_${i}`,
          cost: i,
          unit: i % 2 === 0 ? "Metre" : "Kg",
        };
        await this.$store.dispatch("seedProducts", productObj);
      });
    },

    async seedCustomers() {
      await this.seedWithProgress('Seeding Customers', async (i) => {
        const customerTitle = `customer_${this.prefixId}_${i}`;
        const farmerObj = {
          businessProfile: {
            vatNumber: null,
            address: {
              addressLine1: "Line 1",
              addressLine2: "Line 2",
              country: null,
              city: null,
              state: null,
              postalCode: this.generateRandomString(6).toUpperCase(),
              coordinate: {
                latitude: 0,
                longitude: 0
              }
            },
            registrationNumber: null,
            title: customerTitle,
            shortTitle: "TES"
          },
          email: `${customerTitle}@example.com`,
          firstName: customerTitle,
          colorCode: "#646429",
          primaryContactNumber: "64563456",
          secondaryContactNumber: null,
          secondaryFirstName: ""
        };

        await this.$store.dispatch("seedFarmers", farmerObj);
      });
    },

    async seedImplements() {
      await this.seedWithProgress('Seeding Implements', async (i) => {
        const implementObj = {
          title: `IMPLEMENT_${this.prefixId}_${i}`,
          healthSafetyList: [],
          isHealthSafetyListMandatory: false,
          length: i + 100,
          width: i,
          make: `MK${this.prefixId}_${i}`,
          model: `MD${this.prefixId}_${i}`,
          ownership: i % 2 === 0 ? 0 : 1,
          registration: `REG_${i}_${this.generateRandomString(8).toUpperCase()}`,
          weight: i * 10,
        };
        await this.$store.dispatch("seedImplements", implementObj);
      });
    },

    async seedOperations() {
      await this.seedWithProgress('Seeding Operations', async () => {
        const url = buildUrl(this.$store.state.baseUrl, {
          path: `${localStorage.getItem(ConstantValues.ownerId)}${apiOperationTypes}`
        });

        try {
          const result = await requests.getData(url);
          if (result?.data?.value?.length) {
            const { title, id } = result.data.value[0];
            
            const operationObject = {
              title: `${title} ${this.generateRandomString(5).toUpperCase()}`,
              description: "",
              billingCost: Math.floor(Math.random() * 1000),
              billingUnit: "Metre",
              isTaxable: false,
              vat: 0,
              typeId: id,
              isHealthSafetyListMandatory: false,
              healthSafetyList: [],
              loadInformationList: [],
              vehicles: [],
              implements: [],
              products: []
            };

            await this.$store.dispatch("seedOperations", operationObject);
          }
        } catch (error) {
          console.error('Error seeding operations:', error);
        }
      });
    },

    generatePrefixId() {
      this.prefixId = this.generateRandomString(5).toUpperCase();
    }
  }
};
</script>

<style scoped>
.seed-actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.seed-data-container {
  transition: all 0.3s ease;
  max-width: 800px;
  box-shadow: none !important;
}
</style>
