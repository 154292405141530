import { apiProducts, apiProductUnits } from '@/utils/endpoints';
import request from '@/utils/requests';
import { notify, closeOverlay, deleteObjectFromList } from '@/utils/helpers';
import buildUrl from 'build-url';
import { Product } from '@/data/models/Product';
import { ResponseList } from '@/data/models/ResponseList';
import { listAction } from '@/enum/listAction';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';

export const actions = {

    async getProductsList({ state, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProducts + rootState.tempUrl
        });
        const result: ResponseList<Product> = await request.getList<Product>(url, {
            rootState,
            cancellable: true
        });
        return result;
    },

    async getProductsListFiltered({ state, commit, rootState, dispatch }) {
        state.productLoader = true;
        rootState.listLoading = true;
        const result = await dispatch("getProductsList");
        if (result != null) {
            if (result.value.length > 0) {
                commit('setProductstFilteredList', result.value);
                dispatch("appendLoadedItemsToSelections", result.value);
            }
            if (rootState.offset == 0 && !rootState.isListRequestCancelled) {
                commit('setNoDataStatus', result.value);
            }
        }
        state.productLoader = false;
        rootState.listLoading = false;
    },

    async addOrUpdateProduct({ state, rootState, commit }, data: Product) {
        state.editProductLoader = true;
        let url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProducts
        });
        let result = new Product();
        if (data.id != null) {
            url = url + "/" + data.id;
            result = await request.put<Product>(url, data);
        }
        else {
            result = await request.post<Product>(url, data);
        }
        if (result != null) {
            closeOverlay("editProductFormOverlay");
            state.editProductLoader = false;
            if (data.id != null) {
                notify(StringConstants.productUpdated, 'success');
                commit("updateProduct", result);
                commit('resetProductDetails');
            }
            else {
                notify(StringConstants.productCreated, 'success');
                if (state.productStatus == 0) {
                    commit("setAddProduct", result);
                    rootState.totalRowCount = rootState.totalRowCount + 1;
                    rootState.noData = state.productsList.length === 0 ? true : false;
                }
            }
        }
        else {
            if (result.title != null) {
                notify(result.title, 'fail');
            }
            else {
                notify(StringConstants.productNotCreated, 'fail');
            }
            state.editProductLoader = false;
        }
    },

    async seedProducts({ state, rootState }, data) {
        state.addProductLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProducts
        });
        const result = await request.postData(url, data);
        state.addProductLoader = false;

        return result;
    },

    async getProductDetails({ state, commit, rootState }, id) {
        state.productLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProducts + "/" + id
        });
        const result: Product = await request.get<Product>(url);
        if (result != null) {
            commit('setProductDetails', result);
            state.productLoader = false;
        }
    },

    async setProductsListFilter({ commit, state }, data) {
        if (state.productSearchText != null && state.productSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.productSearchText]);
        }
        commit('setFilterOptions', ['Preference', state.productStatus]);
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async actionOnProduct({ state, rootState }, data) {
        const actionType: listAction = data[0];
        const id = data[1];
        const path = localStorage.getItem(ConstantValues.ownerId) + apiProducts + "/" + id;
        const preferenceParms = "?Preference=" + state.productStatus;
        switch (actionType) {
            case listAction.Delete: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + preferenceParms
                });
                const result = await request.deleteObject(url);
                if (result) {
                    notify(StringConstants.productDeleted, 'success');
                    deleteObjectFromList(state.productsList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.productsList.length === 0 ? true : false;
                } else {
                    notify(StringConstants.productNotDeleted, 'fail');
                }

                break;
            }
            case listAction.Archive: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + '/archive' + preferenceParms
                });
                const result = await request.post<Product>(url, {});
                if (result != null) {
                    notify(StringConstants.productArchiveSuccessMessage, 'success');
                    deleteObjectFromList(state.productsList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.productsList.length === 0 ? true : false;
                }
                else {
                    notify(StringConstants.notArchivedText, 'fail');
                }
                break;
            }
            case listAction.Restore: {
                const url = buildUrl(rootState.baseUrl, {
                    path: path + "/restore" + preferenceParms
                });
                const result = await request.post<Product>(url, {});
                if (result != null) {
                    notify(StringConstants.productRestoredSuccessMessage, 'success');
                    deleteObjectFromList(state.productsList, id, 'id');
                    rootState.totalRowCount = rootState.totalRowCount - 1;
                    rootState.noData = state.productsList.length === 0 ? true : false;
                }
                else {
                    notify(StringConstants.notArchivedText, 'fail');
                }
                break;
            }

        }
    },

    async importProductsExcel({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProducts + '/import'
        })
        const result = await request.postData(url, data)
        if (result[0] != null) {
            return result[0]
        }
    },

    async getProductsUnits({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProductUnits
        });
        const result = await request.getData(url, true);
        if (result != null) {
            return result
        }
    },
}