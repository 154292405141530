import { render, staticRenderFns } from "./field-list.vue?vue&type=template&id=53e0b090&scoped=true"
import script from "./field-list.vue?vue&type=script&lang=ts"
export * from "./field-list.vue?vue&type=script&lang=ts"
import style0 from "./field-list.vue?vue&type=style&index=0&id=53e0b090&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e0b090",
  null
  
)

export default component.exports