import ConstantValues from "@/plugins/constantValues";
import requests from "@/utils/requests";

export default {
  data() {
    return {
      timerId: null,
    };
  },
  methods: {
    applySearchTimer(callback: () => void) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = setTimeout(() => {
        requests.cancelPendingRequests();
        requests.initializeAbortControllerForJob();
        callback();
      }, ConstantValues.defaultDropdownSearchTimeout);
    },
  },
};
