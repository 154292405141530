//import { Coordinate } from './Coordinate';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { ApprovalStatusType } from '@/enum/approvalStatusType';
import { MinimalUser } from '@/data/models/MinimalUser';
import { TeamJobOperation } from '@/data/models/TeamJobOperation';
import { TeamJobField } from './TeamJobField';
import { TeamJobProduct } from './TeamJobProduct';
import { TeamJobTimeDetails } from './TeamJobTimeDetails';
import { TeamJobAddress } from './TeamJobAddress';
import { TeamJobMetaData } from './TeamJobMetaData';

export class TeamJob {
    id?: string;
    creatorId?: string;
    createdOn?: string;
    lastUpdatedOn?: string;
    number?: string;
    scheduledAt?: string;
    instructions?: string;
    notes?: string;
    status: number;
    statusChangeHistory?: [];
    customer: MinimalUser;
    customerCancellationReason?: string;
    contractor: MinimalUser;
    contractorApprovalStatus: number;
    contractorDeclineReason?: string;
    operation: TeamJobOperation;
    operators?: [];
    fields?: TeamJobField[];
    products?: TeamJobProduct[];
    timeRecords?: TeamJobTimeDetails[];
    addresses?: TeamJobAddress[];
    attachments?: [];
    metaData?: TeamJobMetaData[];
    invoiceNumber?: string;
    invoiceUrl?: string;
    isApprovedToInvoice: boolean;
    tags?: string[];

    constructor() {
        this.id = null;
        this.creatorId = null;
        this.createdOn = null;
        this.lastUpdatedOn = null;
        this.number = null;
        this.scheduledAt = null;
        this.instructions = null;
        this.notes = null;
        this.status = TeamJobStatusType.Pending;
        this.statusChangeHistory = [];
        this.customer = new MinimalUser();
        this.customerCancellationReason = null;
        this.contractor = new MinimalUser();
        this.contractorApprovalStatus = ApprovalStatusType.Pending;
        this.contractorDeclineReason = null;
        this.operation = new TeamJobOperation();
        this.operators = [];
        this.fields = new Array<TeamJobField>();
        this.products = new Array<TeamJobProduct>();
        this.timeRecords = new Array<TeamJobTimeDetails>();
        this.addresses = new Array<TeamJobAddress>();
        this.attachments = [];
        this.metaData = new Array<TeamJobMetaData>();
        this.invoiceNumber = null;
        this.invoiceUrl = null;
        this.isApprovedToInvoice = false;
        this.tags = [];
    }

    getTeamJob?(teamJob: TeamJob) {
        this.id = teamJob.id;
        this.creatorId = teamJob.creatorId;
        this.createdOn = teamJob.createdOn;
        this.lastUpdatedOn = teamJob.lastUpdatedOn;
        this.number = teamJob.number;
        this.scheduledAt = teamJob.scheduledAt;
        this.instructions = teamJob.instructions
        this.notes = teamJob.notes;
        this.status = teamJob.status;
        this.statusChangeHistory = teamJob.statusChangeHistory;
        this.customer = new MinimalUser().getMinimalUser(teamJob.customer);
        this.customerCancellationReason = teamJob.customerCancellationReason;
        this.contractor = new MinimalUser().getMinimalUser(teamJob.contractor);
        this.contractorApprovalStatus = teamJob.contractorApprovalStatus;
        this.contractorDeclineReason = teamJob.contractorDeclineReason;
        this.operation = new TeamJobOperation().getTeamJobOperation(teamJob.operation);
        this.operators = teamJob.operators;
        this.attachments = teamJob.attachments;
        this.tags = teamJob.tags;


        if(teamJob.fields && teamJob.fields.length) {
            this.fields = teamJob.fields.map(field => new TeamJobField().getTeamJobField(field))
        } else {
            this.fields = [];
        }

        if(teamJob.products && teamJob.products.length) {
            this.products = teamJob.products.map(product => new TeamJobProduct().getTeamJobProduct(product))
        } else {
            this.products = [];
        }

        if(teamJob.timeRecords && teamJob.timeRecords.length) {
            this.timeRecords = teamJob.timeRecords.map(tr => new TeamJobTimeDetails().getTeamJobTimeDetails(tr))
        } else {
            this.timeRecords = [];
        }

        if(teamJob.addresses && teamJob.addresses.length) {
            this.addresses = teamJob.addresses.map(adr => new TeamJobAddress().getTeamJobAddress(adr))
        } else {
            this.addresses = [];
        }

        if(teamJob.metaData && teamJob.metaData.length) {
            this.metaData = teamJob.metaData.map(md => new TeamJobMetaData().getTeamJobMetaData(md))
        } else {
            this.metaData = [];
        }

        this.invoiceNumber = teamJob.invoiceNumber;
        this.invoiceUrl = teamJob.invoiceUrl;
        this.isApprovedToInvoice = teamJob.isApprovedToInvoice;

        return this;
    }

}