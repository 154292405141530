
import { JobAccessLevelType } from '@/enum/jobAccessLevelType';
import { TeamJobOperatorChecklistResourceType } from '@/enum/teamJobOperatorChecklistResourceType';
import ConstantValues from '@/plugins/constantValues';
import store from '@/store';
import buildUrl from "build-url";
import { apiImplements, apiOperations, apiVehicles } from "../endpoints";
import requests from "../requests";

export const getAssignedUserNameFromJob = (job: any) => {
   const totalAssignedUsers = job.operators.length;
    if (totalAssignedUsers != 0) {
        if (totalAssignedUsers > 1) {
            return job.operators[0].name + " + " + (totalAssignedUsers - 1)
        }
        else {
            return job.operators[0].name
        }
    }
    else {
       return '-'
    }
}

export const getJobNumberPrefix = (jobNumber: string) => {
    if (jobNumber.split("#").length < 2) {
        return "#" + jobNumber;
    }
    return jobNumber.split("#")[0];
}

export const getJobNumberSuffix = (jobNumber: string) => {
    if (jobNumber.split("#").length == 2) {
        return "#" + jobNumber.split("#")[1];
    }
}

export const getCalculatedFieldArea = (field: any) => {
    let areaInSquareMeter = 0;
    for (const i in field) {
        areaInSquareMeter += field[i].areaInSquareMeter;
    }
    let convertedArea = null;
    let convertedAreaUnit = null;
    switch (localStorage.getItem('defaultAreaMeasurementUnit')) {
        case '0':
            // Hectare
            convertedArea = (areaInSquareMeter / 10000).toFixed(2);
            convertedAreaUnit = 'ha';
            break;
        case '1':
            // Acre
            convertedArea = (areaInSquareMeter / 4047).toFixed(2);
            convertedAreaUnit = 'acre';
            break;
        case '2':
            // Square Meter
            convertedArea = (areaInSquareMeter).toFixed(2);
            convertedAreaUnit = 'Sq m';
            break;
        default:
            // Square Meter
            convertedArea = (areaInSquareMeter).toFixed(2);
            convertedAreaUnit = 'Sq m';
            break;
    }
    return convertedArea + ' ' + convertedAreaUnit;
}

export const isOperatorPresentInOperatorsList = (operatorsList, operatorId) => {
    if(operatorsList.length) {
        if(operatorsList.find(operator => operator.operatorDetails.id == operatorId)) {
            return true;
        }
    } else {
        return false;
    }
}

export const getChecklistItemFromResource = (resource, resourceType) => {
    const { healthSafetyList } = resource
    if(healthSafetyList && healthSafetyList.length) {
        //isMandatory off if changed after job in-progress
        return {
            isMandatory: resource.isHealthSafetyListMandatory,
            items: healthSafetyList,
            resourceType: resourceType
        }
    } else {
        return null
    }
}

export const getChecklists = (operation, operatorDetails) => {
    const tempChecklist = []

    if(operatorDetails.defaultVehicle) {
        const vehicleChecklistObj = getChecklistItemFromResource(operatorDetails.defaultVehicle, TeamJobOperatorChecklistResourceType.Vehicle)
        if(vehicleChecklistObj) {
            tempChecklist.push(vehicleChecklistObj)
        }
    }

    if(operatorDetails.defaultImplement) {
        const implementChecklistObj = getChecklistItemFromResource(operatorDetails.defaultImplement, TeamJobOperatorChecklistResourceType.Implement)
        if(implementChecklistObj) {
            tempChecklist.push(implementChecklistObj)
        }
    }
    
    if (operation) {
        const operationChecklistObj = getChecklistItemFromResource(operation, TeamJobOperatorChecklistResourceType.Operation)
        if(operationChecklistObj) {
            tempChecklist.push(operationChecklistObj)
        }
    }

    return tempChecklist
}

export const getOperatorsWithChecklists = (operation, jobOperatorsList) => {
    const tempOperators = []
    jobOperatorsList.forEach(operator => {
        const operatorObj = {
            id: operator.operatorDetails.id,
            level: operator.isTeamLead ? JobAccessLevelType.Primary : JobAccessLevelType.Secondary,
            vehicle: operator.operatorDetails.defaultVehicle ? {
                id: operator.operatorDetails.defaultVehicle.id
            } : null,
            implement: operator.operatorDetails.defaultImplement ? {
                id: operator.operatorDetails.defaultImplement.id
            } : null,
            checklists: (operator.operatorDetails.checklists && operator.operatorDetails.checklists.length) ? operator.operatorDetails.checklists : getChecklists(operation, operator.operatorDetails),
        }
        
        tempOperators.push(operatorObj)
    })

    return tempOperators
}

export const createJobOperatorsForOperatorsView = (jobOperatorsList, operatorsList) => {
    jobOperatorsList = [];

    function getHealthSafetyList(resourceType, operatorChecklists) {
        if(operatorChecklists && operatorChecklists.length) {
            const resourceChecklist = operatorChecklists.find(checklist => checklist.resourceType == resourceType)
            return resourceChecklist ? resourceChecklist.items : []
        }
    }

    function getChecklistMandatory(checklistType, checklists) {
        switch(checklistType) {
            case TeamJobOperatorChecklistResourceType.Vehicle:
                if(checklists && checklists.length) {
                    const vehicleChecklist = checklists.find(checklist => checklist.resourceType == TeamJobOperatorChecklistResourceType.Vehicle)
                    return vehicleChecklist ? vehicleChecklist.isMandatory : false
                }
                break;
            case TeamJobOperatorChecklistResourceType.Implement:
                if(checklists && checklists.length) {
                    const implementChecklist = checklists.find(checklist => checklist.resourceType == TeamJobOperatorChecklistResourceType.Implement)
                    return implementChecklist ? implementChecklist.isMandatory : false
                }
                break;
        }
    }

    function getMachine(operator, operatorMachine, operatorMachineType) {
        if(operatorMachine) {
            const machineObj = {
                id: operatorMachine.id,
                title: operatorMachine.title,
                healthSafetyList: getHealthSafetyList(operatorMachineType, operator.checklists),
                isHealthSafetyListMandatory: getChecklistMandatory(operatorMachineType, operator.checklists),
            }
            return machineObj
        }
    }

    if(operatorsList && operatorsList.length) {
        operatorsList.forEach((operator) => {
            const operatorObj = {
                isTeamLead: operator.level == JobAccessLevelType.Primary,
                operatorDetails: {
                    id: operator.id,
                    firstName: operator.name,
                    defaultVehicle: getMachine(operator, operator.vehicle, TeamJobOperatorChecklistResourceType.Vehicle),
                    defaultImplement: getMachine(operator, operator.implement, TeamJobOperatorChecklistResourceType.Implement),
                    workHistory: operator.workHistory ? operator.workHistory : [],
                    travelHistory: operator.travelHistory ? operator.travelHistory : [],
                    timeline: operator.timeline ? operator.timeline : [],
                    checklists: operator.checklists ? operator.checklists : [],
                    workStatus: operator.workStatus != undefined ? operator.workStatus : null,
                    travelStatus: operator.travelStatus != undefined ? operator.travelStatus : null,
                    colorCode: operator.colorCode || null,
                }
            }
            
            jobOperatorsList.push(operatorObj);
        })
    }

    return jobOperatorsList
}

export const getResourcesForAssignedOperators = (operatorsList, resourcesList, resourceType) => {
    
    const machineConfig = {
        apiMachine: '',
        machineType: ''
    }

    switch(resourceType){
        case TeamJobOperatorChecklistResourceType.Vehicle:
            machineConfig.apiMachine = apiVehicles;
            machineConfig.machineType = 'vehicle';
            break;
        case TeamJobOperatorChecklistResourceType.Implement:
            machineConfig.apiMachine = apiImplements;
            machineConfig.machineType = 'implement';
            break;
        default: break;
    }

    async function getMachineInformation(machineId) {
        const url = buildUrl(store.state.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + machineConfig.apiMachine + '/' + machineId
        });
        const result = await requests.getData(url);
        if(result && result.data) return result.data
    }

    if(operatorsList && operatorsList.length) {
        operatorsList.forEach(async (operator) => {
            if(operator[machineConfig.machineType] && operator[machineConfig.machineType]['id']) {
                const machine = await getMachineInformation(operator[machineConfig.machineType]['id'])
                if(machine) resourcesList.push(machine)
            }
        })
    }

}

export const getOperatorsWithOperations = async (operationId, jobOperatorsList) => {
    const url = buildUrl(store.state.baseUrl, {
        path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations + '/' + operationId
    });
    const result = await requests.getData(url);
    if(result && result.data) {
        const operation = result.data;
    
        return getOperatorsWithChecklists(
            operation,
            jobOperatorsList,
        )
    }
}

export const getPageParam = () => {
    return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
}

export const removeAllLocalParamsJobs = () => {
    localStorage.removeItem('jobSearchQuery');
    localStorage.removeItem('jobStatuses');
    localStorage.removeItem('jobCustomerIds');
    localStorage.removeItem('jobOperationsIds');
    localStorage.removeItem('jobInvoiceStatuses');
    localStorage.removeItem('jobAssignedUserIds');
    localStorage.removeItem('jobStartDate');
    localStorage.removeItem('jobEndDate');
}

export const getTagsTitle = (tags) => {
    if (!tags || !Array.isArray(tags)) return ''
        return tags.length > 1 ? tags.join(', ') : ''
}

export const getPrimaryTagDisplay = (tags) => {
    if (!tags || !Array.isArray(tags) || tags.length === 0) return '-'
                
    const primaryTag = tags[0]
    if (typeof primaryTag !== 'string') return '-'
    
    return primaryTag.length > 10 ? primaryTag.substring(0, 10) + '..' : primaryTag
}

export const hasAdditionalTags = (tags) => {
    return tags && Array.isArray(tags) && tags.length > 1
}

export const hasTags = (tags) => {
    return tags && Array.isArray(tags) && tags.length > 0
}

export const getAdditionalTagsCount = (tags) => {
    if (!tags || !Array.isArray(tags)) return 0
    return Math.max(0, tags.length - 1)
}
