




















































































































































































































































import $ from "jquery";
import { openOverlay } from "@/utils/helpers";
import { defaultScrollViewHeight } from "@/utils/uiconstants";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import operationsComputedMixin from "@/components/operations/operations-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { SkeletonLoader } from "@/components/shared/loaders/index";
import skeletonLoaderMixin from "@/mixins/skeleton-loader-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { UserRoleType } from "@/enum/userRoleType";

export default {
  name: "OperationsListView",
  mixins: [
    permissionsMixin,
    filterParamsMixin,
    operationsComputedMixin,
    skeletonLoaderMixin,
    scrollerMixin,
    Timers
  ],
  components: {
    Button,
    SkeletonLoader,
    DeleteItemDialog
  },
  data() {
    return {
      deleteDialog: false,
      deleteOperationId: "",
      defaultScrollViewHeight,
      searchTimer: null,
      UserRoleType,
      
    };
  },
  beforeMount() {
    this.clearAndResetOperationsPagingStates();
  },
  beforeDestroy() {
    this.clearAndResetOperationsPagingStates();
  },
  mounted() {
    if (!this.$store.state.isPageRelated) {
        localStorage.removeItem('operationSearchTextQuery');
    }
    this.$store.state.dashboardTitle = this.$stringConstants('operationsComponentTitle');
    this.calculateColumnWidths(this.$refs.listHeader);
    this.clearOperationTypesList();
    this.clearOperationsList();
    this.getUrlParamsOperationsLocal();
    this.$store
      .dispatch("setOperationsFilter", [
        ConstantValues.defaultPageOffsetSize,
        ConstantValues.defaultPageLimitSize,
      ])
      .then((res) => {
        this.$store.dispatch("getOperationTypes");
      });
    this.$store.dispatch("getOperationUnits");
    this.$store.dispatch("getLoadInfoUnits");
  },
  methods: {
    ...mapMutations({
      clearOperationFilterOptions: "setClearFilterOptions",
      clearOperationTypesList: "setClearOperationTypesList",
      clearOperationsList: "setClearOperationsList",
      setClearAddOperationTypesList: "setClearAddOperationTypesList",
      setClearSubOperationDetails: "setClearSubOperationDetails",
      resetPaging: "resetPaging",
    }),
    setUrlParamsOperationsLocal() {
      this.setLocalParamsMx(
        this.operationSearchText,
        "operationSearchTextQuery"
      );
    },
    getUrlParamsOperationsLocal() {
      this.operationSearchText = this.getLocalParamsMx(
        "operationSearchTextQuery",
        ""
      );
    },
    onOperationSearchClearClicked() {
      this.operationTypesList = [];
      this.clearOperationFilterOptions();
      this.resetPaging();
      this.removeLocalOperationsParams();
      this.$store
        .dispatch("setOperationsFilter", [
          ConstantValues.defaultPageOffsetSize,
          ConstantValues.defaultPageLimitSize,
        ])
        .then((res) => {
          this.$store.dispatch("getOperationTypes");
        });
    },
    removeLocalOperationsParams() {
      this.operationSearchText = this.removeLocalParamsMx(
        "operationSearchTextQuery",
        ""
      );
    },
    openManageOperationsForm() {
      this.$store.commit('setClearFilterOptions');
      this.setClearAddOperationTypesList();
      this.$store.dispatch("getOperationCategoriesList");
      openOverlay("manageOperationsFormOverlay");
      this.scrollToToForm();
    },
    setSubOperationTaxes() {
      this.$store.dispatch("getInvoiceConfiguration");
    },
    addSubOperation(operationTypeId: string, operationTypeName: string) {
      this.setClearSubOperationDetails();
      this.clearOperationFilterOptions();
      this.selectedSubOperationId = operationTypeId;
      this.selectedSubOperationName = operationTypeName;
      this.setSubOperationTaxes();
      openOverlay("addSubOperationFormOverlay");
      this.scrollToToForm();
    },
    async openSubOperationEditForm(subOperationId: string) {
      this.setClearSubOperationDetails();
      this.clearOperationFilterOptions();
      this.selectedSubOperationId = subOperationId;
      openOverlay("editSubOperationFormOverlay");
      this.setSubOperationTaxes();
      this.scrollToToForm();
    },
    applySearchOperationsFilter() {
      this.clearOperationFilterOptions();
      this.resetPaging();
      this.operationTypesList = [];
      this.filtersApplied = true;
      this.setUrlParamsOperationsLocal();
      if (!this.operationSearchText) {
        this.clearOperationFilterOptions();
        this.filtersApplied = false;
      }
      this.$store
        .dispatch("setOperationsFilter", [
          ConstantValues.defaultPageOffsetSize,
          ConstantValues.defaultPageLimitSize,
        ])
        .then((res) => {
          this.$store.dispatch("getOperationTypes");
        });
    },
    promtDeleteOperation(operationId: string) {
      this.deleteDialog = true;
      this.deleteOperationId = operationId;
    },
    proceedOperationDeletion() {
      this.deleteDialog = false;
      this.$store
        .dispatch("deleteOperation", this.deleteOperationId)
        .then((res) => {
          this.$store.state.operationsModule.operationTypesList = [];
          this.$store
            .dispatch("setOperationsFilter", [
              ConstantValues.defaultPageOffsetSize,
              ConstantValues.defaultPageLimitSize,
            ])
            .then((rs) => {
              this.$store.dispatch("getOperationTypes");
              this.deleteOperationId = "";
            });
        });
    },
    cancelOperationDeletion() {
      this.deleteDialog = false;
      this.deleteOperationId = "";
    },
    clearAndResetOperationsPagingStates() {
      $(window).unbind("scroll");
      this.operationTypesList = [];
      this.clearOperationFilterOptions();
      this.resetPaging();
      this.filtersApplied = false;
    },
    
    proceedSubOperationDeletion() {
      this.deleteDialog = false;
      this.$store
        .dispatch("deleteSubOperation", this.deleteOperationId)
        .then((res) => {
          this.operationTypesList = [];
          this.$store
            .dispatch("setOperationsFilter", [
              ConstantValues.defaultPageOffsetSize,
              ConstantValues.defaultPageLimitSize,
            ])
            .then((rs) => {
              this.$store.dispatch("getOperationTypes");
              this.deleteOperationId = "";
            });
        });
    },
    cancelSubOperationDeletion() {
      this.deleteDialog = false;
      this.deleteOperationId = "";
    },
    handleManageOperationsClick() {
      this.openManageOperationsForm();
      this.$emit('setActiveFlyout', 'manage-form')
    },
    handleAddItemClick(operationType) {
      this.addSubOperation(operationType.id, operationType.title)
      this.$emit('setActiveFlyout', 'add-form');
    },
    handleEditItemClick(operation) {
      this.openSubOperationEditForm(operation.id)
      this.$emit('setActiveFlyout', 'edit-form');
    }
  },
};
