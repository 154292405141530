






























































































import permissionsMixin from "@/mixins/permissions-mixin";
import { getCookie } from "@/utils/helpers";
import SidebarLink from '@/components/shared/sidebar-link.vue';
import routes from '@/router/sidebar-routes';
import superAdminRoutes from '@/router/superadmin-routes';
import { UserRoleType } from '@/enum/userRoleType';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { appEnvironment, appVersion } from "@/utils/constants";

export default {
    mixins: [permissionsMixin],
    components: {
        SidebarLink,
        Avatar
    },
    data() {
        return {
            routes,
            superAdminRoutes,
            appEnv: appEnvironment,
            appVersion: appVersion,
        };
    },
    beforeMount() {
        this.activeRouteIndex = null
    },
    computed: {
        activeRouteIndex: {
            get() {
                return this.$store.state.activeRouteIndex
            },
            set(newValue) {
                this.$store.state.activeRouteIndex = newValue
            }
        },
        showSuperAdminSubMenu: {
            get() {
                return this.$store.state.showSuperAdminSubMenu
            },
            set(newValue) {
                this.$store.state.showSuperAdminSubMenu = newValue
            }
        },
        ownerRole: {
            get() {
                const ownerRole = localStorage.getItem("ownerRole");
                if (ownerRole) {
                    return ownerRole;
                } else {
                    return null;
                }
            },
        },
        loginUserRole: {
            get() {
                return this.$store.getters.loginUserRole;
                // return null;
            },
        },
        disabledNavigation: {
            get() {
                return localStorage.getItem("disabledNavigation");
            },
        },
        visibleRoutes() {
            return this.getVisibleRoutes()
        },
        visibleSuperAdminRoutes() {
            return this.getSuperAdminVisibleRoutes()
        },
        collapsedNavigation() {
            return this.$store.state.collapsedNavigation;
        },
        isSuperAdminLoggedIn() {
            return getLoggedInUserRole().isUserSuperAdminLoggedIn
        },
    },
    watch: {
        $route({path}) {
            const pathIncludesSuperAdminPaths = ['/overview', '/users', '/businesses']
            
            if(!pathIncludesSuperAdminPaths.includes(path)) {
                this.activeRouteIndex = this.visibleRoutes.findIndex((item, index) => path.includes(item.route));
            } else {
                this.showSuperAdminSubMenu = true
                switch(path) {
                    case '#':  this.showSuperAdminSubMenu = !this.showSuperAdminSubMenu; break;
                    case '/overview':  this.activeRouteIndex = this.routes.length + 1; break; 
                    case '/users': this.activeRouteIndex = this.routes.length + 2; break; 
                    case '/businesses': this.activeRouteIndex = this.routes.length + 3; break; 
                    default: return;
                }
            }
        }
    },
    methods: {
        getSuperAdminVisibleRoutes() {
            return this.superAdminRoutes
        },
        getVisibleRoutes() {
            this.routes = this.routes.filter(route => this.checkRenderCondition(route))

            return this.routes
        },

        getLoggedInUserRole() {
            return localStorage.getItem("fullOwnerBusinessName") ? localStorage.getItem("fullOwnerBusinessName") : UserRoleType[parseInt(JSON.parse(getCookie("role")))];
        },
        checkRenderCondition({renderCondition}) {
            if (!renderCondition) return true;

            const { primaryRole, secondaryRole } = renderCondition;
            if (this.loginUserRole == UserRoleType[primaryRole]) return true;
            if (this.loginUserRole == UserRoleType[secondaryRole] && this.ownerRole == primaryRole) return true;

            return false;
        },
        updateActiveRouteIndex(index) {
            this.activeRouteIndex = index;
        },
        updateSuperAdminActiveRouteIndex(index) {

            switch(index) {
                case 0:  this.showSuperAdminSubMenu = !this.showSuperAdminSubMenu; 
                         this.activeRouteIndex = this.routes.length;
                         break;
                case 1:  
                case 2:  
                case 3:  this.activeRouteIndex = this.routes.length + index; break
            }
        },
        handleCollapse() {
            this.$store.commit("setCollapsedNavigation", !this.collapsedNavigation);
        },
        formatEnvironmentInfo(env) {
          return env?.toUpperCase();
        },
    }
};
