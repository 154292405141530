<template>
  <div>
    <div v-if="teamJobsLoader">
      <div>
        <v-skeleton-loader height="40" type="card"> </v-skeleton-loader>
      </div>
    </div>

    <div v-else>
      <div>
        <div
          class="field-input-label d-flex"
          v-bind:class="{ 'pb-1 pt-2': true }"
          style="justify-content: space-between"
        >
          <div>Tags</div>
          <div
            @click="handleTagsManagement"
            class="cursor-pointer"
            :style="{ color: brandColor }"
          >
            Manage Tags
          </div>
        </div>

        <v-chip-group active-class="primary--text" column>
          <v-chip
            v-for="(tag, index) in teamJobTagsSelected"
            :key="`${tag}${index}`"
            @click:close="removeJobTag(tag)"
            class="job-tag-chip"
            close
            label
            text-color="primary"
            close-icon="mdi-close"
          >
            <span class="tag-text">{{ truncateTag(tag) }}</span>
          </v-chip>
        </v-chip-group>
      </div>
      <div class="d-flex">
        <div class="position-relative flex-grow-1">
          <v-autocomplete
            ref="autoComplete"
            :items="teamJobTagsAvailable"
            v-model="teamJobTagsSelected"
            multiple
            :style="{ minWidth: '100%', maxWidth: '300px' }"
            :placeholder="!teamJobTagsSelected ? 'Select tags' : ''"
            :search-input.sync="search"
            @keydown.enter.prevent="handleEnterKey"
            @change="emitUpdate"
            @blur="handleBlur"
            :disabled="isDisabled"
            outlined
            dense
            flat
            attach
            solo
          >
            <template v-slot:selection="data">
              <div>
                <div style="display: none">{{ data.item }}</div>
              </div>
            </template>
            <template v-slot:no-data>
              <div class="px-4">
                Press <span class="fw-5">Enter</span> to add
              </div>
            </template>
          </v-autocomplete>
          <div v-if="!search" class="position-absolute clickthrough" style="">
            Select or type to add a new tag
          </div>
        </div>
        <div>
          <v-btn
            @click="getTagsFromBusinessConfig"
            :disabled="isDisabled"
            class="ml-2 tags-refresh-btn"
            outlined
            height="40"
          >
            <v-icon color="#888">mdi-refresh</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamJobsGlobalComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-global";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { JobStatusType } from "@/enum/jobStatusType";
import { UserRoleType } from "@/enum/userRoleType";
import { brandColor } from "@/utils/uiconstants";

export default {
  name: "TeamJobTags",
  mixins: [TeamJobsGlobalComputed, TeamJobsCommon],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    maxTagLength: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      search: null,
      menuOpen: false,
      jobStatusType: JobStatusType,
      brandColor,
      UserRoleType,
      maxCharLength: 100,
    };
  },
  watch: {
    teamJobTagsSelected: {
      handler() {
        this.enableTeamJobDiscard();
      },
      deep: true,
    },
  },
  computed: {
    isDisabled() {
      return (
        !this.teamJobViewPreference.tags.isEditable || this.teamJobTagsLoader
      );
    },
    loginUserRole: {
      get() {
        return this.$store.getters.loginUserRole;
      },
    },
  },
  methods: {
    truncateTag(tag) {
      if (tag.length > this.maxCharLength) {
        return tag.substring(0, this.maxCharLength) + '...';
      }
      return tag;
    },
    handleBlur() {
      setTimeout(() => {
        this.search = null;
      }, 100);
    },
    forceCloseMenu() {
      if (this.$refs.autoComplete) {
        this.$refs.autoComplete.isMenuActive = false;
        this.$refs.autoComplete.internalSearch = "";
        this.search = null;
      }
    },
    handleEnterKey() {
      if (this.search && !this.teamJobTagsAvailable.includes(this.search)) {
        this.addNewTag(this.search);
        setTimeout(() => {
          this.forceCloseMenu();
          if (this.$refs.autoComplete && this.$refs.autoComplete.$el) {
            const input = this.$refs.autoComplete.$el.querySelector("input");
            if (input) {
              input.blur();
            }
          }
        }, 50);
      }
    },
    addNewTag(tag) {
      if (tag && !this.teamJobTagsSelected.includes(tag)) {
        this.teamJobTagsSelected.push(tag);
        this.emitUpdate();
      }
    },
    removeTag(tag) {
      const index = this.teamJobTagsSelected.indexOf(tag);
      if (index >= 0) {
        this.teamJobTagsSelected.splice(index, 1);
        this.emitUpdate();
      }
    },
    emitUpdate() {
      this.$emit("input", [...this.teamJobTagsSelected]);
      this.$emit("change", [...this.teamJobTagsSelected]);
    },
    removeJobTag(tag) {
      this.removeTag(tag);
    },
    getTagsFromBusinessConfig() {
      this.$store.dispatch("getTagsForTeamJobs");
    },
    handleTagsManagement() {
      window.open("/account?target=tags", "_blank");
    },
  },
  mounted() {
    this.getTagsFromBusinessConfig();
  },
};
</script>

<style>
.clickthrough {
  pointer-events: none;
  user-select: none;
  top: 2px;
  left: 8px;
  padding: 0.5rem;
  color: #a0a0a0;
  font-size: 14px;
  z-index: 1;
}

.job-tag-chip {
  background: #ebf1fb !important;
  max-width: 100%;
}

.job-tag-chip .tag-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.job-tag-chip .v-chip__close {
  margin-left: 4px !important;
  opacity: 1 !important;
}

.tags-refresh-btn {
  border: 1px solid rgb(215, 215, 215);
}
</style>