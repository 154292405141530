



























































































































import { closeOverlay } from "@/utils/helpers";
import {
  formFieldPb,
  containerLeftPad,
  containerRightPad,
} from "@/utils/uiconstants";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import productsComputedMixin from "@/components/products/products-computed";
import {
  HeaderPrimary,
  SubHeaderPrimary,
  DescriptionPrimary,
} from "@/components/shared/headers/index";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { JobExportTimeUnitType } from "@/enum/jobExportTimeUnitType";

export default {
  name: "TeamJobsExportFlyout",
  components: {
    ListLoader,
    SubHeaderPrimary,
    DescriptionPrimary,
    Button,
    HeaderPrimary,
  },
  data() {
    return {
      formFieldPb,
      containerLeftPad,
      containerRightPad,
      exportableColumnsLoading: false,
      JobExportTimeUnitType,
    };
  },
  mixins: [productsComputedMixin],
  computed: {
    timeUnitTypes: {
      get() {
        const timeUnitsEnum = JobExportTimeUnitType;
        return Object.keys(timeUnitsEnum)
          .map((key) => ({
            value: timeUnitsEnum[key],
            title: key,
          }))
          .filter((unit) => Number.isInteger(unit.value));
      },
    },
    exportableColumns: {
      get() {
        return this.$store.getters.exportableColumns;
      },
      set(val) {
        this.$store.state.teamJobsListModule.exportableColumns = val;
      },
    },
    jobExportLoading: {
      get() {
        return this.$store.getters.jobExportLoading;
      },
      set(val) {
        this.$store.state.teamJobsListModule.jobExportLoading = val;
      },
    },
    isAllExportableColumnsSelected: {
      get() {
        return this.$store.getters.isAllExportableColumnsSelected;
      },
      set(val) {
        this.$store.state.teamJobsListModule.isAllExportableColumnsSelected =
          val;
      },
    },
    exportableColumnTimeType: {
      get() {
        return this.$store.getters.exportableColumnTimeType;
      },
      set(val) {
        this.$store.state.teamJobsListModule.exportableColumnTimeType = val;
      },
    },
    jobExportConfigTitle: {
      get() {
        return this.$store.getters.jobExportConfigTitle;
      },
      set(val) {
        this.$store.state.teamJobsListModule.jobExportConfigTitle = val;
      },
		},
		usersBusinessConfig: {
			get() {
				return this.$store.getters.usersBusinessConfig;
			},
			set(val) {
				this.$store.state.teamJobsListModule.usersBusinessConfig = val;
			},
    },
		jobExportSelectedProfileIndex: {
			get() {
				return this.$store.getters.jobExportSelectedProfileIndex;
			},
			set(val) {
				this.$store.state.teamJobsListModule.jobExportSelectedProfileIndex = val;
			},
		},
  },
  methods: {
    closeJobsExportForm() {
      closeOverlay("exportJobsFormOverlay");
      this.isAllExportableColumnsSelected = false;
    },
    exportJobs() {
      this.$store.dispatch("exportSelectedJobsWithColumns").then((res) => {
        closeOverlay("exportJobsFormOverlay");
        this.$store.commit('clearSnackbarDetails');
      });
    },
    handleSelectAll() {
      if (this.isAllExportableColumnsSelected) {
        this.exportableColumns.forEach((column) => {
          column.isSelected = true;
        });
      } else {
        this.exportableColumns.forEach((column) => {
          column.isSelected = false;
        });
      }
    },
    createNewJobExportProfile() {
      this.$store.dispatch("updateJobExportConfigInBusinessConfig");
    },
		handleJobExportProfileSelection() {
			this.$store.dispatch("populateJobExportConfigFromUserConfig");
		}
  },
};
