import { apiOperationTypes, apiOperations } from '@/utils/endpoints';
import requests from '@/utils/requests';
import { devLogger, getListLengthAbsolute, notify } from '@/utils/helpers';
import buildUrl from 'build-url';
import { Operation } from '@/data/models/Operation';
import { ResponseList } from '@/data/models/ResponseList';
import { OperationType } from '@/data/models/OperationType';
import { ResourceType } from '@/enum/resourceType';
import { Machine } from '@/data/models/Machine';
import { MachineType } from '@/enum/machineType';
import StringConstants from '@/plugins/stringConstants';
import { state } from './mutations';
import ConstantValues from '@/plugins/constantValues';

export const actions = {
    async getOpTypes({ commit, rootState, dispatch }, [includeOperation, includePreference, preferenceValue]) {
         if (includeOperation) {
             commit('setFilterOptions', ["includeOperations", includeOperation]);
         }
         if (includePreference) {
             commit('setFilterOptions', ["includePreference", preferenceValue]);
         }
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiOperationTypes + rootState.tempUrl
        });
        
        const result: ResponseList<OperationType> = await requests.getList<OperationType>(url, {
            rootState, 
            cancellable: true
        });
        return result;
    },

    async getOperationTypes({ state, commit, rootState, dispatch }) {
        if (rootState.listCount == 0) {
            state.operationsLoader = true;
        }
        const result: ResponseList<OperationType> = await dispatch("getOpTypes", [true, true, rootState.enumPreference['NotArchived']]);
        if (result != null && result.value != null) {
            rootState.listCount = result.value.length;
            if (result.value.length > 0) {
                commit('setOperationTypesList', result.value);
            }
            if (rootState.offset == 0 && !rootState.isListRequestCancelled) {
                commit('setNoDataStatus', result.value);
            }
        } else {
            devLogger().log(result);
        }
        state.operationsLoader = false;
    },

    async getOperationCategoriesList({ state, commit, dispatch }) {
        state.subOperationLoader = true;
        const result: ResponseList<OperationType> = await dispatch("getOpTypes", [true, true, 2]);
        if (result != null && result.value.length > 0) {
            commit('setManageOperationsList', result.value);
        } else {
            devLogger().log(result);
        }
        state.subOperationLoader = false;
    },

    async saveOperationCategoriesList({ rootState, state, dispatch }, data: OperationType) {
        state.operationsLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiOperationTypes
        });
        const result: OperationType = await requests.put<OperationType>(url, data);
        if (result != null) {
            state.operationsLoader = false;
            state.operationTypesList = [];
            notify(StringConstants.operationTypeAddSuccessMessage, 'success');
            dispatch('getOperationTypes');
            return result;
        } else {
            state.operationsLoader = false;
        }
    },

    async getOperations({ rootState, state, commit, dispatch }) {
        if (rootState.listCount == 0) {
            state.operationsLoader = true;
        }
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations + '?' + 'Preference=' + rootState.enumPreference['NotArchived']
        });
        const result: ResponseList<Operation> = await requests.getList<Operation>(url, {rootState, cancellable: true});
        if (result != null && result.value.length >= 0) {
            commit('setOperationsList', result.value);
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.value);
            } state.operationsLoader = false;
        } else {
            devLogger().log(result);
        }
    },

    async getSubOperation({ rootState, state, commit }, id) {
        state.subOperationLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations + '/' + id
        });
        const result: Operation = await requests.get<Operation>(url);
        if (result != null) {
            state.subOperationLoader = false;
            commit('setSubOperation', result);
            return result;
        } else {
            state.subOperationLoader = false;
            devLogger().log(result);
        }
    },


    async addSubOperation({ rootState, state, commit }, data) {
        state.addSubOperationLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations
        });
        const result: Operation = await requests.post<Operation>(url, data);
        if (result != null) {
            state.operationsList = [];
            commit('resetPaging');
            commit('setClearFilterOptions');
            notify(StringConstants.subOperationAddedSuccessMessage, 'success');
            return result;
        }
        state.addSubOperationLoader = false;
    },


    async updateSubOperation({ rootState, state, dispatch, commit }, data) {
        state.editSubOperationLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations + '/' + data[0]
        });
        const result = await requests.put<Operation>(url, data[1]);
        if (result != null) {
            state.operationsList = [];
            commit('resetPaging');
            dispatch('getOperations');
            notify(StringConstants.subOperationUpdatedSuccessMessage, 'success');
            return result;
        } else {
            state.editSubOperationLoader = false;
        }
        state.editSubOperationLoader = false;
    },

    async deleteSubOperation({ state, rootState }, id) {
        state.operationsLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations + "/" + id
        });
        const result: boolean = await requests.deleteObject(url);
        if (result) {
            state.operationsLoader = false;
            notify(StringConstants.subOperationDeletedSuccessMessage, 'success');

        } else {
            state.operationsLoader = false;
        }
    },

    async setOperationsFilter({ commit, state }, data) {
        if (state.operationSearchText && state.operationSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.operationSearchText]);
        }
    },

    async setResourcesFilterOptions({ commit, state, dispatch }, {resourceType, paginationOffset}) {
        let search: string;
        switch (resourceType) {
            case ResourceType.Vehicle:
                search = state.subOperationVehiclesSearchText != null ? state.subOperationVehiclesSearchText.trim() : null;
                break;
            case ResourceType.Implement:
                search = state.subOperationImplementsSearchText != null ? state.subOperationImplementsSearchText.trim() : null;
                break;
            case ResourceType.Product:
                search = state.subOperationProductsSearchText != null ? state.subOperationProductsSearchText.trim() : null;
                break;
        }
        if (search != null && search.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
        }
        if (paginationOffset) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, paginationOffset]);
        } else {
            commit('setFilterOptions', [ConstantValues.offsetQuery, ConstantValues.defaultPageOffsetSize]);
        }
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    async getOperationResourcesList({ dispatch, state }, resourceType: ResourceType) {
        let response;
        switch (resourceType) {
            case ResourceType.Vehicle: {
                state.subOperationVehiclesDropdownLoader = true;
                response = await dispatch("getMachines", MachineType.Vehicle);
                if(!state.subOperationVehiclesSearchText) {
                    state.subOperationVehiclesListSize = response?.size;
                }
                state.subOperationVehiclesDropdownLoader = false;
                return response;
            }

            case ResourceType.Implement: {
                state.subOperationImplementsDropdownLoader = true;
                response = await dispatch("getMachines", MachineType.Implement);
                if(!state.subOperationImplementsSearchText) {
                    state.subOperationImplementsListSize = response?.size;
                }
                state.subOperationImplementsDropdownLoader = false;
                return response;
            }

            case ResourceType.Product: {
                state.subOperationProductsDropdownLoader = true;
                response = await dispatch("getProductsList");
                if(!state.subOperationProductsSearchText) {
                    state.subOperationProductsListSize = response?.size;
                }
                state.subOperationProductsDropdownLoader = false;
                return response;
            }
                
            default: return;
        }
    },

    async getSubOperationVehiclesList({ commit, dispatch }) {
        const result: ResponseList<Machine> = await dispatch("getOperationResourcesList", ResourceType.Vehicle);
        if (result != null && result.value.length > 0) {
            commit('setSubOperationVehiclesList', result.value);
        }
        return {data: result}
    },

    async getSubOperationImplementsList({ commit, dispatch }) {
        const result: ResponseList<Machine> = await dispatch("getOperationResourcesList", ResourceType.Implement);
        if (result != null && result.value.length > 0) {
            commit('setSubOperationImplementsList', result.value);
        }
        return {data: result}
    },

    async getSubOperationProductsList({ commit, dispatch }) {
        const result: ResponseList<Machine> = await dispatch("getOperationResourcesList", ResourceType.Product);
        if (result != null && result.value.length > 0) {
            commit('setSubOperationProductsList', result.value);
        }
        return {data: result}
    },

    async seedOperations({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
        path: localStorage.getItem(ConstantValues.ownerId) + '/' + apiOperations
        });
        const result = await requests.postData(url, data);
        return result;
    },
}