import {
    apiLogin,
    apiPermissions,
    apiForgetPassword,
    routeJobs,
    routeOnboard,
    routeSubscriptions,
    apiOwner,
    apiInvitation,
    apiTaxesVat,
    apiTaxesVatDescription,
    apiGlobalCurrency,
    apiDefaultTaxes,
    apiInvoiceConfigutation,
    apiGlobalCountries,
    apiGlobalTimeZones,
    apiTimeSheets,
} from '@/utils/endpoints';
import requests from '@/utils/requests';
import { getCookie, notify, getDateDifferenceInDays } from '@/utils/helpers';
import $ from 'jquery';
import buildUrl from 'build-url';
import router from '@/router/index';
import signalR from '@/services/signalr';
import store from '@/store/index';
import { showFullDashboardView, hideFullDashboardView, disableDashboardNavigation, showTrialInfo, hideTrialInfo, isSystemTrialPeriodOver, disableScrollToConfig } from '@/utils/helpers/layout-helpers';
import { listAction } from '@/enum/listAction';
import { UserRoleType } from '@/enum/userRoleType';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import fieldsCacheService from '@/services/fields-cache-service';

const FieldsCacheService = new fieldsCacheService();

export const actions = {
    checkUserLoggedIn({ state }) {
        const jwt = getCookie("tkn");
        if (jwt) {
            state.loggedIn = true;
        }
        else {
            state.loggedIn = false;
        }
    },

    async login({ state, dispatch, commit }, data: Record<string, unknown>) {
        hideTrialInfo();
        state.loginLoader = true;
        state.hasBusinessConfig = false;
        const url = buildUrl(state.baseUrl, {
            path: apiLogin
        });
        const result = await requests.postDataWithoutHeader(url, data);
        
        disableScrollToConfig();
        if (result[0] != null) {
            state.loginLoader = false;
            state.usersModule.usersLoader = true;
            state.subscriptionsModule.subscriptionPlanLoader = true;
            commit('setClearSubscriptionDetails');
            await dispatch('saveToken', result[0]);
            await dispatch('getOwnerDetails');
            await dispatch('getUserProfileForOnboard').then((userProfileResponse) => {
                dispatch('navigateFlows', userProfileResponse);
            })
        } else {
            state.loginLoader = false;
        }
    },

    async loginAndAcceptInvite({ state, dispatch, commit }, data: Record<string, unknown>) {
        state.loginLoader = true;
        const url = buildUrl(state.baseUrl, {
            path: apiLogin
        });
        const result = await requests.postDataWithoutHeader(url, data);
        state.loginLoader = false;
        if (result && result[0]) {
            showFullDashboardView();
            state.disabledNavigation = false;
            commit('setClearSubscriptionDetails');
            await dispatch('saveToken', result[0]);
            await dispatch('getOwnerDetails');
            commit('loginVerify');
            $('#agd-login-view').hide();
        }
    },

    async navigateFlows({ state, commit, dispatch }, { checkIntegration = false, ...userProfileResponse  }) {
        if (userProfileResponse.emailConfirmed) {
            if (userProfileResponse.roles[0].type == UserRoleType.Manager) {
                state.disabledNavigation = false;
                showFullDashboardView();
                window.location.href = routeJobs
            } else {
                if (userProfileResponse && state.hasBusinessProfile && userProfileResponse.businessProfile.config) {
                    if (userProfileResponse.businessProfile.config.currency &&
                        userProfileResponse.businessProfile.config.timezone) {
                        showFullDashboardView();
                       
                        if (isSystemTrialPeriodOver(userProfileResponse.businessProfile.trialEnd)) {
                           
                            dispatch('getSubscriptionDetails').then((response) => {
                                commit('loginVerify');
                                $('#agd-login-view').hide();
                                if (response) {
                                    localStorage.setItem('subscriptionStatus', response.status);
                                    localStorage.setItem('trialDaysRemaining', getDateDifferenceInDays(response.trailEndOn));
                                    switch (response.status) {
                                        case state.enumSubscriptionStatus['Trialing']:
                                        case state.enumSubscriptionStatus['Active']:
                                            showFullDashboardView();
                                            window.location.href = checkIntegration ? `${routeJobs}?checkIntegration=true` : routeJobs;
                                            break;
                                        default:
                                            showFullDashboardView();
                                            disableDashboardNavigation();
                                            window.location.href = routeSubscriptions;
                                    }
                                } else {
                                    showFullDashboardView();
                                    disableDashboardNavigation();
                                    window.location.href = routeSubscriptions;
                                }
                            });
                        } else {
                            showTrialInfo(userProfileResponse.businessProfile.trialEnd);
                            commit('loginVerify');
                            $('#agd-login-view').hide();
                            showFullDashboardView();
                            window.location.href = checkIntegration ? `${routeJobs}?checkIntegration=true` : routeJobs;
                        }
                    } else {
                        $('#agd-login-view').hide();
                        commit('loginVerify');
                        hideFullDashboardView();
                        window.location.href = routeOnboard
                    }
                } else {
                    $('#agd-login-view').hide();
                    commit('loginVerify');
                    hideFullDashboardView();
                    window.location.href = routeOnboard
                }
            }
        }
        else {
            hideFullDashboardView();
            state.loggedIn = false;
            router.push({
                name: StringConstants.resendEmailVerificationComponentTitle,
                params: { email: userProfileResponse.email }
            });
        }

    },

    async getOwnerDetails({ state, commit }, data: Record<string, unknown>) {
        const url = buildUrl(state.baseUrl, {
            path: apiOwner
        });

        const result = await requests.getData(url);
        if (result) {
            commit('setOwnerDetailsLocal', result.data);
        }
    },

    async sendForgetPasswordRequest({ state, commit }, data: Record<string, unknown>) {
        const url = buildUrl(state.baseUrl, {
            path: apiForgetPassword
        });
        const result = await requests.postDataWithoutHeader(url, data);
        if (result[0] != null) {
            notify(StringConstants.requestSendToEmail, 'success');
            commit('setSentCurrentRequest', true);
            return true;
        }
        return false;
    },

    async sendResetPasswordRequest({ state, dispatch }, data: Record<string, unknown>) {
        const url = buildUrl(state.baseUrl, {
            path: apiForgetPassword
        });
        const result = await requests.putDataWithoutHeader(url, data);
        if (result[0] != null) {
            notify(StringConstants.passwordResetSuccess, 'success');
            dispatch('clearLocalData');
        }
    },

    async saveToken({ state }, data) {

        function getRoleIfExists(receivedRoles = data.userRoles) {
            const acceptedRoles = [UserRoleType.Contractor, UserRoleType.Farmer, UserRoleType.Manager]
            let mappedRole
            
            acceptedRoles.some(ar => {
                if(receivedRoles.includes(ar)) {
                    mappedRole = ar
                }
            })

            return mappedRole
        }

        if (data) {
            const primaryRole = JSON.stringify([getRoleIfExists()])

            document.cookie = "ExpiringAt=" + data.expiringAt + '; max-age=86400; path=/; SameSite=Strict';
            document.cookie = "tkn=" + data.token + '; max-age=86400; path=/; SameSite=Strict';
            document.cookie = "rft=" + data.refreshToken + '; max-age=86400; path=/; SameSite=Strict';
            document.cookie = "expiresIn=" + data.expiresIn + '; max-age=86400; path=/; SameSite=Strict';
            document.cookie = "rft=" + data.refreshToken + '; max-age=86400; path=/; SameSite=Strict';
            document.cookie = "role=" + primaryRole + '; max-age=86400; path=/; SameSite=Strict';
            if (data.ownerId == null) {
                localStorage.setItem('ownerId', data.userId);
            } else {
                localStorage.setItem('ownerId', data.ownerId);
            }

            localStorage.setItem('userId', data.userId);
            localStorage.setItem('ownerRole', data.ownerRole);

            const userRoles = data.userRoles;

            localStorage.setItem('userRole', getRoleIfExists())

            if(userRoles.includes(UserRoleType.SuperAdmin)) {
                localStorage.setItem('isSuperAdmin', '1');
            } else {
                localStorage.setItem('isSuperAdmin', '0');
            }
        }
    },
    async subscribeUser({ state }, id) {
        if (id != null) {
            signalR.fnOpenConnection();
            signalR.fnUnSubscripbe(id);
            const result = await signalR.fnStart();
            if (result == true) {
                await signalR.GetJobAlerts("Job", store, "notifyUserUpdate");
                await signalR.GetJobAlerts("Location", store, "getPolyLineMarkersOnMap");
            }
        }
    },

    async notifyUserUpdate({ state }, data) {
        data = JSON.parse(data.data.data);
        const userId = localStorage.getItem("userId");
        const currentPage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

        if (data.isCreated && currentPage == 'jobs' && checkNoJobFiltersApplied()) {
            state.teamJobsListModule.teamJobsDataListOffset += 1;
            state.teamJobsListModule.teamJobsDataListTotal += 1;
            state.teamJobsListModule.teamJobsDataListLoaded += 1;
            state.totalRowCount += 1;
            state.listCount += 1;
            state.noData = false;

            if (state.teamJobsListModule.teamJobsList.length == 0) {
                state.teamJobsListModule.teamJobsList.push(data.data);
            } else {
                state.teamJobsListModule.teamJobsList.unshift(data.data);
            }
        }
        else if (!data.isCreated && currentPage == 'jobs' && userId != data.requesterId) {
            if (state.teamJobsListModule.teamJobsList) {
                state.teamJobsListModule.teamJobsList = state.teamJobsListModule.teamJobsList.filter(x => x.id != data.data.id);
                state.teamJobsListModule.teamJobsList.splice(0, 0, data.data);
            }
        }
        else if (data) {
            if (userId != data.requesterId) {
                state.teamJobDetailsUpdatedRealTime = true;
                state.teamJobsModule.teamJobDetailsRealtime = data.data;
            }
        }
        function checkNoJobFiltersApplied() {
            return state.teamJobsListModule.teamJobsListSearchText.length == 0 && 
                   !state.teamJobsListModule.teamJobsDateFilterApplied && 
                   !state.teamJobsListModule.teamJobsListFilterStartDate && 
                   !state.teamJobsListModule.teamJobsListFilterEndDate;
        }
    },

    async jsonToQueryString({ commit }, obj) {
        let paramsUrl = "?";
        $(obj).each(function () {
            paramsUrl = paramsUrl + encodeURIComponent(this[0]) + "=" + encodeURIComponent(this[1]) + "&";
        });
        paramsUrl = paramsUrl.substring(0, paramsUrl.length - 1);
        commit("setTempUrl", paramsUrl);
    },

    async clearLocalData({ commit }) {
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        const currentVersion = localStorage.getItem('version');
        localStorage.clear();
        if (currentVersion) {
            localStorage.setItem('version', currentVersion);
        }
        commit('setClearSubscriptionDetails');
        await signalR.fnStopConnection();

        commit('loggedOut');
    },

    async deleteCachedData({ state }) {
        FieldsCacheService.deleteFieldsCacheDatabase();
    },

    async logout({ dispatch, commit }) {
        dispatch("clearLocalData");
        commit('clearUserProfile');
        dispatch('deleteCachedData');
    },

    async getPermissions({ state, commit }) {
        if (state.loggedIn == true && state.isListBind == true) {
            return true;
        } else if (state.loggedIn == true && state.isListBind == false) {
            state.isListBind = true;
            state.permissionsList = [];
            const url = buildUrl(state.baseUrl, {
                path: apiPermissions
            });
            const result = await requests.getData(url);

            if (result != null) {
                if (result.data != null && result.data.length != 0) {
                    commit('setPermissionsList', result.data.map(permission => permission['title']));
                    state.isPermissionsSet = true;
                }
            }
        }
    },
    addRequest({ state, commit }, req) {
        commit("setAddRequest", req);
    },
    getTestData({ state }, data) {
        state.noData = true;
    },
    getHoursList({ commit }) {
        const start = 12;
        const end = 0
        for (let i = end; i <= start; i++) {
            commit('setHoursList', {
                hour: i
            });
        }
    },
    getMinutesList({ commit }) {
        const start = 59;
        const end = 0
        for (let i = end; i <= start; i++) {
            commit('setMinutesList', {
                minute: i
            });
        }
    },
    async getProductUnits({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: '/resources/product_units'
        });
        const result = await requests.getData(url);
        if (result != null) {
            commit('setProductUnits', result.data);
        }
    },
    async getLoadInfoUnits({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: '/resources/loadinfo_units'
        });
        const result = await requests.getData(url, true);
        if (result != null) {
            commit('setLoadInfoUnits', result.data);
        }
    },
    async getOperationUnits({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: '/resources/operation_units'
        });
        const result = await requests.getData(url, true);
        if (result != null) {
            commit('setOperationUnits', result.data);
        }
    },
    async getInvoiceConfiguration({rootState, commit}) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvoiceConfigutation
        });
        const result = await requests.getData(url, true);
        if (result?.data) {
            commit('setInvoiceConfiguration', result.data);
            return result.data;
        }
    },
    async setDeleteFilterOptions({ commit }, data) {
        data.map((item) => {
            commit('setFilterOptions', [ConstantValues.queryIdentifiers, item]);
        });
    },
    async setArchiveFilterOptions({ commit }, data) {
        data.map((item) => {
            commit('setFilterOptions', [ConstantValues.queryIdentifiers, item]);
        })
    },
    async setRestoreFilterOptions({ commit }, data) {
        data.map(item => {
            commit('setFilterOptions', [ConstantValues.queryIdentifiers, item])
        })
    },

    async actionOnMultilselectItems({ dispatch }, data) {

        const actionType: listAction = data[0];
        const endpoints = data[1];
        const preference = data[2];

        switch (actionType) {
            case listAction.Delete:
                await dispatch("deleteMultiSelectedItems", [endpoints, preference]);
                break;
            case listAction.Restore:
                await dispatch("restoreMultiSelectedItems", [endpoints, preference]);
                break;
            case listAction.Archive:
                await dispatch("archiveMultiSelectedItems", [endpoints, preference]);
                break;
        }
    },

    async deleteMultiSelectedItems({ rootState, dispatch, commit }, data) {
        let url = null;
        dispatch("jsonToQueryString", rootState.filterOptions);
       
        url = buildUrl(rootState.baseUrl, {
            path: data[0] + rootState.tempUrl
        });
    

        if (data[0].includes(apiTimeSheets)) {
            if (rootState.timeSheetsModule.timesheetListFilterType)
                url += "&Type=" + rootState.timeSheetsModule.timesheetListFilterType
            
            if (rootState.timeSheetsModule.timesheetsSelectedMemberId)
                url += "&UserId=" + rootState.timeSheetsModule.timesheetsSelectedMemberId
            
            if (rootState.timeSheetsModule.timesheetsFilterStartDate)
                url += "&Start=" + rootState.timeSheetsModule.timesheetsFilterStartDate
            
            if (rootState.timeSheetsModule.timesheetsFilterEndDate)
                url += "&End=" + rootState.timeSheetsModule.timesheetsFilterEndDate
        }

        rootState.bulkActionLoader = true;
        const result = await requests.deleteData(url);
        rootState.bulkActionLoader = false;
        if (result != null && 'data' in result) {
            commit('clearSnackbarDetails');
            notify(StringConstants.itemDeletedSuccessfully, 'success');
        } 
    },

    async archiveMultiSelectedItems({ rootState, dispatch, commit }, data) {
        let url = null;

        dispatch("jsonToQueryString", rootState.filterOptions);

        url = buildUrl(rootState.baseUrl, {
            path: data[0] + "/archive" + rootState.tempUrl
        });

        rootState.bulkActionLoader = true;
        const result = await requests.postData(url, ``);
        rootState.bulkActionLoader = false;

        if (result[0] === '') {
            commit('clearSnackbarDetails');
            notify(StringConstants.itemArchivedSuccessfully, 'success');
        }
    },

    async restoreMultiSelectedItems({ rootState, dispatch, commit }, data) {
        let url = null;

        dispatch("jsonToQueryString", rootState.filterOptions);

        url = buildUrl(rootState.baseUrl, {
            path: data[0] + "/restore" + rootState.tempUrl
        });

        rootState.bulkActionLoader = true;
        const result = await requests.postData(url, ``);
        rootState.bulkActionLoader = false;

        if (result[0] === '') {
            commit('clearSnackbarDetails');
            notify(StringConstants.itemRestoreSuccessfully, 'success');
        }
    },

    async deleteMultiSelectedWithoutOwnerId({ rootState, dispatch, commit }, apiUrl) {
        let url = null;
        dispatch("jsonToQueryString", rootState.filterOptions);

        url = buildUrl(rootState.baseUrl, {
            path: apiUrl + rootState.tempUrl
        });

        rootState.bulkActionLoader = true;
        const result = await requests.deleteData(url);
        rootState.bulkActionLoader = false;

        if (result != null && 'data' in result) {
            commit('clearSnackbarDetails');
            notify(StringConstants.itemDeletedSuccessfully, 'success');
        }
    },

    async acceptUserInvite({ state, rootState }, code) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + '/' + code + '/' + 'accept'
        });
        const result = await requests.postData(url, null);
        if (result[0] != null) {
            state.usersLoader = false;
            notify(StringConstants.userInviteAcceptSuccessMessage, 'success');
            return true;
        } else {
            if (result[1].response.data.message != null) {
                notify(result[1].response.data.message, 'fail');
            }
            else {
                notify(StringConstants.userInviteAcceptFailMessage, 'fail');
            }
            state.usersLoader = false;
            return false;
        }
    },

    async declineUserInvite({ state, rootState }, code) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + '/' + code + '/' + 'decline'
        });
        const result = await requests.postData(url, null);
        if (result[0] != null) {
            notify(StringConstants.userInviteDeclineSuccessMessage, 'success');
            state.usersLoader = false;
            return true;
        }
        else {
            if (result[1].response.data.message != null) {
                notify(result[1].response.data.message, 'fail');
            }
            else {
                notify(StringConstants.userInviteDeclineFailSuccessMessage, 'fail');
            }
            state.usersLoader = false;
            return false;
        }
    },

    async deleteSentConReq({ state, rootState }, code) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + '/' + code
        });
        const result = await requests.deleteData(url, true);
        if (result != null && 'data' in result) {
            notify(StringConstants.connectionRequestDeleted, 'success');
            state.usersLoader = false;
            return true;
        }
        else {
            if (result[1].response.data.message != null) {
                notify(result[1].response.data.message, 'fail');
            }
            else {
                notify(StringConstants.connectionRequestFailedToDelete, 'fail');
            }
            return false;
        }

    },

    async getGlobalCurrenciesList({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiGlobalCurrency
        });
        const result = await requests.getData(url);
        if (result != null) {
            commit('setGlobalCurrenciesList', result.data);
        }
    },

    async getGlobalTimeZonesList({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiGlobalTimeZones
        });
        const result = await requests.getData(url);
        if (result != null) {
            commit('setGlobalTimeZonesList', result.data);
        }
    },

    async getDefaultTaxesNameList({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiDefaultTaxes
        });
        const result = await requests.getData(url);
        if (result != null) {
            commit('setGlobalTaxesNameList', result.data);
        }
    },
    async getGlobalCountriesList({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiGlobalCountries
        });
        const result = await requests.getData(url, true);
        if (result != null) {
            commit('setGlobalCountriesList', result.data);
        }
    },
    async updateOffsetAndLimitValues({state}, [offsetValue, limitValue]) {
        const options = state.filterOptions;
        const updatedOptions = options.slice();

        const offsetIndex = updatedOptions.findIndex(option => option[0] === "Offset");
        const limitIndex = updatedOptions.findIndex(option => option[0] === "Limit");

        if (offsetIndex !== -1 && limitIndex !== -1) {
            updatedOptions[offsetIndex][1] = offsetValue;
            updatedOptions[limitIndex][1] = limitValue;
        } else {
            updatedOptions.push(["Offset", offsetValue]);
            updatedOptions.push(["Limit", limitValue]);
        }

        state.filterOptions = updatedOptions;

        return state.filterOptions;
    },
    async appendLoadedItemsToSelections({ state, getters }, items) {
        if (state.selectedItems.length === 0) return;

        if (getters.isAllItemsSelected) {
            state.selectedItems = [...state.selectedItems, ...items.map(item => item.id)];
        }
    }
}
