import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import { getPageParam } from '@/utils/helpers/jobs-helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobViewPreference',
        'teamJobDetails',
        'teamJobTimeDetailsList',
        'teamJobTempTimeDetailsList',
        'attachmentDeleteInProgress',
        'teamJobsLoader',
        'teamJobsAddLoader',
        'hasTeamJobChangesOnDetails',
        'teamJobInvoiced',
        'teamJobDetailsRealtime',
        'teamJobDetailsUpdatedSnackbarVisible',
        'teamJobDisabledAllResources',
        'isAllOperatorsSelected',
        'teamJobSelectedOperators',
        'teamJobSelectedOperatorsHours',
        'isAllTimeRecordsSelected',
        'teamJobSelectedTimeRecords',
        'teamJobSelectedTimeRecordsHours',
        'teamJobTagsAvailable',
        'teamJobTagsSelected',
        'teamJobTagsLoader',
        'hasUserEditedInstructions',
        'isExternalInstructionsUpdate',
    ],
    'jobsInvoicesModule': [
        'generatedFromJobDetails',
        'invoiceReportGenerationInProgress',
    ],
    'root': [
        'dashboardTitle',
        'showImagePreview',
        'tempImageUrl',
        'loginUserRole',
        'hasMoreData',
        'teamJobDetailsUpdatedRealTime',
        'globalCountriesList',
        'isJobBeingAdded'
    ]
}

export default {
    name: 'TeamJobsGlobalComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        getSenderId() {
            return getOwnerId();
        },
        loginOwnerIsContractor() {
            return getLoggedInUserRole().isUserOwnerContractorLoggedIn;
        },
        isDisabled() {
            if (this.teamJobUser && this.teamJobOperation && this.teamJobOperation.id) {
                return false;
            }
            else {
                return true;
            }

        },
        jobResourceId: {
            get() {
                return getPageParam();
            }
        },
        isDetailsView() {
            if (getPageParam() != 'add' && !this.isJobBeingAdded) {
                return true;
            }
            else {
                return false;
            }
        },
        ownerRole: {
            get() {
                return localStorage.getItem('ownerRole');
            }
        },
        isFarmerLogInAndExteranlJobAndJobStarted(){
            return getLoggedInUserRole().isUserOwnerFarmerLoggedIn && this.teamJobDetails.customer.id != this.teamJobDetails.contractor.id && this.teamJobDetails.status >= TeamJobStatusType.InProgress
        },
        isFarmerLogInAndSelfJobAndJobStarted(){
            return getLoggedInUserRole().isUserOwnerFarmerLoggedIn && this.teamJobDetails.customer.id == this.teamJobDetails.contractor.id && this.teamJobDetails.status >= TeamJobStatusType.InProgress
        }
    }
}