<template>
  <div class="seed-action-button py-3">
    <v-btn 
      @click="$emit('click')"
      :disabled="isDisabled"
      color="primary"
      outlined
    >
      {{ textValue }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    textValue: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
