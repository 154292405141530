

































import $ from "jquery";
import {mapMutations} from "vuex";
import { defaultScrollViewHeight } from '@/utils/uiconstants';
import {ButtonLoadMore} from "@/components/shared/buttons";
import {SkeletonLoader, EllipsisLoader} from "@/components/shared/loaders";
import skeletonLoaderMixin from "@/mixins/skeleton-loader-mixin";
import ConstantValues from '@/plugins/constantValues';
import Observer from '@/mixins/observer';

export default {
    name: "List",
    mixins: [skeletonLoaderMixin, Observer],
    components: {
        ButtonLoadMore, 
        SkeletonLoader, 
        EllipsisLoader
    },
    props: {
        containsAction: Boolean,
        listItems: Array,
        externalList: Boolean,
        moduleNamespace: String,
        listHeaderOffset: Number,
        preserveListState: Boolean,
        noHeader: Boolean,
        type: String
    },
    computed: {
        noData() {
            if (this.moduleNamespace)
                return this.$store.state[this.moduleNamespace].noData
            return this.$store.getters.noData
        },
        listCount() {
            if (this.moduleNamespace)
                return this.$store.state[this.moduleNamespace].listCount || this.$store.state[this.moduleNamespace].responseRowCount
            return this.$store.getters.listCount
        },
        listLoading() {
            return this.$store.getters.listLoading
        },
        isListRequestCancelled() {
            return this.$store.getters.isListRequestCancelled
        },
        offset() {
            if (this.moduleNamespace)
                return this.$store.state[this.moduleNamespace].offset
            return this.$store.getters.offset
        },
        limit() {
            if (this.moduleNamespace)
                return this.$store.state[this.moduleNamespace].limit
            return this.$store.getters.limit
        },
        totalRowCount: {
            get() {
                if (this.moduleNamespace)
                    return this.$store.state[this.moduleNamespace].totalRowCount
                return this.$store.getters.totalRowCount
            },
            set(newVal) {
                if (this.moduleNamespace)
                    return this.$store.state[this.moduleNamespace].totalRowCount
                this.$store.state.totalRowCount = newVal
            }
        },
        loadedListLength() {
            return this.listItems.length
        },
        filtersApplied() {
            return this.$store.getters.filtersApplied
        },
        hasListItemListener() {
            return this.$listeners && 'listItemClick' in this.$listeners
        },
        isSkeletonLoaderVisible() {
            return this.listLoading && this.listCount == 0 && this.columnWidths.length > 0
        },
        isLoadMoreVisible() {
            return !this.listLoading && this.listCount !== 0 && this.loadedListLength < this.totalRowCount
        },
        isEllipsisLoaderVisible() {
            if (this.moduleNamespace) {
                return this.$store.state[this.moduleNamespace].responseRowCount && this.listCount !== 0 && this.loadedListLength < this.totalRowCount
            }
            return this.listLoading && this.listCount !== 0 && this.loadedListLength < this.totalRowCount
        },
        shouldListLoadMoreItems() {
            if (this.externalList) {
                return this.listCount !== 0 && !(this.listCount < this.limit)
            }
            return this.loadedListLength < this.totalRowCount && this.listCount <= this.limit
        },
    },
    watch: {
        loadedListLength() {
            const target = this.$refs.loadMoreRef;
            if (this.loadedListLength == 0) {
                return this.observer?.unobserve(target);
            }
            if (this.externalList) {
                return this.observer?.observe(target);
            }
            if (this.loadedListLength === this.totalRowCount) {
                this.observer?.unobserve(target);
            } else if (this.loadedListLength < this.totalRowCount) {
                this.observer?.observe(target);
            }
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        handleScroll() {
            if (!this.listLoading && parseInt($(".list-content").innerHeight().toString()) - $(".scroll-view").scrollTop() <= defaultScrollViewHeight) {
                this.$emit('clearListFilterOptions');
                if (this.listCount > 0 && this.loadedListLength < this.totalRowCount) {
                    this.loadMoreItems();
                }
            }
        },
        loadMoreItems() {
            let $state;
            if (this.moduleNamespace) {
                $state = this.$store.state[this.moduleNamespace]
            } else {
                $state = this.$store.state;
            }
            if (this.loadedListLength < this.totalRowCount) {
                this.clearFilterOptions();
                $state.offset = $state.offset + $state.listCount || $state.responseRowCount;
                this.$emit('setFilterOptionsAndGetListData', [$state.offset, $state.limit])
            }
        },
        initializeLoadMoreObserver(callback) {
            const target = this.$refs.loadMoreRef
            this.initializeIntersectionObserver(target, callback);
        },
        scrollToSavedListItem() {
            if (this.preserveListState && this.$store.state.scrollToItem) {
                const listContainer = this.$refs.listScrollView;
                const listHeader = this.$refs.listHeader;
                const listFilters = document.querySelector('.list-filters');
                const listItem:any = document.querySelector(`[data-id="${this.$store.state.scrollToItem}"]`);
                if (listItem) {
                    const scrollBy = listItem.offsetTop - (listHeader.clientHeight + listFilters.clientHeight + listItem.clientHeight - 48);
                    listContainer.scrollTo({top: scrollBy, behavior: 'smooth'})
                }
            }
        }
    },
    mounted() {
        // this.totalRowCount = ConstantValues.defaultPageTotalRowCount;
        this.calculateColumnWidths(this.$refs.listHeader);
        this.initializeLoadMoreObserver(this.loadMoreItems);
        this.scrollToSavedListItem();
    },
    beforeDestroy() {
        if (!this.preserveListState){
            this.resetPaging();
        }
    }
}
