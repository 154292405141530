



































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { wysiwygEditorConfig } from '@/utils/uiconstants';
import { VueEditor } from "vue2-editor";

    export default {
        name: "TeamJobsInstructions",
        data() {
            return {
                customToolbar: wysiwygEditorConfig,
                editorInstance: null 
            }
        },
        watch: {
            tempTeamJobInstructions: {
                handler(newValue) {
                    if (newValue !== this.teamJobInstructions) {
                        const parsedValue = this.autoLinkUrls(newValue);
                        this.tempTeamJobInstructions = parsedValue;
                        this.teamJobInstructions = parsedValue;
                    }
                },

                immediate: true,
            },
        },
        mixins: [TeamJobsComputed, TeamJobsCommon],
        methods: {
            onChangeInstruction(delta, oldDelta, source) {
                if (!this.isExternalInstructionsUpdate) {
                    this.enableTeamJobDiscard();
                    this.hasUserEditedInstructions = true;
                }
            },
            handleKeyDown() {
                this.hasUserEditedInstructions = true;
                this.enableTeamJobDiscard();
            },
            setupEditorKeyboardListener() {
                if (this.$refs.editor && this.$refs.editor.quill) {
                    this.editorInstance = this.$refs.editor.quill;
                    this.editorInstance.root.addEventListener('keydown', this.handleKeyDown);
                }
            }
        },
        components: {
            VueEditor
        },
        mounted() {
            this.hasUserEditedInstructions = false;
            this.$nextTick(this.setupEditorKeyboardListener);
        },
        beforeDestroy() {
            if (this.editorInstance && this.editorInstance.root) {
                this.editorInstance.root.removeEventListener('keydown', this.handleKeyDown);
            }
            this.hasUserEditedInstructions = false;
        }
    }
