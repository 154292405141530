import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/components/login/login.vue";
import ForgetPassword from "@/components/login/forget-password.vue";
import ResetPassword from "@/components/login/reset-password.vue";
import SignUp from "@/components/signup/signup.vue";
import ResendEmailVerification from "@/components/signup/resend-email-verification.vue";
import JobsList from "@/components/team-jobs/team-jobs-list/team-jobs-list.vue";
import Products from "@/components/products/products.vue";
import Vehicles from "@/components/machines/machines.vue";
import Farmers from "@/components/farmers/farmers.vue";
import Fields from "@/components/fields/fields.vue";
import Implements from "@/components/machines/machines.vue";
import Operations from "@/components/operations/operations.vue";
import Contractors from "@/components/contractors/contractors.vue";
import TeamMembers from "@/components/team-members/team-members.vue";
import Timesheets from "@/components/timesheets/timesheets.vue";
import Overview from "@/components/su-dashboard/su-overview.vue";
import Users from "@/components/su-users/su-users.vue";
import Businesses from "@/components/su-businesses/su-businesses.vue";
import BusinessesDetails from "@/components/su-businesses/su-businesses-details.vue";
import UsersInviteeProfile from "@/components/users/users-invitee-profile.vue";
import Subscriptions from "@/components/subscriptions/subscriptions.vue";
import Invoices from '@/components/invoices/invoices.vue';
import InvoicesAdd from '@/components/invoices/invoices-add/invoices-add.vue';
import InvoicesAddExternal from '@/components/invoices-external/invoices-external-add/invoices-external-add.vue';
import Integrations from '@/components/Integrations/integration-tabs.vue';
import AddInternalContact from '@/components/internal-contact/internal-contact-add.vue';
import UpdateInternalContact from '@/components/internal-contact/internal-contact-update.vue';
import DetailsInternalContact from '@/components/internal-contact/internal-contact-details.vue';
import InvoicesDetails from '@/components/invoices/invoices-details/invoices-details.vue';
import Accounts from '@/components/account/account.vue';
import Onboard from '@/components/onboard/onboard.vue';
import SeedData from '@/components/seed/seed-data.vue';
import TeamJobsAddView from '@/components/team-jobs/team-jobs-add/team-jobs-add.vue';
import TeamJobsDetailsView from '@/components/team-jobs/team-jobs-details/team-jobs-details.vue';
import JobsReport from '@/components/team-jobs/team-jobs-details/team-jobs-details-report.vue';
import CalendarView from '@/components/calendar/calendar-view.vue';
import store from "@/store/index";
import request from "@/utils/requests";
import { getCookie } from "@/utils/helpers";
import {
    routeOnboard,
    routeRoot,
    routeLogin,
    routeSignUp,
    routeResendEmailVerification,
    routeJobs,
    routeFields,
    routeFieldsAdd,
    routeFieldsEdit,
    routeOperations,
    routeTimesheets,
    routeTeamMembers,
    routeContractors,
    routeCustomers,
    routeVehicles,
    routeImplements,
    routeProducts,
    routeForgetPassword,
    routeResetPassword,
    routeSubscriptions,
    routeInvoices,
    routeInvoicesAdd,
    routeInvoicesAddExternal,
    routeQuickBooks,
    routeXero,
    routeSage,
    routeContactAdd,
    routeCustomersUpdate,
    routeCustomersDetails,
    routeAccount,
    routeSeed,
    routeTeamJobsAdd,
    routeTeamJobsDetails,
    routeInvoicesDetails,
    routeJobsReport,
    routeOverview,
    routeUsers,
    routeBusinesses,
    routeBusinessesDetails,
    routeTeamJobDuplicate
} from '@/utils/endpoints';
import { checkAppVersion, hideFullDashboardView, showFullDashboardView } from "@/utils/helpers/layout-helpers";
import { UserRoleType } from "@/enum/userRoleType";
import StringConstants from "@/plugins/stringConstants";

Vue.use(VueRouter);

function checkInvitationCode(to, form, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const invitationCode = urlParams.get('code');
    const invitationType = urlParams.get('type');
    if (invitationCode != null && invitationCode.length != 0 && invitationType == 'Invitation') {
        next({
            path: '/users/invitee/profile/' + invitationCode,
        });
        return true;
    }
    return false;
}

function checkVerificationCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const invitationType = urlParams.get('type');
    if (invitationType == 'Verification') {
        return true;
    }
    return false;
}

async function checkTokenValidity(to, from, next) {
    const tkn = getCookie('tkn');
    if (tkn && checkVerificationCode()) {
        next();
    }
    if (tkn) {
        if (!checkInvitationCode(to, from, next)) {
            next({
                path: from.fullPath,
            });
        }
    }
    else {
        next();
    }
}

async function checkLoginStatus(to, from, next) {
    const jwt = getCookie('tkn');
    if (jwt) {
        alert('Please log out before signing up for a new account!');
        next({
            path: routeJobs,
            query: { redirect: to.path }
        });
    }
    else {
        next();
    }
}

async function canAccessSeed(to, from, next) {
    if (process.env.NODE_ENV !== 'production' && requireAuth(to, from, next)) {
        next();
    } else {
        alert('Access denied!');
        next({
            path: '/',
        });
    }
}

async function checkSignupStatus(to, from, next) {
    next();
}

async function logoutUser(to, from, next) {
    const jwt = getCookie('tkn');
    if (jwt) {
        alert('Please log out before reset your password!');
        next({
            path: routeJobs,
            query: { redirect: to.path }
        });
    }
    else {
        next();
    }
}

function isNextOrPreviousPageRelated(nextPageUrl, previousPageUrl) {
    const nextPageBase = nextPageUrl.split("/")[1];
    const previousPageBase = previousPageUrl.split("/")[1];
    const routedFromJobDetailsToJob = !nextPageBase.trim() && previousPageUrl.includes(routeTeamJobsDetails);

    const pagesHaveParentChildRelation = (nextPageBase == previousPageBase) || routedFromJobDetailsToJob;
    const isSamePageReloading = previousPageBase == '';
    const routedFromReferencePage = store.state.isRoutedFromReferencePage;
    if ((pagesHaveParentChildRelation ||
        routedFromReferencePage) && !isSamePageReloading) {
            store.state.isPageRelated = true;
        } else {
            store.state.isPageRelated = false;
        }
}

function checkParamsPreserve(to, from, next) {
    isNextOrPreviousPageRelated(to.path, from.path);
}

async function requireAuth(to, from, next) {
    try {
        const rootStore = store;
        if (document.cookie != undefined && document.cookie != "") {
            const jwt = getCookie('tkn');
            if (jwt) {

                const result = await request.checkUrl(rootStore);
                if (result && result[0]) {
                    //reset different routes local storage
                    checkParamsPreserve(to, from, next);

                    //await store.dispatch('subscribeUser', localStorage.getItem('userId'));
                    if (!rootStore.state.isPermissionsSet) {
                        await rootStore.dispatch('getPermissions');
                    }
                    await rootStore.commit('setLoginUserRole');


                    if (rootStore && rootStore.state) {
                        if(rootStore.state.hasBusinessProfile && rootStore.state.loginUserRole != UserRoleType.Manager) {
                            const _businessProfile = result[1].businessProfile;

                            const currency = _businessProfile?.config?.currency;
                            const timezone= _businessProfile?.config?.timezone;
                           
                            if (rootStore.state.hasBusinessProfile && currency && timezone) {
                                rootStore.state.showAllDashboardViews = true;
                                showFullDashboardView();
                                next();
                            } else {
                                hideFullDashboardView();
                                window.location.href = routeOnboard;
                            }
                        } else if (rootStore.state.loginUserRole == UserRoleType.Manager) {
                            next();
                        } else {
                            hideFullDashboardView();
                            window.location.href = routeOnboard;
                        }
                    } else {
                        next();
                    }
                    next();
                }
                else {
                    if (result && result[0] == false) {
                        await rootStore.dispatch('logout')
                    } else {
                        next({
                            path: routeLogin,
                            query: { redirect: to.path }
                        });
                    }
                }
            }
            else {
                await rootStore.dispatch('logout');
                next({
                    path: routeLogin,
                    query: { redirect: to.path }
                });
            }
        }
        else {
            next({
                path: routeLogin,
                query: { redirect: to.path }
            });
        }
    }
    catch (e) {
        next({
            path: routeLogin,
            query: { redirect: to.path }
        });
    }
}

async function requireAuthOnboard(to, from, next) {
    try {
        if (document.cookie != undefined && document.cookie != "") {
            const jwt = getCookie('tkn');
            if (jwt) {
                const result = await request.checkUrl(store);
                if (result) {
                    next();
                }
                else {
                    next({
                        path: routeLogin,
                        query: { redirect: to.path }
                    });
                }
            }
            else {
                await store.dispatch('logout');
                next({
                    path: routeLogin,
                    query: { redirect: to.path }
                });
            }
        }
        else {
            next({
                path: routeLogin,
                query: { redirect: to.path }
            });
        }
    }
    catch (e) {
        next({
            path: routeLogin,
            query: { redirect: to.path }
        });
    }
}

const routes: Array<RouteConfig> = [
    {
        path: routeSignUp,
        name: StringConstants.signUpComponentTitle,
        component: SignUp,
        beforeEnter: checkLoginStatus
    },
    {
        path: routeResendEmailVerification,
        name: StringConstants.resendEmailVerificationComponentTitle,
        component: ResendEmailVerification,
        beforeEnter: checkSignupStatus
    },
    {
        path: routeForgetPassword,
        name: StringConstants.forgetPasswordComponentTitle,
        component: ForgetPassword,
    },
    {
        path: routeResetPassword,
        name: StringConstants.resetPasswordComponentTitle,
        component: ResetPassword,
        beforeEnter: logoutUser
    },
    {
        path: routeLogin,
        name: StringConstants.loginComponentTitle,
        component: Login,
        beforeEnter: checkTokenValidity
    },
    {
        path: routeRoot,
        name: StringConstants.homeComponentTitle,
        component: JobsList,
        beforeEnter: requireAuth
    },
    {
        path: routeJobs,
        name: StringConstants.jobsComponentTitle,
        component: JobsList,
        beforeEnter: requireAuth
    },
    {
        path: routeFields,
        name: StringConstants.fieldsComponentTitle,
        component: Fields,
        beforeEnter: requireAuth,
        alias: [
            routeFieldsAdd,
            routeFieldsEdit + '/:ownerId/:fieldId'
        ]
    },
    {
        path: routeOperations,
        name: StringConstants.operationsComponentTitle,
        component: Operations,
        beforeEnter: requireAuth
    },
    {
        path: routeTimesheets,
        name: StringConstants.timesheetsComponentTitle,
        component: Timesheets,
        beforeEnter: requireAuth
    },
    {
        path: routeTeamMembers,
        name: StringConstants.teamMembersComponentTitle,
        component: TeamMembers,
        beforeEnter: requireAuth
    },
    {
        path: routeContractors,
        name: StringConstants.contractorsComponentTitle,
        component: Contractors,
        beforeEnter: requireAuth
    },
    {
        path: routeCustomers,
        name: StringConstants.farmersComponentTitle,
        component: Farmers,
        beforeEnter: requireAuth
    },
    {
        path: routeVehicles,
        name: StringConstants.vehiclesComponentTitle,
        component: Vehicles,
        beforeEnter: requireAuth
    },
    {
        path: routeImplements,
        name: StringConstants.implementsComponentTitle,
        component: Implements,
        beforeEnter: requireAuth
    },
    {
        path: routeProducts,
        name: StringConstants.productsComponentTitle,
        component: Products,
        beforeEnter: requireAuth
    },
    {
        path: '/users/invitee/profile' + '/:code',
        name: 'UsersInviteeProfile',
        component: UsersInviteeProfile,
        beforeEnter: requireAuth,
    },
    {
        path: routeSubscriptions,
        name: 'Subscriptions',
        component: Subscriptions,
        beforeEnter: requireAuth
    },
    {
        path: routeInvoices,
        name: 'Invoices',
        component: Invoices,
        beforeEnter: requireAuth
    },
    {
        path: routeInvoicesAdd,
        name: 'InvoicesAdd',
        component: InvoicesAdd,
        beforeEnter: requireAuth
    },
    {
        path: routeInvoicesAddExternal,
        name: 'InvoicesAddExternal',
        component: InvoicesAddExternal,
        beforeEnter: requireAuth
    },
    {
        path: routeQuickBooks,
        redirect: '/integrations',
        name: 'QuickBooks',
        beforeEnter: requireAuth
    },
    {
        path: routeXero,
        redirect: '/integrations',
        name: 'Xero',
        beforeEnter: requireAuth
    },
    {
        path: routeSage,
        redirect: '/integrations',
        name: 'Sage',
        beforeEnter: requireAuth
    },
    {
        path: '/integrations',
        name: 'Integrations',
        component: Integrations,
        beforeEnter: requireAuth
    },
    {
        path: routeContactAdd,
        name: StringConstants.addContactTitle,
        component: AddInternalContact,
        beforeEnter: requireAuth
    },
    {
        path: routeCustomersUpdate + '/:id',
        name: "Update Contact",
        component: UpdateInternalContact,
        beforeEnter: requireAuth
    },
    {
        path: routeCustomersDetails + '/:id',
        name: "Contact Details",
        component: DetailsInternalContact,
        beforeEnter: requireAuth
    },
    {
        path: routeInvoicesDetails + ':id',
        name: 'InvoicesDetails',
        component: InvoicesDetails,
        beforeEnter: requireAuth
    },
    {
        path: routeAccount,
        name: StringConstants.accountTitle,
        component: Accounts,
        beforeEnter: requireAuth
    },
    {
        path: routeOnboard,
        name: StringConstants.onboardTitle,
        component: Onboard,
        beforeEnter: requireAuthOnboard
    },
    {
        path: routeSeed,
        name: 'Seed data',
        component: SeedData,
        beforeEnter: canAccessSeed
    },
    {
        path: routeTeamJobsAdd,
        name: 'Team jobs add',
        component: TeamJobsAddView,
        beforeEnter: requireAuth
    },
    {
        path: routeTeamJobDuplicate + ':id',
        name: 'Team jobs duplicate',
        component: TeamJobsAddView,
        beforeEnter: requireAuth
    },
    {
        path: routeTeamJobsDetails + ':id',
        name: StringConstants.teamJobDetailsComponentTitle,
        component: TeamJobsDetailsView,
        beforeEnter: requireAuth
    },
    {
        path: routeJobsReport,
        name: StringConstants.jobsReportComponentTitle,
        component: JobsReport,
        beforeEnter: ((to, from, next) => next()),
    },
    {
        path: routeOverview,
        name: 'Overview',
        component: Overview,
        beforeEnter: requireAuth
    },
    {
        path: routeUsers,
        name: 'Users',
        component: Users,
        beforeEnter: requireAuth
    },
    {
        path: routeBusinesses,
        name: 'Businesses',
        component: Businesses,
        beforeEnter: requireAuth
    },
    {
        path: routeBusinessesDetails,
        name: 'BusinessesDetails',
        component: BusinessesDetails,
        beforeEnter: requireAuth
    },
    {
        path: '/calendar',
        name: 'CalendarView',
        component: CalendarView,
        beforeEnter: requireAuth
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.afterEach(() => {
    checkAppVersion(store.state)
})

export default router;