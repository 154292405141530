



























































































































































































import accountComputedMixin from '@/components/account/account-computed';
import accountMapSearch from '@/components/account/account-map-search.vue';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

export default {
    name: 'TeamSettingsForm',
    mixins: [accountComputedMixin],
    components: {
        SectionLeftHeadings,
        accountMapSearch,
        VueCtkDateTimePicker,
        Button
    },
    data() {
        return {
            dialogSelectLocationOnMap: false,
            menuColor: false,
            addJobTag: null
        }
    },
    computed: {
        swatchStyle() {
            const { menuColor } = this;
            return {
                backgroundColor: this.accountBusinessProfile.config.defaultColor,
                cursor: "pointer",
                height: "24px",
                width: "24px",
                borderRadius: menuColor ? "50%" : "50px",
                transition: "border-radius 200ms ease-in-out",
            };
        },
        filteredJobTags() {
            return this.accountBusinessProfile.config.jobTags;
        },
        isTagValid() {
            return (
                this.addJobTag && 
                !this.accountBusinessProfile.config.jobTags.includes(this.addJobTag.trim())
            );
        }
    },
    methods: {
        openDialogSelectLocationOnMap() {
            this.dialogSelectLocationOnMap = true;
            this.$store.dispatch('getBusinessLocationCordinateOnMap');
        },
        confirmDialogSelectLocationOnMap() {
            this.dialogSelectLocationOnMap = false;
            this.$store.dispatch("getGoogleMapToShowDefaultMarker");
        },
        addJobTagToConfig() {
            if (this.addJobTag) {
                this.accountBusinessProfile.config.jobTags.push(this.addJobTag);
                this.addJobTag = null;
            }
        },
        removeJobTag(tag) {
            const index = this.accountBusinessProfile.config.jobTags.indexOf(tag);
            if (index !== -1) {
            this.accountBusinessProfile.config.jobTags.splice(index, 1);
            }
        }
    }
}
