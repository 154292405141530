
































































































































































































































































































































import $ from 'jquery';
import { 
    clearDateTimePicker, 
    getHoursAndMinsText, 
    getShortName, 
    openOverlay, 
    initializeDateTimePlaceholders 
} from '@/utils/helpers';
import { mapMutations } from 'vuex';
import { routeJobs } from '@/utils/endpoints';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import router from '@/router/index';
import moment from 'moment';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import snackbarDelete from "@/components/shared/snackbar/snackbar-listing.vue";
import timesheetsComputedMixin from '@/components/timesheets/timesheets-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { DeleteItemDialog, ActionListingDialog } from "@/components/shared/dialogs/index";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import { excelParser } from '@/utils/helpers/excel-parser-helpers';
import { removeAllLocalParamsJobs } from '@/utils/helpers/jobs-helpers';
import { TimeSheetType } from '@/enum/timeSheetType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import { defaultDateFormat } from '@/utils/constants';

export default {
    name: "TimeSheetListView",
    mixins: [permissionsMixin, scrollerMixin, timesheetsComputedMixin, filterParamsMixin],
    data() {
        return {
            dialogTimeSheet: false,
            timeSheetDeleteId: null,
            dateInput: {
                placeholder: StringConstants.selectDatePlaceholder
            },
            format: defaultDateFormat,
            sameDateFormat: {
                from: defaultDateFormat,
                to: defaultDateFormat
            },
            showTimesheetExportDialog: false,
            isChecked: false,
            TimeSheetType,
            searchTimerMemberFilter: null
        }
    },
    computed: {
        someTimesheetsSelected() {
            return this.selectedItems.length > 0 && !this.allTimesheetsSelected
        },
        allTimesheetsSelected() {
            return this.selectedItems.length == this.timesheetsList.length
        }
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems.length > 0) {
                this.snackbarShow = true;
            } 
            else {
                this.snackbarShow = false;
                this.isChecked = false;
            }
        },
        timesheetListFilterType() {
            this.$store.commit('clearSnackbarDetails');
        }
    },
    components: {
        DatePicker,
        snackbarDelete,
        ActionListingDialog,
        DeleteItemDialog,
        Button,
        List
    },
    beforeMount() {
        this.clearAndResetTimeSheetsPagingStates();
    },
    beforeDestroy() {
        this.clearAndResetTimeSheetsPagingStates();
        this.$store.commit('clearSnackbarDetails');
    },
    mounted() {
        this.setPreviousMonthDateFilter();
        this.$store.commit('setDefaultMember', [this.loginUserName, this.loginUserRole]);
        this.$store.dispatch('getTimesheetMembersList');
        this.setFilterOptionsAndGetTimesheets();
        initializeDateTimePlaceholders();
    },
    methods: {
        ...mapMutations({
            resetPaging: "resetPaging",
            clearTimeSheetFilterOption: "setClearFilterOptions",
            resetSnackbarDetails: "clearSnackbarDetails"
        }),
        applySearchTimeSheetFilter() {
            this.timesheetsFilterLoader = true;
            this.clearTimeSheetFilterOption();
            this.resetPaging();
            this.timesheetsList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetTimesheets();
        },
        clearAndResetTimeSheetsPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.timeSheetsModule.timesheetsList = [];
            this.clearTimeSheetFilterOption();
            this.resetPaging();
            this.resetSnackbarDetails();
            this.timesheetListFilterType = 2;
            this.filtersApplied = false;
        },
        filterTimesheetByDateRange(start, end) {
            this.timesheetsFilterStartDate = moment(start).utc().format();
            this.timesheetsFilterEndDate = moment(end).utc().format();
            this.timesheetDateFilterApplied = true;

            this.clearTimeSheetFilterOption();
            this.resetPaging();
            this.$store.state.timeSheetsModule.timesheetsList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetTimesheets();
        },
        clearTimesheetDateFilter() {
            $('.vdpr-datepicker input[type=text]').first().val('');
            clearDateTimePicker(this.$refs.datePicker.$el);
            this.timesheetDateFilterApplied = false;
            this.timesheetsFilterStartDate = null;
            this.timesheetsFilterEndDate = null;
            this.timesheetsList = [];
            this.clearTimeSheetFilterOption();
            this.resetPaging();
            this.setFilterOptionsAndGetTimesheets();
        },
        getDateFormatted: function (date) {
            return moment.utc(date).local().format('DD MMM YYYY');
        },
        getDateTimeInExcelFormat: function (date) {
            return moment.utc(date).local().format('DD_MMM_YYYY');
        },
        getTimeInitials: function (date) {
            return moment.utc(date).local().format("LT");
        },
        openAddTimeListForm: function () {
            this.$store.commit('setDefaultMember', [this.loginUserName, this.loginUserRole]);
            this.$store.dispatch('getTimesheetMembersList');
            this.$store.dispatch(
                'getTimesheetMembersWorkTime', 
                this.getSenderId
            );
            openOverlay("addTimeSheetFormOverlay");
        },
        openTimeSheetEditForm: function (id) {
            const $this = this.$store;
            this.resetSnackbarDetails();
            $this.dispatch('getTimesheetDetails', id);
            openOverlay("editTimeSheetFormOverlay");
        },
        confirmDeleteTimeSheet: function (id) {
            this.timeSheetDeleteId = id;
            this.dialogTimeSheet = true;
        },
        proceedTimeSheetDeletion: function () {
            this.dialogTimeSheet = false;
            this.resetSnackbarDetails();
            this.$store.dispatch('deleteTimesheet', this.timeSheetDeleteId).then((res) => {
                this.clearTimeSheetFilterOption();
                this.resetPaging();
                if (res != undefined) {
                    this.timesheetsList = [];
                    this.setFilterOptionsAndGetTimesheets();
                }
            });
        },
        openJobList: function (startTime: any, memberId: string) {
            if (startTime && memberId) {
                removeAllLocalParamsJobs();
                this.$store.state.isRoutedFromReferencePage = true;
                this.routedFromTimesheets = true;

                const startTimeUTC: string = moment(startTime).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();
                const endTimeUTC: string = moment(startTimeUTC).add('1', 'days').utc().format();

                if (startTimeUTC && endTimeUTC) {
                    this.setJobsFilterParams(startTimeUTC, endTimeUTC, memberId);
                }
            }
            router.push(routeJobs);
        },
        setJobsFilterParams(startTime, endTime, memberId) {
            this.setLocalParamsMx(startTime, 'jobStartDate');
            this.setLocalParamsMx(endTime, 'jobEndDate');
            this.setLocalParamsMx(memberId, 'jobAssignedUserIds');
        },

        getTotalAvgHours: function (time) {
            const hour = Math.floor(time / 60);
            const min = time % 60;
            if (min != 0) {
                return hour + '.' + min;
            }
            else {
                return hour;
            }
        },
        getMemberTimesheet(memberId: string) {
            this.timesheetsFilterLoader = true;
            this.resetPaging();
            this.$store.state.tempUrl = '';
            if (memberId == undefined) {
                this.clearTimeSheetFilterOption();
            } else {
                this.timesheetsSelectedMemberId = memberId;
            }
            this.timesheetsList = []
            this.setFilterOptionsAndGetTimesheets();
        },
         updateSearchMemberText(e) {
            const searchedText = e.target.value;
            if (searchedText.length == 0) {
                this.timesheetMembersSearchText = null;
                this.applyMembersFilterSearchTimer();
            } else {
                this.timesheetMembersSearchText = searchedText;
                this.applyMembersFilterSearchTimer();
            }
        },
        applyMembersFilterSearchTimer() {
            if (this.searchTimerMemberFilter) {
                clearTimeout(this.searchTimerMemberFilter);
                this.searchTimerMemberFilter = null;
            }
            this.searchTimerMemberFilter = setTimeout(() => {
                this.searchTeamMembersFilter();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        searchTeamMembersFilter() {
            this.clearTimeSheetFilterOption();
            this.$store.dispatch('setSearchTextForTimesheetMembers').then(res => {
                this.$store.dispatch('getTimesheetMembersList');
                    if (this.timesheetMembersSearchText == '') {
                        this.clearTimeSheetFilterOption();
                    }
             });
        },
        setPreviousMonthDateFilter() {
            let priorDate: any = new Date()
            priorDate = priorDate.setDate(priorDate.getDate() - 30)
            this.timesheetsFilterStartDate = moment(priorDate).utc().format()
        },
        getMoreTimesheetDataForExport() {
            this.$store.dispatch('getTimesheetDataForExport').then(() => {
                if (this.timesheetDataListLoadedCount < this.timesheetDataListTotalCount) {
                    this.getMoreTimesheetDataForExport();
                } else {
                    if (this.timesheetDataListForExport.length > 0) {
                        this.showTimesheetExportDialog = false
                        this.saveTimesheetDataToExcel()
                    }
                }
            });
        },
        getTimesheetDataForDownload() {
            this.$store.dispatch('setFilterForTimesheetDataExport').then(() => {
                this.$store.dispatch('getTimesheetDataForExport').then(() => {
                    this.getMoreTimesheetDataForExport()
                });
            });
        },
        getExcelFilename() {
            let memberName = 'all'
            if (this.timesheetsSelectedMemberId != null)
                memberName = this.timesheetsMembersList.filter(member => member.id == this.timesheetsSelectedMemberId)[0].name

            return ('timesheets_' +
                memberName + '_' +
                (this.timesheetsFilterStartDate ? this.getDateTimeInExcelFormat(this.timesheetsFilterStartDate) : '') +
                (this.timesheetsFilterEndDate ? ('_to_' + this.getDateTimeInExcelFormat(this.timesheetsFilterEndDate)) : ''))
        },
        saveTimesheetDataToExcel() {
            excelParser().exportDataFromJSON(
                this.timesheetDataListForExport,
                this.getExcelFilename(),
                ConstantValues.defaultListExportFormat,
            )
        },
        initiateTimesheetExport() {
            this.$store.commit('resetTimesheetExportData')
            this.clearTimeSheetFilterOption()
            this.resetPaging()
            this.showTimesheetExportDialog = true
            this.getTimesheetDataForDownload()
        },
        selectAllTimesheets() {
            this.selectedItems = [];
            this.allItemsDeleted = false;
            if (this.isChecked) {
                this.timesheetsList.map((pro) => {
                    this.selectedItems.push(pro.id);
                });
            }
        },
        getTimeInHoursAndMins(durationInMins: number) {
            return getHoursAndMinsText(durationInMins);
        },
        getShortNote(textNote: string) {
            return getShortName(textNote, 28);
        },
        setFilterOptionsAndGetTimesheets(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.$store.dispatch('setTimesheetsFilter', filters).then(() => {
                this.$store.dispatch('getTimesheetsList').then(() => {
                    this.timesheetsFilterLoader = false;
                });
                this.$store.dispatch('getTimesheetsStatistics');
                if (this.timesheetsSearchText == '') {
                    this.clearTimeSheetFilterOption();
                    this.filtersApplied = false;
                }
            });
        }
    }
}
