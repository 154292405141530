import { apiUsers, apiFarmers, apiJobs, apiInvoicesDraft, apiInvoices, apiInvoicesItems } from '@/utils/endpoints';
import { notify } from '@/utils/helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { JobInvoicingStatusType } from '@/enum/jobInvoicingStatusType';
import { ExternalIntegrationTypeLabels } from '@/enum/externalIntegrationType';

export const actions = {
    async initializeLoginWithExternalInvoicingService({ rootState }, integrationType) {
        const url = buildUrl(rootState.baseUrl, {
            path: `/integrations/${ExternalIntegrationTypeLabels[integrationType]}/start_signup`
        });

        rootState.loginLoader = true;
        const result = await requests.postWithoutDataWithoutAuth(url);

        setTimeout(() => {
            rootState.loginLoader = false;
        }, ConstantValues.defaulSignupLoadingTimeout);

        if (result?.length > 0) {
            window.location.href = result[0];
        }
    },
    async getCustomersListForInvoiceExternal({ state, commit, rootState, dispatch }) {
        if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
            state.customersListForExternalInvoiceLoader = true;
            commit('customersDropdown/fetchingData');
            dispatch("jsonToQueryString", rootState.filterOptions);
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + rootState.tempUrl
            });

            state.customersListForExternalInvoiceLoader = true;            
            const result = await requests.getData(url);
            state.customersListForExternalInvoiceLoader = false;

            if (result != null && 'data' in result) {
                if (result.data != null) {
                    commit('customersDropdown/setResponseRowCount', result.data.value.length);
                    commit('customersDropdown/setTotalRowCount', result.data.size);
                    if (result.data.value.length > 0) {
                        commit('setCustomersListForInvoiceExternal', result.data.value);
                        state.customersListForInvoiceExternalSize = result.data.size;
                    }
                    if (rootState.offset == 0) {
                        commit('customersDropdown/setNoDataStatus', result.data.value);
                    }
                }
                commit('customersDropdown/doneFetchingData');
            }
        }
    },

    async getInvoicesListExternal({ state, commit, rootState, dispatch }, integrationType) {
        if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
            rootState.listLoading = true;
            dispatch("jsonToQueryString", rootState.filterOptions);

            const url = buildUrl(rootState.baseUrl, {
                path: localStorage.getItem(ConstantValues.ownerId) + '/' + integrationType + apiInvoices + rootState.tempUrl
            });

            if(rootState.tempUrl.includes('undefined')) return;

            const result = await requests.getData(url);

            if (result != null && 'data' in result) {
                if (result.data != null) {
                    const invoicesList = result.data.value;
                    if (invoicesList) {
                        state.listCount = invoicesList.length;
                        state.totalRowCount = result.data.size;
                        state.offset = result.data.offset;
                        if (invoicesList.length > 0) {
                            commit('setInvoicesListExternal', invoicesList);
                        }
                        if (rootState.offset == 0) {
                            commit('setNoExternalListDataStatus', invoicesList);
                        }
                    }
                }
                rootState.listLoading = false;
            }
        }
    },

    async getJobsListForInvoiceExternal({ state, commit, rootState, dispatch }) {
        state.invoiceLoaderExternal = true;
        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const tagsFilter = state.invoicesExternalListFilterTagValue ? `&tags=${state.invoicesExternalListFilterTagValue}` : '';

        let url = '';
        if (state.jobsForInvoiceSearchTextExternal?.length != 0) {
            url = buildUrl(rootState.baseUrl, {
                path: apiJobs + '?Statuses=5' + '&SearchText=' + encodeURIComponent(state.jobsForInvoiceSearchTextExternal.replaceAll(' ', '')) + '&InvoicingStatus=' + JobInvoicingStatusType.Approved + (rootState.tempUrl ? '&' + rootState.tempUrl.slice('1') : '') + tagsFilter
            });
        } else {
            url = buildUrl(rootState.baseUrl, {
                path: apiJobs + '?Statuses=5' + '&InvoicingStatus=' + JobInvoicingStatusType.Approved + (rootState.tempUrl ? '&' + rootState.tempUrl.slice('1') : '') + tagsFilter
            });
        }
        const result = await requests.getData(url);
        state.invoiceLoaderExternal = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                rootState.totalRowCount = result.data.size;
                rootState.listCount = result.data.value.length;
                rootState.listLoading = false
                commit('setJobsListForInvoiceExternal', result.data.value);
                
                if (rootState.offset == 0) {
                    commit('setNoDataStatus', result.data.value)
                }
                
                state.totalApprovedJobsCountExternal = result.data.size;
                return result.data;
            }
        }
        rootState.listLoading = false
    },

    async createInvoiceDraftPreviewExternal({ rootState, state }, data) {
        state.invoiceLoaderExternal = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + data[0] + apiInvoicesDraft
        });
        const result = await requests.postData(url, data[1]);
        if (result[0] != null) {
            state.invoiceLoaderExternal = false;
            return result[0];
        } else {
            state.invoiceLoaderExternal = false;
            return false;
        }
    },

    async getInvoiceLineItemsExternal({ rootState, state, commit, dispatch }, integrationType) {
        state.fetchingLineItems = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) +
                '/' +
                integrationType +
                apiInvoicesItems
        });
        const result = await requests.getDataRes(url);
        state.fetchingLineItems = false;

        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setInvoiceExternalLineItems', result.data);
                return result.data;
            }
        }
    },

    async setLineItemsExternalSearchText({ commit, state }, lineItemIndex) {
        if (state.invoiceExternalLineItemSearchText[lineItemIndex] != null) {
            const search = state.invoiceExternalLineItemSearchText[lineItemIndex].trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async createInvoiceFinalizeExternal({ rootState }, data) {
        const invoiceType = data[0];
        const invoiceData = data[1];

        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/' + invoiceType + '/invoices'
        });
        const result = await requests.postData(url, invoiceData);
        if (result[0] != null) {
            // notify(StringConstants.invoiceCreateSuccess, 'success');
            return result[0];
        } else {
            notify(StringConstants.invoiceCreateFail, 'fail');
        }
    },

    async setCustomersFilterExternal({ commit, state }, data) {
        if (state.customersSearchTextExternal.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.customersSearchTextExternal]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async setJobsForInvoiceFilterExternal({ commit, state, rootState }, data) {
        if (state.selectedCustomerIdForInvoiceExternal) {
            commit('setFilterOptions', [ConstantValues.customerIdsQuery, state.selectedCustomerIdForInvoiceExternal]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);

        return true
    },

    async fnSetInvoicesFilterExternal({ commit, state }, data) {
        if (state.invoiceSearchTextExternal) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.invoiceSearchTextExternal]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);

    },

    async checkContactLinkStatus({ state, rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + "/" + data[0] + "/contacts/" + data[1] + '/link'
        });
        const result = await requests.getData(url);
        if (result.status != 200) {
            state.linkedContactToExternalService = false;
        } else {
            state.linkedContactToExternalService = true;
            commit('setLinkedContactInfo', result.data);
            return result.data;
        }
    },

    async getCustomersListForInvoicesFilter({ state, commit, rootState, dispatch }) {
        if (rootState.loginUserRole != 1) {
            dispatch("jsonToQueryString", rootState.filterOptions);
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers 
                      + (state.customersFilterSearchText ? ('?SearchText=' + state.customersFilterSearchText) : '')
            });
            const result = await requests.getData(url);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setCustomersListFilterForInvoices', result.data.value);
                }
            }
        }
    },

    async getSupplierDetailsForExternalInvoice({dispatch, commit}) {
        const supplierDetails = await dispatch('getBusinessProfileForInvoice', localStorage.getItem(ConstantValues.ownerId))
        commit('setSupplierDetailsForExternalInvoice', supplierDetails)
        return
    },

    async getCustomerDetailsForExternalInvoice({dispatch, commit}, customerId) {
        const customerDetails = await dispatch('getBusinessProfileForInvoice', customerId)
        commit('setCustomerDetailsForExternalInvoice', customerDetails)
        return
    },

    async getTagsListForInvoicesExternal({ state, dispatch, commit }) {
        const tags = await dispatch('getTagsForTeamJobs')
        
        if (tags?.config?.jobTags) {
            state.invoicesExternalListFilterTagsList = tags.config.jobTags || [];
        }
    }
}
