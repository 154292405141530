


































































































































































































































































































































































































































































































































































import { routeTeamJobsDetails } from "@/utils/endpoints";
import moment from "moment";
import invoicesComputedMixin from "@/components/invoices/invoices-computed";
import { HoverTextButton } from "@/components/shared/buttons/index";
import { getDateFormatted, getListLengthAbsolute, notify, reduceText } from "@/utils/helpers";
import router from '@/router';
import { routeAccount } from '@/utils/endpoints';
import { AccountTabType } from '@/enum/accountTabType';
import ConstantValues from '@/plugins/constantValues';
import CloseButton from '@/components/shared/buttons/button-close.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import PaginationModule from "@/store/modules/pagination";
import LoadMoreItem from "../../shared/load-more-item.vue";
import { mapMutations, mapState } from 'vuex';
import {InvoiceStatusType, InvoiceEmailStatusType} from '@/enum/invoiceStatusType';

export default {
    data() {
        return {
            menuDate: false,
            invoiceMenuDate: false,
            editorData: "",
            searchTimerProductFilter: null,
            isProductInList: false
        };
    },
    mixins: [invoicesComputedMixin],
    components: {
        HoverTextButton,
        CloseButton,
        Button,
        LoadMoreItem
    },
    computed: {
        computedDateFormatted() {
            return this.invoiceDueDate
                ? moment(this.invoiceDueDate).format("DD MMM YY")
                : "";
        },
        computedInvoiceDateFormatted() {
            return this.invoiceDate
                ? moment(this.invoiceDate).format("DD MMM YY")
                : "";
        },
        maxCompanyNameLength() {
            return this.$store.getters.maxCompanyNameLength;
        },
        maxAddressLength() {
            return this.$store.getters.maxAddressLength;
        },
        invoiceCurrencySymbol() {
            return this.invoiceDraftPreviewDetails?.currency?.symbol || this.currencySymbol
        },
        validateAddItem(){
            if(!this.newLineItemsInitialsForInternal.title){
                return true;
            } 
            else {
               return false;
            }
        },
        isInvoiceStatusOpen() {
            if (!this.invoiceDraftPreviewDetails) return null
            return this.invoiceDraftPreviewDetails?.status === InvoiceStatusType.Open
        },
        invoiceStatus() {
            if (!this.invoiceDraftPreviewDetails) return ''
            return InvoiceStatusType[this.invoiceDraftPreviewDetails?.status].toUpperCase()
        },
        invoiceEmailStatus() {
            if (!this.invoiceDraftPreviewDetails) return null
            return InvoiceEmailStatusType[this.invoiceDraftPreviewDetails?.emailStatus]?.toLowerCase();
        }
    },
    mounted() {
        this.invoiceJobReportUrls = [];
        this.$store.dispatch("getInvoiceConfiguration");
    },
     beforeDestroy() {
        this.$store.unregisterModule(this.$stringConstants('invoicesProductListFilter'));
    },
     created() {
        this.registerProductModule();
    },
    methods: {
        ...mapMutations({
            resetNewTimeLineInitials: "resetNewTimeLineInitials",
        }),
        handleLineItemPricing(index) {
           this.invoiceDraftPreviewDetails.lineItems.map((lineItem, idx) => {
                if (idx == index) {
                    lineItem.amount = 0;
                    lineItem.amount =
                        parseFloat(lineItem.rate) * ((typeof lineItem.quantity == "string") ? 0 : parseFloat(lineItem.quantity));
                    lineItem.taxAmounts[0].amount =
                        parseFloat(lineItem.amount) *
                        (lineItem.taxAmounts[0].salesTaxRateInPercentage / 100);
                    lineItem.amount = parseFloat(lineItem.amount);
                }
            });
            this.getSubtotal();
            this.getVatTotal();
            this.getTotalAmount();
        },
        getSubtotal() {
            let subTotal = 0;
            this.invoiceDraftPreviewDetails.lineItems.map((lineItem) => {
                subTotal += parseFloat(lineItem.amount);
            });
            this.invoiceDraftPreviewDetails.subTotal = subTotal;
            return this.invoiceDraftPreviewDetails.subTotal.toFixed(
                ConstantValues.defaultAmountFixedDigitCount
            );
        },
        getVatTotal() {
            let vatTotal = 0;
            this.invoiceDraftPreviewDetails.lineItems.map((lineItem) => {
                vatTotal += parseFloat(lineItem.taxAmounts[0].amount);
            });
            return vatTotal.toFixed(ConstantValues.defaultAmountFixedDigitCount);
        },
        getLineItemTotal(lineItem) {
            return (
                parseFloat(lineItem.amount) + parseFloat(lineItem.taxAmounts[0].amount)
            );
        },
        getTotalAmount() {
            return this.getSubtotal() + this.getVatTotal();
        },
        negativeKeydown(e) {
            if (!/[^-+]/.test(e.key)) {
                e.preventDefault();
            }
        },
        routeToJobDetails(jobIndex: string) {
            const jobId = this.invoiceLineItemsJobIds[jobIndex];

            if (jobId) {
                window
                    .open(`${routeTeamJobsDetails}${jobId}`, "_blank")
                    .focus();
            }
        },
        getAddressString(address: any) {
            const line1 = address.addressLine1 ? address.addressLine1 : "";
            const line2 = " " + (address.addressLine2 ? address.addressLine2 : "");
            const city = " " + (address.city ? address.city : "");
            const state = " " + (address.state ? address.state : "");
            const country = " " + (address.country ? address.country : "");
            const postalCode = " " + (address.postalCode ? address.postalCode : "");
            const addressArray = [line1, line2, city, state, country, postalCode];
            return addressArray.filter(a => a && a != ' ').join();
        },
        viewReport(reportUrl: string) {
            window.open(reportUrl, "_blank");
        },
        removeAttachment(rIndx) {
            this.invoiceJobReportUrls.splice(rIndx, 1);
        },
        handleQuantityChange(event, index) {
            this.handleLineItemPricing(index);
            this.negativeKeydown(event);
        },
        getJobNumber(jobNumberIndex) {
            if (this.invoiceDraftPreviewDetails?.references?.length > 0) {
                return this.invoiceDraftPreviewDetails.references[jobNumberIndex].split(' ')[0];
            } else {
                return `Report ${jobNumberIndex}`;
            }
        },
        getDateFormatted(date) {
            return getDateFormatted(date)
        },
        getRateNameAndValue(rateName: string, rateInPercentage: number, currentTaxValue: number) {
            if (rateName)
                return `${rateName} - ${rateInPercentage}`
            return `Default - ${currentTaxValue}`
        },
        routeToAccount() {
            router.push(routeAccount)
            setTimeout(() => {
                this.accountTab = AccountTabType.Business;
            }, ConstantValues.defaultTabSelectionTimeout)

            setTimeout(() => {
                document.getElementById('account-business').scrollBy(0, 700)
            }, ConstantValues.defaultScrollToPositionTimeout)
        },
        getSalesTaxRegistrationNumber() {
            return this.invoiceDraftPreviewDetails?.taxAccounts[0]?.registrationNumber ?? ''
        },
        removeInternalLineItem(lineitemIndex) {
            this.invoiceDraftPreviewDetails.lineItems = this.invoiceDraftPreviewDetails.lineItems.filter((item, index) => index != lineitemIndex);
        },
        showAddLineItemViewInternal(index){
            this.isProductInList = false;
            this.registerProductModule();
            this.resetNewTimeLineInitials();
            this.showAddLineitemsForInternalInvoice = this.showAddLineitemsForInternalInvoice.map((lineView) => lineView = false)
            this.showAddLineitemsForInternalInvoice[index] = true;
        },
        reduceTextLength(textToReduce: string, maxTextLength: number) {
          return (textToReduce) ? reduceText(textToReduce, maxTextLength) : '';
        },
        registerProductModule(){
            this.productDropdownModule =  this.$stringConstants('invoicesProductListFilter');
                if(!this.$store.hasModule(this.productDropdownModule)) {
                    this.$store.registerModule(this.productDropdownModule, PaginationModule);
                }
        },
        applyProductFilterSearchTimerFocus() {
            if(!this.newProductForInternalInvoice){
                this.applyProductFilterSearchTimer();
            }
        },
        applyProductFilterSearchTimer() {
            if (this.searchTimerProductFilter) {
                clearTimeout(this.searchTimerProductFilter);
                this.searchTimerProductFilter = null;
            }
            this.searchTimerProductFilter = setTimeout(() => {
                this.searchProductByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        searchProductByText: function () {
            this.productListForInternalInvoice = [];
            this.appendMoreProducts();
        },
        appendMoreProducts() {
            const paginationModule = this.$stringConstants('invoicesProductListFilter');
            this.$store.dispatch('setProductsFilterForInternalInvoice');
            this.$store.dispatch('getProductsListFilteredForInternalInvoice').then(response => this.$store.dispatch(`${paginationModule}/updateStates`, response));
        },
        cancelNewLineItemAdd() {
            this.showAddLineitemsForInternalInvoice = this.showAddLineitemsForInternalInvoice.map((lineItemView) => lineItemView = false)
        },
        setProductInfoInLineItem(){
            this.isProductInList = false;
            if(this.newProductForInternalInvoice != null){
                this.newLineItemsInitialsForInternal.title = this.newProductForInternalInvoice.title;
                this.newLineItemsInitialsForInternal.rate = this.newProductForInternalInvoice.cost;
                this.newLineItemsInitialsForInternal.unit = this.newProductForInternalInvoice.unit;
            }
            else{
                this.resetNewTimeLineInitials();
            }
        },
        addNewLineItemInternal(distinctJobIndex){
            const jobReference = this.invoiceLineItemJobNumbersDistinct[distinctJobIndex];
            let lastIndexToAppendTo = 0;
            this.invoiceDraftPreviewDetails.lineItems.forEach((item, index) => {
                if(item.reference == jobReference) {
                lastIndexToAppendTo = index;
                  if (item.title == this.newLineItemsInitialsForInternal.title){
                    this.isProductInList = true;
                  }
                }
            })
            if(!this.isProductInList){
            this.newLineItemsInitialsForInternal.reference = jobReference;
            this.newLineItemsInitialsForInternal.amount = this.newLineItemsInitialsForInternal.rate * this.newLineItemsInitialsForInternal.quantity;
            this.newLineItemsInitialsForInternal.taxAmounts[0].salesTaxName = this.taxName;
            this.invoiceDraftPreviewDetails.lineItems.splice(lastIndexToAppendTo + 1, 0, this.newLineItemsInitialsForInternal);
            const anyLineItemWithSameJob = this.invoiceDraftPreviewDetails.lineItems.find(lineItem => lineItem.reference == jobReference);
            this.newLineItemsInitialsForInternal.date = (anyLineItemWithSameJob && anyLineItemWithSameJob.date) ? anyLineItemWithSameJob.date : '';
            this.resetNewTimeLineInitials();
            this.cancelNewLineItemAdd();
            }
        },
    },
};
