






































































































































































import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { mapMutations } from "vuex";
import moment from "moment";
import invoicesComputedMixin from "@/components/invoices/invoices-computed";
import { getAdditionalTagsCount, getAssignedUserNameFromJob, getPrimaryTagDisplay, getTagsTitle, hasAdditionalTags, hasTags } from "@/utils/helpers/jobs-helpers";
import LoadMoreItem from "@/components/shared/load-more-item.vue";
import { getListLengthAbsolute } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
import { brandColor } from '@/utils/uiconstants';

export default {
  name: "JobsListForInvoice",
  computed: {
    currentInvoiceId: {
      get() {
          const invoiceId = window.location.pathname.split("/").pop();
          return invoiceId !== "add" ? invoiceId : null;
      }
    },
  },
  data() {
    return {
      radioGroup: 1,
      tempCustomerId: "",
      isAllJobsSelectedForInvoice: false,
      brandColor,
    };
  },
  components: {
    ListLoader,
    LoadMoreItem,
  },
  mixins: [invoicesComputedMixin],
  watch: {
    selectedCustomerIdForInvoice(newVal) {
      if (newVal == null) {
        this.tempCustomerId = "";
        this.selectedJobsForInvoice = [];
        this.tempJobId = "";
      } else {
        this.getJobsFromCustomer(newVal);
      }
    },
    isAllApprovedJobsSelected(newVal) {
      if (newVal && this.jobsListForInvoice.length) {
        this.selectedJobsForInvoice = this.jobsListForInvoice;
        this.isAllJobsSelectedForInvoice = true;
      } 
      if (!newVal && this.jobsListForInvoice.length) {
        this.selectedJobsForInvoice = [];
        this.isAllJobsSelectedForInvoice = false;
      }
    }
  },
  mounted() {
    if (!this.currentInvoiceId)
      this.$store.dispatch("getJobsListForInvoice");
  
    this.isAllJobsSelectedForInvoice = false;
    this.$store.dispatch("getTagsListForInvoices");
  },
  methods: {
    ...mapMutations({
      clearCustomersFilterOption: "setClearFilterOptions",
      resetPaging: "resetPaging",
    }),
    updateSelectedJobs(selectedJobs) {
      const newVal = selectedJobs[0];
      if (
        newVal &&
        newVal.length != 0 && 
        newVal.customer.id != this.getSenderId &&
        this.tempCustomerId != newVal.customer.id &&
        this.selectedCustomerIdForInvoice == null
      ) {
        const jobOwnerId = (this.tempCustomerId = newVal.customer.id);

        if (jobOwnerId) {
          this.tempJobId = selectedJobs[0].id;
          this.$store.dispatch("getJobsListForInvoice", jobOwnerId);
          this.$store.dispatch("getCustomerDetailsForInvoice", jobOwnerId);
          const customer = newVal.customer;
          if (customer.businessName) {
            customer["displayName"] = customer.businessName;
          } else {
            customer["displayName"] = customer.name;
          }
          this.customersListForInvoice.push(customer);
          this.selectedCustomerIdForInvoice = jobOwnerId;
        }
      }
    },
    clearSelectedJobs() {
      this.invoiceLoader = true;
      this.selectedJobsForInvoice = [];
      this.invoiceLoader = false;
    },
    getJobsListFromCustomerId() {
      if (this.selectedCustomerIdForInvoice) {
        this.$store
          .dispatch("getJobsListForInvoice", this.selectedCustomerIdForInvoice)
          .then((res) => {
            this.clearSelectedJobs();
          });
      } else {
        this.$store.dispatch("getJobsListForInvoice").then((res) => {
          this.clearSelectedJobs();
        });
      }
    },
    applySearchCustomersFilter() {
        if (this.searchTimerCustomers) {
            clearTimeout(this.searchTimerCustomers);
            this.searchTimerCustomers = null;
        }
        this.searchTimerCustomers = setTimeout(() => {
            this.applySearchFilters();
        }, ConstantValues.defaultDropdownSearchTimeout); 
    },
    applySearchFilters() {
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.$store
        .dispatch("setCustomersFilter", [
          ConstantValues.defaultPageOffsetSize,
          ConstantValues.defaultPageLimitSize,
        ])
        .then((res) => {
          this.$store.dispatch("getCustomersListForInvoice");
          if (this.customersSearchText == "") {
            this.clearCustomersFilterOption();
          }
        });
    },
    getJobsFromCustomer(customerIdForJobs: string) {
      if (customerIdForJobs) {
        this.$store
          .dispatch("getJobsListForInvoice", customerIdForJobs)
          .then((res) => {
            this.clearSelectedJobs();
            if (this.jobsListForInvoice.length) {
              this.selectedJobsForInvoice = [];
              const previousSelectedJob = this.jobsListForInvoice.find(job => job.id === this.tempJobId);
              if (previousSelectedJob) {
                this.selectedJobsForInvoice.push(previousSelectedJob);
              }
            }
          });
        this.$store.dispatch("getCustomerDetailsForInvoice", customerIdForJobs);
      }
    },
    getDateTimeFormat(date) {
      return date ? moment.utc(date).local().format("DD MMM YYYY") : "-";
    },
    applySearchJobsListFilter() {
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.jobsListForInvoice = [];
      this.$store.dispatch("setJobsForInvoiceFilter").then((res) => {
        this.$store
          .dispatch("getJobsListForInvoice", this.selectedCustomerIdForInvoice)
          .then((rs) => {
            this.invoiceLoader = false;
          });
        if (this.selectedCustomerIdForInvoice) {
          this.$store.dispatch(
            "getCustomerDetailsForInvoice",
            this.selectedCustomerIdForInvoice
          );
        }
        if (this.jobsForInvoiceSearchText == "") {
          this.clearCustomersFilterOption();
        }
      });
    },
    getAssignedUserName(job: any) {
      return getAssignedUserNameFromJob(job);
    },
    getJobNumberPrefix(jobNumber: string) {
      if (jobNumber.split("#").length < 2) {
        return "#" + jobNumber;
      }
      return jobNumber.split("#")[0];
    },
    getJobNumberSuffix(jobNumber: string) {
      if (jobNumber.split("#").length == 2) {
        return "#" + jobNumber.split("#")[1];
      }
    },
    appendMoreCustomers() {
      this.customersSearchText = '';
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.$store.commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
      this.$store.commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(this.customersListForInvoice)]);
      this.$store.dispatch("getCustomersListForInvoice");
      if (this.customersSearchText == '') {
        this.clearCustomersFilterOption();
      }

      if (this.isAllJobsSelectedForInvoice)
        this.selectedJobsForInvoice = this.jobsListForInvoice;
    },
    toggleSelectAllJobs() {
      if (this.isAllJobsSelectedForInvoice) {
        this.selectedJobsForInvoice = this.jobsListForInvoice;
      } else {
        this.selectedJobsForInvoice = [];
        this.isAllApprovedJobsSelected = false;
      }
    },
    getTagsTitle(tags) {
        return getTagsTitle(tags)
    },
    getPrimaryTagDisplay(tags) {
        return getPrimaryTagDisplay(tags)
    },
    hasAdditionalTags(tags) {
        return hasAdditionalTags(tags)
    },
    hasTags(tags) {
        return hasTags(tags)
    },
    getAdditionalTagsCount(tags) {
        return getAdditionalTagsCount(tags)
    },
  },
}
