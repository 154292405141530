



































import accountComputedMixin from "@/components/account/account-computed";
import AccountProfileForm from "./account-profile.vue";
import AccountBusinessForm from "./account-business.vue";
import FarmsAddressForm from "./account-farms-address.vue";
import { UserRoleType } from "@/enum/userRoleType";
import { AccountTabType } from "@/enum/accountTabType";
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
    mixins: [accountComputedMixin, requestsCancellationMixin],
    data() {
        return {
            UserRoleType
        };
    },
    components: {
        AccountProfileForm,
        AccountBusinessForm,
        FarmsAddressForm,
    },
    mounted() {
        this.$store.state.dashboardTitle = this.$stringConstants('accountTitle');
        this.$store.dispatch("getGlobalCountriesList");

        const urlSearchParams = new URLSearchParams(window.location.search)
        const target = urlSearchParams.get('target')

        if (target == 'tags') 
            this.accountTab = AccountTabType.Business
    },
    methods: {
        handleTabChangeBusiness(accountTab) {
            if (this.accountTab == AccountTabType.Business) {
                this.$store.dispatch("getGoogleMapToShowDefaultMarker");
            }
        }
    }
};
