
















































































































































































































































































































import $ from 'jquery';
import { mapMutations } from 'vuex';
import { openOverlay, openOverlayPermanent } from '@/utils/helpers';
import { routeContactAdd, routeCustomersUpdate, routeCustomersDetails, routeQuickBooks } from '@/utils/endpoints';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from '@/mixins/filter-params-mixin';
import Avatar from '@/components/shared/avatars/avatar.vue';
import AvatarDisabled from '@/components/shared/avatars/avatar-disabled.vue';
import router from '@/router/index';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import farmersConnectionRequest from '@/components/farmers/farmers-connection-request.vue';
import confirmDialogbox from "@/components/shared/dialogs/delete-item.vue";
import skeletonLoaderMixin from '@/mixins/skeleton-loader-mixin';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import BulkActionResponseDialog from '@/components/shared/dialogs/bulk-action-response-dialog.vue';
import { UserRoleType } from '@/enum/userRoleType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import { ActionListingDialog } from "@/components/shared/dialogs/index";
import { listAction } from '@/enum/listAction';
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import { IntegrationAccessStates } from '@/enum/integrationAccessStates';

export default {
    name: "FarmersListView",
    mixins: [
        permissionsMixin, 
        scrollerMixin, 
        filterParamsMixin, 
        farmersComputedMixin, 
        skeletonLoaderMixin,
        Timers
    ],
    components: {
        Avatar,
        AvatarDisabled,
        farmersConnectionRequest,
        confirmDialogbox,
        Button,
        DeleteItemDialog,
        BulkActionResponseDialog,
        List,
        Snackbar,
        ActionListingDialog
    },
    data() {
        return {
            selectedFarmerId: null,
            dialogFarmer: false,
            importButtonDisabled: false,
            integrateDialog: false,
            UserRoleType,
            searchTimer: null,
            actionType: listAction.Restore,
            isChecked: false,
            ViewPreferenceType,
            listAction,
            showUpdateCustomersDialog: false,
        }
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems?.length > 0) {
                this.snackbarShow = true
            } else {
                this.snackbarShow = false
                this.isChecked = false
            }
        },
        farmerFilterType() {
            this.$store.commit('clearSnackbarDetails');
        },
    },
    computed: {
        someFarmersSelected() {
            return this.selectedItems.length > 0 && !this.allFarmersSelected;
        },
        allFarmersSelected() {
           if(this.farmerSearchText){
                return false;
            }
            else {
            return (this.selectedItems.length == this.farmersFilterList.length);
            }
        },
    },
    beforeMount() {
        if (!this.isPageRelated) {
            this.clearAndResetFarmersPagingStates();
        }
    },
    beforeDestroy() {
        if (!this.isPageRelated) {
            this.clearAndResetFarmersPagingStates();
            this.$store.commit('clearSnackbarDetails');
        }
    },
    mounted() {
        this.getFarmersList()
    },
    methods: {
        ...mapMutations({
            clearFarmerFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            setClearInviteeProfile: "setClearInviteeProfile",
            clearUserInvitationState: "setClearUserInvitationState",
            resetImportForm: "resetImportDataDetails",
            resetSnackbarDetails: "clearSnackbarDetails",
        }),
        getFarmersList() {
            if (!this.$store.state.isPageRelated || this.farmersFilterList.length === 0) {
                localStorage.removeItem('customerSearchQuery')
                this.getFarmersListView()
            } else {
                this.getUrlParamsCustomerLocal()
            }
            this.importButtonDisabled = true
            this.$store.dispatch('getFarmersConReq')
            this.$store.dispatch("getExternalInvoicingServiceAccess").then(() => {
                if (this.xeroAccessState == IntegrationAccessStates.HasAccess) {
                    this.integrationType = "xero"
                }
                if (this.quickbookAccessState == IntegrationAccessStates.HasAccess) {
                    this.integrationType = "quickbooks"
                }
                if (this.sageAccessState == IntegrationAccessStates.HasAccess) {
                    this.integrationType = "sage"
                }
                this.importButtonDisabled = false
            })
        },
        setUrlParamsCustomersLocal() {
            this.setLocalParamsMx(this.farmerSearchText, 'customerSearchQuery')
            this.setLocalParamsMx(this.farmerFilterType, 'customerFilterType')
            this.setLocalParamsMx(this.farmerType, 'customerType')
        },
        getUrlParamsCustomerLocal() {
            this.farmerSearchText = this.getLocalParamsMx('customerSearchQuery', '')
            this.farmerFilterType = parseInt(this.getLocalParamsMx('customerFilterType', 0))
            this.farmerType = this.getLocalParamsMx('customerType', null) ? JSON.parse(this.getLocalParamsMx('customerType', null).toLowerCase()) : null
        },
        applySearchFarmerFilter() {
            this.clearFarmerFilterOption();
            this.resetPaging();
            this.farmersFilterList = [];
            this.pendingFarmersList = [];
            this.filtersApplied = true;
            this.setUrlParamsCustomersLocal();
            this.getFarmersListView();
        },
        clearAndResetFarmersPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.farmersModule.farmersFilterList = [];
            this.clearFarmerFilterOption();
            this.clearUserInvitationState();
            this.resetPaging();
            this.farmerFilterType = 0;
            this.filtersApplied = false;
            this.integrationType = null;
        },
        openAddFarmerForm() {
            this.clearUserInvitationState();
            this.setClearInviteeProfile();
            openOverlay("addFarmerFormOverlay");
        },
        openInitiateImporterForm() {
            this.resetImportForm();
            openOverlay("importCustomerFormOverlay");
        },
        confirmDeleteFarmer: function (id) {
            this.actionType = listAction.Delete
            this.selectedFarmerId = id
            this.fnGetHeaderString()
            this.fnGetBodyString()
            this.dialogFarmer = true
        },
        proceedFarmerDeletion: async function () {
            this.dialogFarmer = false
            switch(this.actionType) {
                case listAction.Delete:
                    await this.$store.dispatch('deleteFarmer', this.selectedFarmerId)
                    break
                case listAction.Archive:
                    await this.$store.dispatch('archiveFarmer', this.selectedFarmerId)
                    break
                case listAction.Restore:
                    await this.$store.dispatch('restoreFarmer', this.selectedFarmerId)
                    break
                default:
                    return
            }
            this.resetSnackbarDetails()
        },
        confirmActionOnFarmer: function (id, listAction) {
            this.actionType = listAction;
            this.selectedFarmerId = id;
            this.dialogFarmer = true;
        },
        cancelActionOnFarmer: function (actionType) {
            this.dialogFarmer = false;
            this.selectedFarmerId = "";
        },
        proceedActionOnFarmer: function (actionType) {
            this.dialogFarmer = false;
            this.resetSnackbarDetails();
            this.$store.dispatch("actionOnFarmer", [this.actionType, this.selectedFarmerId]).then(() => {
                this.snackbarShow = this.isChecked = false
            });
        },
        openAddContact: function () {
            router.push(routeContactAdd);
        },
        openContactEditForm: function (id) {
            router.push(routeCustomersUpdate + '/' + id);
        },

        openContactDetailsForm: function (user) {
            this.saveListItemID(user.id);
            router.push(routeCustomersDetails + '/' + user.id);
        },
        closeDialogImportUserResponse() {
            this.dialogBulkActionResponse = false;
            this.bulkActionResponse = null;
        },
        proceedIntegrateDialog() {
            this.integrateDialog = false;
            router.push(routeQuickBooks);
        },
        getContactsListForImport() {
            this.tempIntegratedCustomers = [];
            this.clearFarmerFilterOption();
            this.contactsIntegration = [];
            this.customerLoaderImport = true;
            this.integratedCustomerImportInProgress = true;
            this.integrationContactSearchText = '';
            openOverlayPermanent('importIntegratedCustomerFormOverlay');
            this.$store.dispatch('fnSetIntegrationContactsFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSizeCustomer]).then(res => {
                this.$store.dispatch('getIntegrationContacts', this.integrationType);
            });
        },
        getCustomersListByStatus() {
            this.applySearchFarmerFilter();
        },
        fnGetHeaderString() {
            const actionType = this.actionType;
            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('farmersDeletePromtTitle');
                case listAction.Archive:
                    return this.$stringConstants('farmersArchivePromtTitle');
                case listAction.Restore:
                    return this.$stringConstants('farmersRestorePromtTitle');
            }
        },
        fnGetBodyString() {
            const actionType = this.actionType;
            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('farmersDeletePromtSubtitle');
                case listAction.Archive:
                    return this.$stringConstants('farmersArchivePromtSubtitle');
                case listAction.Restore:
                    return this.$stringConstants('farmersRestorePromtSubtitle');
            }
        },
        selectAllFarmers() {
            this.selectedItems = [];
            this.allItemsDeleted = false;
            if (this.isChecked) {
                this.farmersFilterList.map((pro) => {
                    this.selectedItems.push(pro.id);
                });
            }
        },
        saveListItemID(id) {
            this.$store.state.scrollToItem = id;
        },
        updateCustomers() {
            this.showUpdateCustomersDialog = true
        },
        async updateLinkedCustomers() {
            this.showUpdateCustomersDialog = false

            const $globalStore = this.$store
            $globalStore.commit('setClearFilterOptions')

            const resetSnackbarAndListStates = () => {
                this.resetSnackbarDetails()
                $globalStore.state.farmersModule.farmersFilterList = []
                this.clearAndResetFarmersPagingStates()
                this.getFarmersList()
            }
            
            if(!this.allItemsDeleted) {
                this.selectedItems.map((item) => {
                    $globalStore.commit("setFilterOptions", [ConstantValues.queryIdentifiers, item])
                })
            } 

            await $globalStore.dispatch("updateCustomersFromInvoicingService", { allSelected: this.allItemsDeleted })
            
            resetSnackbarAndListStates()

            this.resetSnackbarDetails()
        }
    }
}
