import { mapMutations } from 'vuex';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'jobsCalendarModule': [
        'jobsCalendarData',
        'jobsCalendarStartDate',
        'jobsCalendarEndDate',
        'jobsCalendarLoader',
        'jobsUnscheduledJobsData',
        'jobsUnscheduledJobsSearchText'
    ],
    'teamJobsListModule': [
        'teamJobsList',
        'calendarViewJobs',
        'jobViewType',
        'calendarViewJobEvents',
        'focusMonth',
        'teamJobsListFilterStartDate',
        'teamJobsListFilterEndDate'
    ],
    'root': [
        'enumListProductUnits',
        'noData',
        'filtersApplied',
        'listCount',
        'enumActiveStatus',
        'totalRowCount',
        'snackbarShow',
        'allItemsDeleted',
        'selectedItems',
        'listLoading',
        'isListRequestCancelled'
    ]
}

export default {
    name: 'JobsCalendarComputed',
    computed: {
        ...getComputedProperties(computedProperties),
    },
    methods: {
        ...mapMutations({
            setClearFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
    }
}
