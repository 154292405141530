import moment from 'moment';
import router from '@/router/index';
import { routeTeamJobs } from '@/utils/endpoints';
import TeamJobsGlobalComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-global';
import TeamJobsUsersComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-user-computed';
import TeamJobsOperationsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-operations-computed';
import TeamJobsFieldsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-fields-computed';
import TeamJobsGenericComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-generic-computed';
import TeamJobsLocationsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-addresses-computed';
import TeamJobsOperatorsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-operators-computed';
import TeamJobsProductsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-products-computed';
import TeamJobsMetaComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-meta-computed';
import TeamJobsLocationComputedMixin from '@/components/team-jobs/team-jobs-computed/_team-job-location-computed';
import TeamJobsCompletionInformationComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-completion-information';
import { getOperatorsWithChecklists, createJobOperatorsForOperatorsView } from '@/utils/helpers/jobs-helpers';
import {
    getLoggedInUserRole,
    getOwnerId,
} from "@/utils/helpers/user-role-helpers";
import { TeamJobTimeDetails } from "@/data/models/TeamJobTimeDetails";
import { TeamJobOperationBillingQuantity } from '@/data/models/TeamJobOperationBillingQuantity';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { TeamJob } from '@/data/models';
import { defaultPageLimitSize, defaultPageOffsetSize, limitQuery, offsetQuery, searchTextQuery } from '@/utils/constants';

export default {
    name: 'TeamJobsComputedMixin',
    mixins: [
        TeamJobsGlobalComputed,
        TeamJobsUsersComputed,
        TeamJobsOperationsComputed,
        TeamJobsFieldsComputed,
        TeamJobsGenericComputed,
        TeamJobsLocationsComputed,
        TeamJobsOperatorsComputed,
        TeamJobsProductsComputed,
        TeamJobsMetaComputed,
        TeamJobsLocationComputedMixin,
        TeamJobsCompletionInformationComputed,
    ],
    methods: {
        getOperators() {
            return getOperatorsWithChecklists(
                this.teamJobOperation,
                this.teamJobOperators,
            )
        },
        async saveTeamJob(quickSave: boolean) {
            let custId = null;
            if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                custId = getOwnerId();
                if (this.teamJobUser) {
                    this.teamJobContractorId = this.teamJobUser.id;
                }
                else {
                    this.teamJobContractorId = getOwnerId();
                }
            }
            else if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                this.teamJobContractorId = getOwnerId();
                custId = this.teamJobUser ? this.teamJobUser.id : getOwnerId();
            }
            const operatorsForTeamJob = this.teamJobOperators.length ? this.getOperators() : []

            const teamJobDto = {
                scheduledAt: this.teamJobScheduledDate != null ? moment.utc(moment(this.teamJobScheduledDate).utc()).format() : null,
                instructions: this.teamJobInstructions,
                customerId: custId,
                contractorId: this.teamJobContractorId,
                operation: {
                    id: this.teamJobOperation ? this.teamJobOperation.id : null,
                    billingQuantities: [new TeamJobOperationBillingQuantity()]
                },
                fields: this.teamJobSelectedFields.map(field => {
                    return {
                        id: field.id,
                    }
                }),
                operators: operatorsForTeamJob,
                metaData: this.teamJobMetaDataList,
                addresses: this.teamJobAddresses,
                products: this.teamJobProducts.map(product => new TeamJobProduct().getTeamJobProductRequest(product)),
                attachments: this.teamJobAttachments,
                tags: this.teamJobTagsSelected,
            }
            await this.$store.dispatch('saveTeamJob', teamJobDto).then(res => {
                if (res) {
                    if (!quickSave) {
                        this.$store.commit('resetTeamJobsList');
                        this.$store.commit('setClearFilterOptions');
                        this.$store.commit('resetPaging');
                        router.push(routeTeamJobs + '/details/' + res.id);
                    } else {
                        let filterOptions = this.$store.state.filterOptions

                        filterOptions = filterOptions
                            ?.filter(item => item?.[0] !== searchTextQuery) 
                            ?.reduce((acc, item) => {
                                if (item?.[0] === offsetQuery) acc.push([offsetQuery, defaultPageOffsetSize])
                                else if (item?.[0] === limitQuery) acc.push([limitQuery, defaultPageLimitSize])
                                else acc.push(item)
                                return acc
                            }, [])
                        
                        if (!filterOptions.some(item => item?.[0] === offsetQuery)) filterOptions.push([offsetQuery, defaultPageOffsetSize])
                        if (!filterOptions.some(item => item?.[0] === limitQuery)) filterOptions.push([limitQuery, defaultPageLimitSize])
                        
                        this.$store.state.filterOptions = filterOptions;
                    }
                }
            });
        },
        async updateTeamJob(quickSaveJob: boolean | TeamJob) {
            const timeRecords = [];
            let custId = null;
            if (
                getLoggedInUserRole().isFarmerLoggedIn ||
                getLoggedInUserRole().isFarmersManagerLoggedIn
            ) {
                custId = getOwnerId();
                if (this.teamJobUser) {
                    this.teamJobContractorId = this.teamJobUser.id;
                } else {
                    this.teamJobContractorId = getOwnerId();
                }
            } else if (
                getLoggedInUserRole().isContractorLoggedIn ||
                getLoggedInUserRole().isContractorsManagerLoggedIn
            ) {
                this.teamJobContractorId = getOwnerId();
                custId = this.teamJobUser ? this.teamJobUser.id : getOwnerId();
            }
            const operatorsForTeamJob = this.teamJobOperators.length
                ? this.getOperators()
                : [];
            if (this.teamJobTimeDetailsList.length > 0) {
                this.teamJobTimeDetailsList.map((x) => {
                    timeRecords.push(
                        new TeamJobTimeDetails().getTeamJobTimeDetailsRequest(x)
                    );
                });
            }
            let teamJobDto;

            if (typeof quickSaveJob === 'object') {
                const operatorsWithOperatorDetailsProperty = createJobOperatorsForOperatorsView([], quickSaveJob.operators);
                const operators = getOperatorsWithChecklists(quickSaveJob.operation, operatorsWithOperatorDetailsProperty);
                teamJobDto = {
                    id: quickSaveJob.id,
                    scheduledAt: moment.utc(moment(quickSaveJob.scheduledAt).utc()).format(),
                    instructions: quickSaveJob.instructions,
                    customerId: quickSaveJob.customer?.id || null,
                    contractorId: quickSaveJob.contractor?.id || null,
                    operation: quickSaveJob.operation,
                    fields: quickSaveJob.fields?.map((field) => ({ id: field.id })),
                    operators,
                    metaData: quickSaveJob.metaData,
                    addresses: quickSaveJob.addresses,
                    products: quickSaveJob.products,
                    timeRecords: timeRecords.length ? quickSaveJob.timeRecords.map((record) => (new TeamJobTimeDetails().getTeamJobTimeDetailsRequest(record))) : [],
                    attachments: quickSaveJob.attachments,
                    notes: quickSaveJob.notes,
                    tags: quickSaveJob.tags,
                }
            } else {
                teamJobDto = {
                    id: this.teamJobDetails.id,
                    scheduledAt:
                        this.teamJobScheduledDate != null
                            ? moment.utc(moment(this.teamJobScheduledDate).utc()).format()
                            : null,
                    instructions: this.teamJobInstructions,
                    customerId: custId,
                    contractorId: this.teamJobContractorId,
                    operation: {
                        id: this.teamJobOperation ? this.teamJobOperation.id : null,
                        billingQuantities: this.teamJobOperation
                            ? this.teamJobOperation.billingQuantities
                            : [],
                    },
                    fields: this.teamJobSelectedFields.map((field) => {
                        return {
                            id: field.id,
                        };
                    }),
                    operators: operatorsForTeamJob,
                    metaData: this.teamJobMetaDataList,
                    addresses: this.teamJobAddresses,
                    products: this.teamJobProducts,
                    timeRecords: timeRecords,
                    attachments: this.teamJobAttachments,
                    notes: this.teamJobNotes,
                    tags: this.teamJobTagsSelected,
                };
            }
            return await this.$store.dispatch("updateTeamJob", teamJobDto).then((res) => {
                if (res && !quickSaveJob) {

                    this.resetTeamJobs();
                    this.mountTeamJobsDetails();
                }
                return res;
            });
        },
        autoLinkUrls(content) {
            try {
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, "text/html");
        
                const urlRegex = /((https?:\/\/|ftp:\/\/|www\.)[^\s/$.?#].[^\s]*|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s]*)?)/gi;
        
                const linkifyTextNodes = (node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                        const text = node.textContent;
        
                        if (urlRegex.test(text)) {
                            const html = text.replace(urlRegex, (url) => {
                                let fullUrl = url;
        
                                if (!/^https?:\/\//i.test(url) && !/^ftp:\/\//i.test(url)) {
                                    fullUrl = `http://${url}`;
                                }
        
                                return `<a href="${fullUrl}" rel="noopener noreferrer" target="_blank">${url}</a>`;
                            });
        
                            const tempDiv = document.createElement("div");
                            tempDiv.innerHTML = html;
                            Array.from(tempDiv.childNodes).forEach((child) => {
                                node.parentNode.insertBefore(child, node);
                            });
        
                            node.parentNode.removeChild(node);
                        }
                    } else if (node.nodeType === Node.ELEMENT_NODE) {
                        Array.from(node.childNodes).forEach(linkifyTextNodes);
                    }
                };
        
                linkifyTextNodes(doc.body);
        
                let updatedContent = doc.body.innerHTML;
        
                updatedContent = updatedContent.replace(/&quot;/g, '"');
        
                return updatedContent;
            } catch (error) {
                console.error("Error processing content: ", error);
                return content;
            }
        }
        
        
    }
}